import { ArrowLeft, Eye } from "lucide-react";
import React, { ElementRef, useEffect, useRef } from "react";
import { Link } from "react-router-dom";

function  AddContractPage() {
  const emailRef = useRef<ElementRef<"input">>();

  useEffect(() => {
    emailRef.current?.focus();
  }, []);

  return (
    <div className="w-full flex flex-col my-12 px-12  agency-shadow rounded-xl  max-w-[500px] ">
      <div className="flex items-center justify-between w-full mx-auto my-4">
        <ArrowLeft className="cursor-pointer" />
        <div className="flex items-center justify-between gap-4">
          <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clipPath="url(#clip0_516_5203)">
              <path
                d="M14.25 8.25H17.25V11.25"
                stroke="#A4A4A4"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M9.75 15.75H6.75V12.75"
                stroke="#A4A4A4"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M20.25 4.5H3.75C3.33579 4.5 3 4.83579 3 5.25V18.75C3 19.1642 3.33579 19.5 3.75 19.5H20.25C20.6642 19.5 21 19.1642 21 18.75V5.25C21 4.83579 20.6642 4.5 20.25 4.5Z"
                stroke="#A4A4A4"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </g>
            <defs>
              <clipPath id="clip0_516_5203">
                <rect width="24" height="24" fill="white" />
              </clipPath>
            </defs>
          </svg>
          <p className="h-12 w-12 rounded-full bg-[#f3df30] flex items-center justify-center ">A1</p>
        </div>
      </div>
      <h1 className={"font-[670] leading-[40px] text-[36px] text-primary mb-6 pt-2"}>
        Agency Agrement <br />
        Contract
      </h1>
      <h4 className="my-4 font-bold">Amendments</h4>

      <div className="flex items-center flex-col justify-between w-full max-w-[800px] gap-4 px-4 py-4 mx-auto border border-gray-400 rounded-lg mt-6">
        <div className="flex items-center justify-between w-full max-w-[800px] gap-4 px-2 mx-auto ">
          <div className="flex items-center justify-between gap-4 my-3">
            <div className="flex flex-col">
              <h4 className="font-bold">Perks</h4>
              <p className="font-normal text-[#838383]">
                Ammended by <span className="text-black">Petter</span>
              </p>
            </div>
          </div>
          <Eye size={40} className="cursor-pointer text-black p-2 bg-[#ccc6c6] rounded-full" />
        </div>

        <hr className="text-[#b3a8a8] h-1 w-full" />

        <div>
          <div className="flex justify-between gap-4">
            <div className="flex flex-col flex-1">
              <p className="font-normal text-[#838383]"> from</p>
              <p>
                <span className="text-black">1200 kr</span>
              </p>
            </div>
            <div className="flex flex-col flex-1">
              <p className="font-normal text-[#838383]"> To</p>
              <p className="font-bold">
                Lorem ipsum dolor sit amet consectetur adipisicing elit. Cupiditate in dolore consequatur enim. Amet
                quasi itaque repudiandae nostrum! Placeat asperiores impedit dolore fugit excepturi, obcaecati
              </p>
            </div>
          </div>
        </div>
      </div>

      <div className="flex items-center flex-col justify-between w-full max-w-[800px] gap-4 px-4 py-4 mx-auto border border-gray-400 rounded-lg mt-6">
        <div className="flex items-center justify-between w-full max-w-[800px] gap-4 px-2 mx-auto ">
          <div className="flex items-center justify-between gap-4 my-3">
            <div className="flex flex-col">
              <h4 className="font-bold">Perks</h4>
              <div className="flex items-center gap-2">
                <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <g clipPath="url(#clip0_516_6135)">
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M6 12C9.31371 12 12 9.31371 12 6C12 2.68629 9.31371 0 6 0C2.68629 0 0 2.68629 0 6C0 9.31371 2.68629 12 6 12ZM6 1.99999C6.41421 1.99999 6.75 2.33578 6.75 2.74999V6.3C6.75 6.71421 6.41421 7.05 6 7.05C5.58579 7.05 5.25 6.71421 5.25 6.3V2.74999C5.25 2.33578 5.58579 1.99999 6 1.99999ZM6.90156 8.99998C6.90156 9.49703 6.49862 9.89997 6.00156 9.89997C5.50451 9.89997 5.10156 9.49703 5.10156 8.99998C5.10156 8.50292 5.50451 8.09998 6.00156 8.09998C6.49862 8.09998 6.90156 8.50292 6.90156 8.99998Z"
                      fill="#ED4B4B"
                    />
                  </g>
                  <defs>
                    <clipPath id="clip0_516_6135">
                      <rect width="12" height="12" fill="white" />
                    </clipPath>
                  </defs>
                </svg>

                <p className="py-3 text-red-600 text-[12px]">Requires approval</p>
              </div>
            </div>
          </div>
          <Eye size={40} className="cursor-pointer text-black p-2 bg-[#ccc6c6] rounded-full" />
        </div>

        <hr className="text-[#b3a8a8] h-1 w-full" />

        <div className="flex justify-between w-full gap-6 ">
          <div className="flex flex-col flex-1">
            <p className="font-normal text-[#838383]"> From</p>
            <p>
              <span className="text-black">1200 kr</span>
            </p>
          </div>
          <div className="flex flex-col flex-1">
            <p className="font-normal text-[#838383]"> To</p>
            <p>
              <span className="text-black">1500 SEK</span>
            </p>
          </div>
        </div>
      </div>

      <div className="flex items-center flex-col justify-between w-full max-w-[800px] gap-4 px-4 py-4 mx-auto border border-gray-400 rounded-lg mt-6">
        <div className="flex items-center justify-between w-full max-w-[800px] gap-4 px-2 mx-auto ">
          <div className="flex items-center justify-between gap-4 my-3">
            <div className="flex flex-col">
              <h4 className="font-bold">Confidentiality</h4>
              <div className="flex items-center gap-2">
                <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <g clipPath="url(#clip0_516_6135)">
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M6 12C9.31371 12 12 9.31371 12 6C12 2.68629 9.31371 0 6 0C2.68629 0 0 2.68629 0 6C0 9.31371 2.68629 12 6 12ZM6 1.99999C6.41421 1.99999 6.75 2.33578 6.75 2.74999V6.3C6.75 6.71421 6.41421 7.05 6 7.05C5.58579 7.05 5.25 6.71421 5.25 6.3V2.74999C5.25 2.33578 5.58579 1.99999 6 1.99999ZM6.90156 8.99998C6.90156 9.49703 6.49862 9.89997 6.00156 9.89997C5.50451 9.89997 5.10156 9.49703 5.10156 8.99998C5.10156 8.50292 5.50451 8.09998 6.00156 8.09998C6.49862 8.09998 6.90156 8.50292 6.90156 8.99998Z"
                      fill="#ED4B4B"
                    />
                  </g>
                  <defs>
                    <clipPath id="clip0_516_6135">
                      <rect width="12" height="12" fill="white" />
                    </clipPath>
                  </defs>
                </svg>

                <p className="py-3 text-red-600 text-[12px]">Require approval</p>
              </div>
            </div>
          </div>
          <Eye size={40} className="cursor-pointer text-black p-2 bg-[#ccc6c6] rounded-full" />
        </div>

        <hr className="text-[#b3a8a8] h-1 w-full" />

        <div className="flex justify-between w-full gap-6 ">
          <div className="flex flex-col flex-1">
            <p className="font-normal text-[#838383]"> From</p>
            <p>
              <span className="text-black">For Client</span>
            </p>
          </div>
          <div className="flex flex-col flex-1">
            <p className="font-normal text-[#838383]"> To</p>
            <p>
              <span className="text-black">Customer amendment</span>
            </p>
          </div>
        </div>
      </div>

      <div className="flex items-center w-full gap-4 my-10">
        <Link
          to="/info"
          type={"button"}
          className={"btn text-black bg-[#F8F8F8] mt-3 flex items-center  rounded-[12px] flex-1  "}
        >
          Show info
        </Link>

        <button
          type={"button"}
          className={"btn text-white  bg-black mt-3 flex items-center  rounded-[12px] h-[44px] flex-1 "}
        >
          Save
        </button>
      </div>
    </div>
  );
}

export default AddContractPage;