import React, { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { z } from "zod";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";

import { useWorkspaceStore } from "../../../store/workspace.store";
import { AppHeader } from "../../../components/layout/app-header";
import Button from "../../../components/ui/button";
import { DatePicker } from "../../../components/ui/date-picker";

const formSchema = z.object({
  name: z.string().min(1, "Name is required").optional(),
  startDate: z.string().min(1, "Start date is required").optional(),
  endDate: z.string().min(1, "End date is required").optional(),
  noticePeriod: z.string().min(1, "Notice period is required").optional(),
  noticePeriodUnit: z.string().min(1, "Notice period unit is required").optional(),
});
interface FormSchemaType extends z.infer<typeof formSchema> { }

const ContractParameters = () => {

  const selectedCustomer = useWorkspaceStore((state) => state.selectedCustomer);
  const selectedContact = useWorkspaceStore((state) => state.selectedContact);
  const selectedTemplate = useWorkspaceStore((state) => state.selectedTemplate);
  const setContractParameters = useWorkspaceStore((state) => state.setContractParameters);
  const contractParameters = useWorkspaceStore((state) => state.contractParameters);

  const params = useParams();
  const navigate = useNavigate();

  const form = useForm<FormSchemaType>({
    resolver: zodResolver(formSchema),
    defaultValues: {
    },
  });

  async function onSubmit(values: FormSchemaType) {
    setContractParameters(values as ContractParameters);

    navigate(`/app/accounts/${params?.clientId}/create-contract`);
  }
  const { isSubmitting, errors } = form.formState;

  useEffect(() => {
    if (contractParameters?.name) form.setValue("name", contractParameters?.name);
    if (contractParameters?.startDate) form.setValue("startDate", contractParameters?.startDate);
    if (contractParameters?.endDate) form.setValue("endDate", contractParameters?.endDate);
    if (contractParameters?.noticePeriod) form.setValue("noticePeriod", contractParameters?.noticePeriod);
    if (contractParameters?.noticePeriodUnit) form.setValue("noticePeriodUnit", contractParameters?.noticePeriodUnit);
  }, [contractParameters?.name, contractParameters?.startDate, contractParameters?.endDate, contractParameters?.noticePeriod, contractParameters?.noticePeriodUnit]);

  useEffect(() => {
    if (!selectedCustomer?.customerId) navigate(`/app/accounts/${params.clientId}/select-customer`);
    else if (!selectedContact?.contactId) navigate(`/app/accounts/${params.clientId}/select-contact`);
    else if (!selectedTemplate?.templateId) navigate(`/app/accounts/${params.clientId}/select-template`);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedContact?.contactId, selectedCustomer?.customerId, selectedTemplate?.templateId]);

  if (!selectedTemplate?.templateId) return null;

  return (
    <div className="w-full flex flex-col p-4 justify-center  max-w-[500px] h-screen">
      <AppHeader
        show_back
        back_url={`/app/accounts/${params?.clientId}/select-template`}
        web_url={`/app/clients/${params?.clientId}/select-template`}
      />

      <h1 className={"font-[670] leading-[40px] text-[36px] text-primary mb-5 pt-2"}>Contract parameters</h1>
      <div className={"flex-1 overflow-y-auto scrollbar-sm scroll-hidden"}>
        <form onSubmit={form.handleSubmit(onSubmit)} noValidate>
          <div className="w-full">
            <input
              {...form.register("name")}
              placeholder="Name"
              className="input w-full border-2 border-[#F8F8F8] focus:ring-0 focus:border-[#e1e1e1 ] focus:outline-none
                        text-primary  text-[14px] leading-[18px] placeholder:text-[#e1e1e1 ]
                        placeholder:opacity-100
                      "
            />
            {
              errors?.name?.message &&
              <p className={"text-red-500 text-sm mt-1"}>{errors?.name?.message}</p>
            }
          </div>

          <div className="flex gap-3 my-4">
            <div className="w-full">
              <DatePicker
                value={form.watch("startDate")}
                label="Start date"
                onValueChange={(date) => form.setValue("startDate", date as string)}
              />
              {
                errors?.startDate?.message && <p className={"text-red-500 text-sm mt-1"}>{errors?.startDate?.message}</p>
              }
            </div>
            <div className="w-full">
              <DatePicker
                value={form.watch("endDate")}
                label="End date"
                
                onValueChange={(date) => form.setValue("endDate", date as string)}
              />
              {
                errors?.endDate?.message && <p className={"text-red-500 text-sm mt-1"}>{errors?.endDate?.message}</p>
              }
            </div>
          </div>
          <div className="flex gap-3 my-4">
            <div className="w-full">
              <input
                {...form.register("noticePeriod")}
                placeholder="Notice Period"
                className="input w-full border-2 border-[#F8F8F8] focus:ring-0 focus:border-[#e1e1e1 ] focus:outline-none
                      text-primary  text-[14px] leading-[18px] placeholder:text-[#e1e1e1 ]
                      placeholder:opacity-100
                    "
              />
              {
                errors?.noticePeriod?.message &&
                <p className={"text-red-500 text-sm mt-1"}>{errors?.noticePeriod?.message}</p>
              }
            </div>
            <div className="w-full">
              <select
                {...form.register("noticePeriodUnit")}
                className="input w-full border-2 border-[#F8F8F8] focus:ring-0 focus:border-[#e1e1e1 ] focus:outline-none
                      text-primary  text-[14px] leading-[18px] placeholder:text-[#e1e1e1 ]
                      placeholder:opacity-100
                    "
              >
                <option value="">Unit</option>
                <option value="MONTHS">MONTHS</option>
              </select>
              {
                errors?.noticePeriodUnit?.message &&
                <p className={"text-red-500 text-sm mt-1"}>{errors?.noticePeriodUnit?.message}</p>
              }
            </div>
          </div>

          <Button loading={isSubmitting} className={"mt-5"}>
            Add
          </Button>
        </form>
      </div>

    </div>
  );
};

export default ContractParameters;