import React from "react";
import { Outlet } from "react-router-dom";
import { AuthGuard } from "./auth/auth-guard";

export const AppWrapper = ()=>{
  return (
    <AuthGuard>
      <Outlet />
    </AuthGuard>
  )
}