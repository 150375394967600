import React, { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { AppHeader } from "../../../components/layout/app-header";
import { useWorkspaceStore } from "../../../store/workspace.store";
import Button from "../../../components/ui/button";
import toast from "react-hot-toast";
import { addContract } from "../../../services/workspace.service";
import { Dot } from "lucide-react";
import { format } from "date-fns";

function CreateContractPage() {
  const [isCreating, setIsCreating] = useState(false);
  const [errors, setErrors] = useState<string[]>([]);

  const selectedCustomer = useWorkspaceStore((state) => state.selectedCustomer);
  const selectedContact = useWorkspaceStore((state) => state.selectedContact);
  const selectedTemplate = useWorkspaceStore((state) => state.selectedTemplate);
  const selectedBilling = useWorkspaceStore((state) => state.selectedBilling);
  const contractParameters = useWorkspaceStore((state) => state.contractParameters);
  const resetAllContractFields = useWorkspaceStore((state) => state.resetAllContractFields);

  const params = useParams();
  const navigate = useNavigate();

  const handleCreateContract = async () => {
    try {
      setIsCreating(true);
      setErrors([]);
      let details = {
        "customerId": selectedCustomer?.customerId,
        "contactId": selectedContact?.contactId,
        "billingId": selectedBilling?.billingId,
        "templateId": selectedTemplate?.templateId,
        ...contractParameters
      };
      const result = await addContract(Number(params.clientId), details);
      setIsCreating(false);

      if (!result.success) {
        let errors = [];
        if (typeof result.error === "object" && result.error?.length > 0) {
          for (let error of result.error) {
            errors.push(`${error?.path[0]}: ${error.message}`);
          }
        }
        setErrors(errors);
        return;
      }

      if (result?.data?.contract?.contractId) {
        navigate(`/app/accounts/${params.clientId}/workspaces/${result?.data?.contract?.contractId}?version=${result?.data?.contract?.version}`);
      } else {
        navigate(`/app/accounts/${params.clientId}/workspaces`);
      }

      setTimeout(() => {
        resetAllContractFields();
      }, 1000);
    } catch (e) {
      setIsCreating(false);
      toast.error("Failed to create contract! Please try again later");
    }
  };

  useEffect(() => {
    if (!selectedCustomer?.customerId) navigate(`/app/accounts/${params.clientId}/select-customer`);
    else if (!selectedContact?.contactId) navigate(`/app/accounts/${params.clientId}/select-contact`);
    else if (!selectedTemplate?.templateId) navigate(`/app/accounts/${params.clientId}/select-template`);
    else if (!contractParameters?.name) navigate(`/app/accounts/${params.clientId}/contract-parameters`);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedContact?.contactId, selectedCustomer?.customerId, selectedTemplate?.templateId]);

  if (!contractParameters?.name) return null;

  console.log(contractParameters);


  return (
    <div className="w-full flex flex-col p-4 justify-center max-w-[360px] h-screen ">

      <AppHeader
        show_back
        back_url={`/app/accounts/${params?.clientId}/contract-parameters`}
        web_url={`/app/clients/${params?.clientId}/select-template`}
      />

      <h1 className={"font-[670] leading-[40px] text-[36px] text-primary mb-2 pt-2"}>Create a contract</h1>
      <span className="text-[18px]  mb-4">
        Are you sure that you want to create a
        contract for:
      </span>

      <div className={"my-3"}>
        {
          errors?.map(error => <p className={"text-red-400  text-sm flex items-center gap-1.5"}> <Dot /> {error}</p>)
        }
      </div>

      <div className={"flex-1 overflow-y-auto scrollbar-sm scroll-hidden"}>
        <div className="flex items-center justify-between w-full gap-4 py-3 mx-auto">
          <div className="flex flex-col">
            <h4 className="font-bold">{selectedCustomer?.companyName}</h4>
            <h4 className="text-[#a19d9d]">{selectedCustomer?.contactPersonName}</h4>
          </div>
          <Link to={`/app/accounts/${params.clientId}/select-customer`}>
            <p className="font-medium underline">change</p>
          </Link>
        </div>
        <hr />
        <div className="flex items-center justify-between w-full gap-4 py-3 mx-auto">
          <div className="flex flex-col">
            <h4 className="font-bold">{selectedContact?.name}</h4>
            <h4 className="font-normal ">{selectedContact?.title}</h4>
            <h4 className="text-[#a19d9d]">{selectedContact?.phoneNumber}</h4>
            <p className="text-[#a19d9d]">{selectedContact?.email}</p>
          </div>
          <Link to={`/app/accounts/${params.clientId}/select-contact`}>
            <p className="font-medium underline">change</p>
          </Link>
        </div>
        <hr />
        <div className="flex items-center justify-between w-full gap-4 py-3 mx-auto">
          <div className="flex flex-col">
            <h4 className="font-bold">{selectedBilling?.unit}</h4>
            <h4 className="text-[#a19d9d]">{selectedBilling?.address}</h4>
            <p className="text-[#a19d9d]">{selectedBilling?.email}</p>
          </div>
          <Link to={`/app/accounts/${params.clientId}/select-billing`}>
            <p className="font-medium underline">change</p>
          </Link>
        </div>
        <hr />
        <div className="flex items-center justify-between w-full gap-4 py-3 mx-auto">
          <h4 className="font-bold">{selectedTemplate?.name}</h4>

          <Link to={`/app/accounts/${params.clientId}/select-template`}>
            <p className="font-medium underline">change</p>
          </Link>{" "}
        </div>
        <hr />
        <div className="flex items-center justify-between w-full gap-4 py-3 mx-auto flex-">
          <h4 className="font-bold">Parameters</h4>
          <Link to={`/app/accounts/${params.clientId}/contract-parameters`}>
            <p className="font-medium underline">change</p>
          </Link>{" "}
        </div>
        <div className="flex items-center justify-between">
          <p className="text-[#a19d9d]">Name</p>
          <p className="text-[#a19d9d] font-semibold">{contractParameters?.name}</p>
        </div>
        <div className="flex items-center justify-between">
          <p className="text-[#a19d9d]">Start date</p>
          <p className="text-[#a19d9d] font-semibold">{contractParameters?.startDate && format(new Date(contractParameters?.startDate), "PPP")}</p>
        </div>
        <div className="flex items-center justify-between">
          <p className="text-[#a19d9d]">End date</p>
          <p className="text-[#a19d9d] font-semibold">{contractParameters?.endDate && format(new Date(contractParameters?.endDate), "PPP")}</p>
        </div>
        <div className="flex items-center justify-between">
          <p className="text-[#a19d9d]">Notice period</p>
          <p className="text-[#a19d9d] font-semibold">{contractParameters?.noticePeriod} {contractParameters?.noticePeriodUnit}</p>
        </div>


      </div>


      <Button onClick={handleCreateContract} loading={isCreating} className={"mt-5"}>
        Create
      </Button>
    </div>
  );
}

export default CreateContractPage;