export { default as LoginPage } from "./auth/login";
export { default as VerifyPage } from "./auth/verify";
export { default as AddCustomerPage } from "./contracts(new)/customer/add";
export { default as SelectCustomerPage } from "./contracts(new)/customer/choose";
export { default as AddBillPage } from "./contracts(new)/bill/add";
export { default as SelectBillPage } from "./contracts(new)/bill/select";
export { default as AddContactPage } from "./contracts(new)/contact/add";
export { default as SelectContactPage } from "./contracts(new)/contact/select";
export { default as CreateContractPage } from "./contracts(new)/create";
export { default as SelectTemplatePage } from "./contracts(new)/template/choose";
export { default as ContractParameters } from "./contracts(new)/parameters";
export { default as AccountsPage } from "./accounts";
export { default as WorkspacePage } from "./workspaces";
export { default as TemplatesPage } from "./templates";
export { default as SingleTemplatePage } from "./templates/template";

export {default as ContractPage} from "./workspaces/contract";

export { default as NotFound } from "./not-found";

export { default as AddContractPage } from "./users/contract";
export { default as CustomAmendmentsPage } from "./users/custom";
export { default as EditContractInformationPage } from "./users/edit";
export { default as AddContractInformationPage } from "./users/information";
