import { request, requestFetch } from "../utils/axios-utils";
import { getAxiosError } from "../utils/getAxiosError";

export const apiCall = async (options: any) => {
	try {
		const response = await request({
			...options
		});

		if (response.data.status === false) {
			return { success: false, error: response.data.errors ?? response.data.message };
		}
		return { success: true, data: response.data };
	} catch (error: any) {
		return { success: false, error: getAxiosError(error) };
	}
};
