
import React from "react";
import Button from "../../../../components/ui/button";
import { TemplateOptionType } from "../../../../../lib/template-options-types";
import { TemplateTools } from "../../../../../lib/template-utilities";

interface ContractOptionsProps {
  workspace: Workspace;
  updateCurrentView: (view: string)=>void;
  updateSelectedOption: (option: Record<string, any>) => void;
}

const ContractOptions = ({workspace, updateSelectedOption, updateCurrentView,}: ContractOptionsProps)=>{
  const options = Object.keys(workspace?.template?.options)?.map(key=>({key, ...workspace?.template?.options[key]}));

  const textOptions: Record<string, any>[] = options.filter(option=>option.type===TemplateOptionType.TEXT);
  const rangeOptions: Record<string, any>[] = options.filter(option=>option.type===TemplateOptionType.RANGE);

  const onEyeClick = (event: any, option: Record<string, any>) =>{
    event.preventDefault();
    event.stopPropagation();

    TemplateTools.scrollToTag(option.key);
  }

  return (
    <div className={"h-full flex-1 mt-6 flex flex-col"}>

      {
        options?.length === 0 ? (
          <div className={"flex-1 flex items-center justify-center"}>
            <p className={"font-[380] text-[16px] leading-[24px] text-center text-[#666666] "}>No options</p>
          </div>
        ) : (
          <div className={"flex-1 h-full w-full overflow-y-auto space-y-3 justify-center"}>
            {
              textOptions?.map((option, index) => (
                <div
                  onClick={()=>{
                    TemplateTools.scrollToTag(option.key)
                    updateSelectedOption(option);
                    updateCurrentView("option-edit")
                  }}
                  key={index}
                  className={"border cursor-pointer rounded-md flex justify-between items-center p-4 hover:bg-grayish"}
                >
                  <div className={"flex items-center gap-2"}>
                    <span className={"bg-[#F4F4F4] rounded-[5px] h-[30px] w-[30px] flex items-center justify-center"}>
                      <svg width="28" height="28" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path
                          d="M8.49967 3.16666H5.16634C4.42996 3.16666 3.83301 3.76361 3.83301 4.49999V11.5C3.83301 12.2364 4.42996 12.8333 5.16634 12.8333H10.833C11.5694 12.8333 12.1663 12.2364 12.1663 11.5V6.83332M8.49967 3.16666V5.49999C8.49967 6.23637 9.09661 6.83332 9.83301 6.83332H12.1663M8.49967 3.16666L12.1663 6.83332"
                          stroke="#666666"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                        <path d="M5.83301 10.5H10.1663" stroke="#666666" strokeLinecap="round" strokeLinejoin="round" />
                        <path d="M5.83301 8.5H7.49967" stroke="#666666" strokeLinecap="round" strokeLinejoin="round" />
                      </svg>
                    </span>
                    <div>
                      <h3 className={"font-[570] leading-[20px]"}>{option.key}</h3>
                      <p className={"font-[380]"}>{Object.keys(option?.values)?.length} text options</p>
                    </div>

                  </div>

                  <div className={"flex items-center gap-2"}>
                    <button onClick={event => onEyeClick(event, option)}>
                      <svg width="24" height="24" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <g clipPath="url(#clip0_816_310)">
                          <path
                            d="M15.4569 7.7975C15.435 7.74813 14.9056 6.57375 13.7287 5.39687C12.1606 3.82875 10.18 3 7.99999 3C5.81999 3 3.83937 3.82875 2.27124 5.39687C1.09437 6.57375 0.562494 7.75 0.543119 7.7975C0.51469 7.86144 0.5 7.93064 0.5 8.00062C0.5 8.0706 0.51469 8.1398 0.543119 8.20375C0.564994 8.25312 1.09437 9.42688 2.27124 10.6038C3.83937 12.1713 5.81999 13 7.99999 13C10.18 13 12.1606 12.1713 13.7287 10.6038C14.9056 9.42688 15.435 8.25312 15.4569 8.20375C15.4853 8.1398 15.5 8.0706 15.5 8.00062C15.5 7.93064 15.4853 7.86144 15.4569 7.7975ZM7.99999 10.5C7.50554 10.5 7.02219 10.3534 6.61107 10.0787C6.19995 9.80397 5.87951 9.41352 5.6903 8.95671C5.50108 8.49989 5.45157 7.99723 5.54803 7.51227C5.64449 7.02732 5.8826 6.58186 6.23223 6.23223C6.58186 5.8826 7.02732 5.6445 7.51227 5.54804C7.99722 5.45157 8.49989 5.50108 8.9567 5.6903C9.41352 5.87952 9.80396 6.19995 10.0787 6.61107C10.3534 7.0222 10.5 7.50555 10.5 8C10.5 8.66304 10.2366 9.29893 9.76776 9.76777C9.29892 10.2366 8.66304 10.5 7.99999 10.5Z"
                            fill="#666666" />
                        </g>
                        <defs>
                          <clipPath id="clip0_816_310">
                            <rect width="16" height="16" fill="white" />
                          </clipPath>
                        </defs>
                      </svg>

                    </button>
                  </div>
                </div>

              ))
            }

            {
              rangeOptions?.map((option, index)=>(
                <div
                  onClick={()=>{
                    updateSelectedOption(option);
                    updateCurrentView("option-edit")
                  }}
                  key={index} className={"border cursor-pointer rounded-md flex justify-between items-center p-4 hover:bg-grayish"}>
                  <div className={"flex items-center gap-2"}>
                    <span className={"bg-[#F4F4F4] rounded-[5px] h-[32px] w-[32px] flex items-center justify-center"}>
                      <svg width="28" height="28" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M3.16699 4.16666L4.16699 3.16666V6.83332M4.16699 6.83332H3.16699M4.16699 6.83332H4.83366"
                              stroke="#666666"
                              strokeLinecap="round" strokeLinejoin="round" />
                        <path
                          d="M8.83366 9.5H7.77113C7.28399 9.5 7.01246 8.95727 7.27086 8.5716C7.30266 8.52407 7.34773 8.4874 7.39533 8.45573L8.59526 7.65733C8.64286 7.6256 8.68779 7.5888 8.72026 7.54173C9.01719 7.11187 8.71453 6.5 8.16873 6.5H7.16699"
                          stroke="#666666" strokeLinecap="round" strokeLinejoin="round" />
                        <path
                          d="M11.167 9.83334H12.1313C12.9542 9.83334 13.1003 11.0917 12.3404 11.3037C12.3036 11.314 12.265 11.3181 12.2269 11.3195L11.8337 11.3333L12.2269 11.3472C12.265 11.3485 12.3036 11.3526 12.3404 11.3629C13.1003 11.5749 12.9542 12.8333 12.1313 12.8333H11.167"
                          stroke="#666666" strokeLinecap="round" strokeLinejoin="round" />
                        </svg>
                    </span>

                    <div>
                      <h3 className={"font-[570] leading-[20px]"}>{option?.key}</h3>
                      <p className={"font-[380] text-[14px] truncate"}>Range: {" "}
                        <span className={"font-bold"}>
															{option?.values?.range?.min} -
                          {option?.values?.range?.max}
														</span>
                      </p>
                      <p className={"font-[380] text-[13px] truncate"}>Pre-Approved Range:  {" "}
                        <span className={"font-bold"}>
															{option?.values?.preApprovedRange?.min} -
                          {option?.values?.preApprovedRange?.max}
														</span>
                      </p>
                    </div>

                  </div>

                  <div className={"flex items-center gap-2"}>
                    <button onClick={event => onEyeClick(event, option)}>
                      <svg width="24" height="24" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <g clipPath="url(#clip0_816_310)">
                          <path
                            d="M15.4569 7.7975C15.435 7.74813 14.9056 6.57375 13.7287 5.39687C12.1606 3.82875 10.18 3 7.99999 3C5.81999 3 3.83937 3.82875 2.27124 5.39687C1.09437 6.57375 0.562494 7.75 0.543119 7.7975C0.51469 7.86144 0.5 7.93064 0.5 8.00062C0.5 8.0706 0.51469 8.1398 0.543119 8.20375C0.564994 8.25312 1.09437 9.42688 2.27124 10.6038C3.83937 12.1713 5.81999 13 7.99999 13C10.18 13 12.1606 12.1713 13.7287 10.6038C14.9056 9.42688 15.435 8.25312 15.4569 8.20375C15.4853 8.1398 15.5 8.0706 15.5 8.00062C15.5 7.93064 15.4853 7.86144 15.4569 7.7975ZM7.99999 10.5C7.50554 10.5 7.02219 10.3534 6.61107 10.0787C6.19995 9.80397 5.87951 9.41352 5.6903 8.95671C5.50108 8.49989 5.45157 7.99723 5.54803 7.51227C5.64449 7.02732 5.8826 6.58186 6.23223 6.23223C6.58186 5.8826 7.02732 5.6445 7.51227 5.54804C7.99722 5.45157 8.49989 5.50108 8.9567 5.6903C9.41352 5.87952 9.80396 6.19995 10.0787 6.61107C10.3534 7.0222 10.5 7.50555 10.5 8C10.5 8.66304 10.2366 9.29893 9.76776 9.76777C9.29892 10.2366 8.66304 10.5 7.99999 10.5Z"
                            fill="#666666" />
                        </g>
                        <defs>
                          <clipPath id="clip0_816_310">
                            <rect width="16" height="16" fill="white" />
                          </clipPath>
                        </defs>
                      </svg>

                    </button>
                  </div>
                </div>
              ))
            }

          </div>
        )
      }


      <Button className={"save"}>Save</Button>
    </div>
  )
}

export default ContractOptions;