import React from "react";

interface Props {
	values: Record<string, boolean|string|number>,
	errors: Record<string, string>,
	handleInputChange : (key: string, value: string|number|boolean) => void;
}

const TextOptions = ({values, errors, handleInputChange}: Props) => {

	return (
		<>
			<div className={"flex-1 overflow-y-auto scrollbar-sm scroll-hidden space-y-8 mt-6"}>
				<div>

					<div>
						<input
							placeholder="Name"
							className="input w-full bg-[#F8F8F8] focus:ring-0 focus:border-[#e1e1e1 ] focus:outline-none
														text-primary text-[14px] leading-[18px] placeholder:text-[#e1e1e1 ]
														placeholder:opacity-100
													"
							value={values?.valueName as string}
							onChange={e=>handleInputChange("valueName", e.target.value)}
						/>
						{errors.valueName && <p className={"text-red-400 mt-1.5 text-sm"}>{errors.valueName}</p>}
					</div>

					<div className={"my-4"}>
						<textarea
							rows={6}
							className={"leading-[24px] p-4 focus:border-none focus:outline-none focus:ring-0 bg-[#F8F8F8] resize-none scrollbar-sm scroll-hidden font-[380] w-full h-full  text-[#121212]"}
							value={values?.valueText as string}
							onChange={e=>handleInputChange("valueText", e.target.value)}
						>
						</textarea>
						{errors.valueText && <p className={"text-red-400 mt-1.5 text-sm"}>{errors.valueText}</p>}
					</div>

					<div className={"flex justify-between items-center"}>
						<label htmlFor={"checkbox2"} className={"flex items-center gap-2"}>
							<input
								type="checkbox" id="checkbox2"
								className={"h-[20px] w-[20px] checkbox"}
								checked={!!values?.needsApproval}
								onChange={e=>handleInputChange("needsApproval", e.target.checked)}
							/>
							<span className={"font-[570] text-[14px] leading-[20px]"}>Need approval</span>
						</label>
					</div>
				</div>
			</div>
		</>
	);
};

export default TextOptions;