
import React from "react"
import Button from "../../../../components/ui/button";

interface Props {
  workspace: Workspace
  updateCurrentView: (view: string) => void
}
const ContractInfo = ({workspace, updateCurrentView}:Props)=>{
  return (
    <div className={"h-full flex-1 mt-8 flex flex-col"}>
      <div className={"flex-1 overflow-y-auto"}>

        <div>
          <h2 className={"font-[570] text-[18px] leading-[24px] text-[#000000]"}>Customer</h2>

          <div className={"mt-3 flex items-center justify-between gap-4"}>
            <p className={"font-[570] text-[14px] leading-[20px] text-[#000000]"}>{workspace?.customer?.companyName}</p>
          </div>
          <p
            className={"font-[380] text-[14px] max-w-[140px] leading-[15.89px] text-[#000000]"}
          >
            {workspace?.customer?.address}
          </p>
          <p
            className={"font-[380] text-[14px] max-w-[140px] leading-[20px] text-[#000000]"}
          >
            {workspace?.customer?.email}
          </p>
        </div>

        <div className={"h-[1px] my-[24px] bg-[#EDEDED]"} />

        <div>
          <h2 className={"font-[570] text-[18px] leading-[24px] text-[#000000]"}>Billing Unit</h2>

          <div className={"mt-3 flex items-center justify-between gap-4"}>
            <p className={"font-[570] text-[14px] leading-[20px] text-[#000000]"}>{workspace?.billing?.unit}</p>
            <button onClick={()=>updateCurrentView("change-billing")} className={"underline font-[570] text-[14px] leading-[15.89px] text-[#000000]"}>Change</button>
          </div>
          <p
            className={"font-[380] text-[14px] max-w-[140px] leading-[15.89px] text-[#000000]"}
          >
            {workspace?.billing?.address}
          </p>
          <p
            className={"font-[380] text-[14px] max-w-[140px] leading-[20px] text-[#000000]"}
          >
            {workspace?.billing?.email}
          </p>
        </div>

        <div className={"h-[1px] my-[24px] bg-[#EDEDED]"} />

        <div className="pb-4">
          <h2 className={"font-[570] text-[18px] leading-[24px] text-[#000000]"}>Customer Contact</h2>

          <div className={"mt-3 flex items-center justify-between gap-4"}>
            <p className={"font-[570] text-[14px] leading-[20px] text-[#000000]"}>{workspace?.contact?.name}</p>
            <button onClick={()=>updateCurrentView("change-contact")} className={"underline font-[570] text-[14px] leading-[15.89px] text-[#000000]"}>Change</button>
          </div>
          <p
            className={"font-[380] text-[14px] max-w-[140px] leading-[15.89px] text-[#000000]"}
          >
            {workspace?.contact?.title}
          </p>
          <p
            className={"font-[380] text-[14px] max-w-[140px] leading-[20px] text-[#000000]"}
          >
            {workspace?.contact?.phoneNumber}
          </p>
          <p
            className={"font-[380] text-[14px] max-w-[140px] leading-[20px] text-[#000000]"}
          >
            {workspace?.contact?.email}
          </p>
        </div>


      </div>

      <Button className={"save"}>Save</Button>
    </div>
  )
}

export default ContractInfo;