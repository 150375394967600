// @ts-nocheck

import React from "react";
import { useRanger, Ranger } from '@tanstack/react-ranger';
import styled from 'styled-components';
import ThreeHandleSlider from "../../../../../../components/ui/ThreeHandleSlider";

export const Track = styled('div')`
  height: 4px;
  width: 100%;
  position: relative;
  "user-select": none;
  background: #E7E7E7;
  box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.6);
  border-radius: 2px;
`;

// @ts-ignore

export const Tick = styled('div')`
  position: absolute;
  top: 5px;
  left: ${(props: { percentage: number; }) => `${props.percentage}%`};
  transform: translateX(-50%);
  :before {
    content: '';
    position: absolute;
    left: 0;
    background: rgba(0, 0, 0, 0.2);
    height: 5px;
    width: 2px;
    transform: translate(-50%, 0.7rem);
  }
`;

export const TickLabel = styled('div')`
  position: absolute;
  font-size: 0.6rem;
  color: rgba(0, 0, 0, 0.5);
  top: 100%;
  transform: translate(-50%, 1.2rem);
  white-space: nowrap;
`;

export const Segment = styled('div')`
  position: absolute;
  background: ${(props: { index: number; left: number; width: number; }) =>
		props.index === 0
			? '#FF0000A3'
			: props.index === 1
				? '#E7E7E7'
				: props.index === 2
					? '#E7E7E7'
					: '#FF0000A3'};
  left: ${(props: { left: number; }) => `${props.left}%`};
  height: 100%;
  width: ${(props: { width: number; }) => `${props.width}%`};
`;

export const Handle = styled('button')`
  position: absolute;
  left: ${(props: { left: number; }) => `${props.left}%`};
	transform: translate(-50%, -50%);
`;

const RangeOptions = ({ values, errors, handleRangeInputChange, showHeader = true }: {
	values: Record<string, number>,
	errors: Record<string, string>,
	handleRangeInputChange: (key: string, value: number) => void;
	showHeader?: boolean;
}) => {

	const rangerRef = React.useRef<HTMLDivElement>(null);

	const rangerInstance = useRanger<HTMLDivElement>({
		getRangerElement: () => rangerRef.current,
		values: [values.preApprovedMin, values.defaultValue, values.preApprovedMax],
		min: values.minValue,
		max: values.maxValue,
		stepSize: 1,
		onChange: (instance: Ranger<HTMLDivElement>) => {
			handleRangeInputChange("preApprovedMin", instance.sortedValues[0]);
			handleRangeInputChange("defaultValue", instance.sortedValues[1]);
			handleRangeInputChange("preApprovedMax", instance.sortedValues[2]);
		}
	});

	return (
		<>
			<div className={"flex-1 space-y-8 mt-6"}>
				{
					showHeader && <h1 className={"font-bold text-[20px] my-3"}>Set possible range</h1>
				}
				<div className={"gap-4"}>
					<div className={"my-4 bg-[#F8F8F8] p-4 rounded-[8px]"}>
						<p className={"font-[380] text-[10px] text-[#717171]"}>
							Default
						</p>
						<input
							type="text"
							className="focus:border-none focus:outline-none focus:ring-0 font-[570] w-full text-[20px] placeholder:font-[380] placeholder:text-[16px] bg-transparent"
							placeholder="value..."
							value={values.defaultValue}
							onChange={e => {
								try {
									handleRangeInputChange("defaultValue", Number(e.target.value));
								} catch (error) { }
							}}
						/>
						{errors.defaultValue && <p className={"text-red-400 mt-1.5 text-sm"}>{errors.defaultValue}</p>}
					</div>

					<div className={"flex justify-between gap-4 items-center mt-4"}>
						<div className={"flex-1 bg-[#F8F8F8] p-4 rounded-[8px]"}>
							<p className={"font-[380] text-[10px] text-[#717171]"}>
								Range from
							</p>
							<input
								type="number"
								className="focus:border-none focus:outline-none focus:ring-0 font-[570] w-full text-[20px] placeholder:font-[380] placeholder:text-[16px] bg-transparent"
								placeholder="value..."
								value={values.minValue}
								onChange={e => {
									try {
										handleRangeInputChange("minValue", Number(e.target.value));
									} catch (error) { }
								}}
							/>
							{errors.minValue && <p className={"text-red-400 mt-1.5 text-sm"}>{errors.minValue}</p>}
						</div>

						<div className={"flex-1 bg-[#F8F8F8] p-4 rounded-[8px]"}>
							<p className={"font-[380] text-[10px] text-[#717171]"}>
								Range to
							</p>
							<input
								type="text"
								className="focus:border-none focus:outline-none focus:ring-0 font-[570] w-full text-[20px] placeholder:font-[380] placeholder:text-[16px] bg-transparent"
								placeholder="value..."
								value={values.maxValue}
								onChange={e => {
									try {
										handleRangeInputChange("maxValue", Number(e.target.value));
									} catch (error) { }
								}}
							/>
							{errors.maxValue && <p className={"text-red-400 mt-1.5 text-sm"}>{errors.maxValue}</p>}
						</div>
					</div>

					<div className={"flex justify-between gap-4 items-center mt-4"}>
						<div className={"flex-1 bg-[#F8F8F8] p-4 rounded-[8px]"}>
							<p className={"font-[380] text-[10px] text-[#717171]"}>
								Pre-approved range from
							</p>
							<input
								type="text"
								className="focus:border-none focus:outline-none focus:ring-0 font-[570] w-full text-[20px] placeholder:font-[380] placeholder:text-[16px] bg-transparent"
								placeholder="value..."
								value={values.preApprovedMin}
								onChange={e => {
									try {
										handleRangeInputChange("preApprovedMin", Number(e.target.value));
									} catch (error) { }
								}}
							/>
							{errors.preApprovedMin && <p className={"text-red-400 mt-1.5 text-sm"}>{errors.preApprovedMin}</p>}
						</div>

						<div className={"flex-1 bg-[#F8F8F8] p-4 rounded-[8px]"}>
							<p className={"font-[380] text-[10px] text-[#717171]"}>
								Pre-approved range to
							</p>
							<input
								type="text"
								className="focus:border-none focus:outline-none focus:ring-0 font-[570] w-full text-[20px] placeholder:font-[380] placeholder:text-[16px] bg-transparent"
								placeholder="value..."
								value={values.preApprovedMax}
								onChange={e => {
									try {
										handleRangeInputChange("preApprovedMax", Number(e.target.value));
									} catch (error) { }
								}}
							/>
							{errors.preApprovedMax && <p className={"text-red-400 mt-1.5 text-sm"}>{errors.preApprovedMax}</p>}
						</div>
					</div>
					<div className="w-full mt-10 py-4 px-[20px]">

						<div className={"track"}>
							<ThreeHandleSlider
								values={{
									minValue: values.preApprovedMin,
									maxValue: values.preApprovedMax,
									defaultValue: values.defaultValue,
									fromValue: values.minValue,
									toValue: values.maxValue
								}}
								onChange={(values) => {									
									handleRangeInputChange("preApprovedMin", values.minValue);
									handleRangeInputChange("defaultValue", values.defaultValue);
									handleRangeInputChange("preApprovedMax", values.maxValue);
								}}
							/>

						</div>


					</div>
				</div>
			</div>
		</>
	);
};

export default RangeOptions;