import React, { useEffect } from "react";
import { z } from "zod";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { AppHeader } from "../../../../components/layout/app-header";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useWorkspaceStore } from "../../../../store/workspace.store";
import { addCustomerBillingInfo, fetchClientCustomer } from "../../../../services/customers.service";
import Button from "../../../../components/ui/button";

const formSchema = z.object({
  unit: z.string().optional(),
  address: z.string().optional(),
  email: z.string().optional()
});
interface FormSchemaType extends z.infer<typeof formSchema> { }
function AddBillPage() {

  const params = useParams();
  const navigate = useNavigate()

  const selectedCustomer = useWorkspaceStore((state) => state.selectedCustomer);
  const setSelectedCustomer = useWorkspaceStore((state) => state.setSelectedCustomer);

  const form = useForm<z.infer<typeof formSchema>>({
    resolver: zodResolver(formSchema),
    defaultValues: {
    },
  });

  const loadCustomerDetails = async (clientId: string, customerId: string) => {
    const response = await fetchClientCustomer(clientId, customerId);

    if (!response.success) {
      return null;
    }

    return response?.data?.customer;
  };

  const clientId = params?.clientId as string;
  async function onSubmit(values: FormSchemaType) {
    try {
      const response = await addCustomerBillingInfo(clientId, { customerId: selectedCustomer?.customerId, ...values });

      if (!response.success) {
        if (typeof response.error === "object" && response.error?.length > 0) {
          for (let error of response.error) {
            form.setError(error?.path[0], {
              type: "server",
              message: error.message,
            });
          }
        }
        return;
      }

      const customer = await loadCustomerDetails(clientId, selectedCustomer?.customerId + "");
      if (customer) {
        setSelectedCustomer(customer);
      }
      navigate(`/app/accounts/${params?.clientId}/select-billing`);
    } catch (error) {
      console.log("Error =>", error);
    }
  }

  const { isSubmitting, errors } = form.formState;

  useEffect(() => {
    form.setFocus("unit")
  }, []);

  return (
    <div className="w-full flex flex-col p-4 justify-center max-w-[500px] h-screen">
      <AppHeader
        show_back
        back_url={`/app/accounts/${params?.clientId}/select-contact`}
        web_url={`/app/clients/${params?.clientId}/customers`}
      />

      <h1 className={"font-[670] leading-[40px] text-[36px] text-primary mb-4 pt-2"}>Add a billing unit</h1>
      <div className={"flex-1 overflow-y-auto scrollbar-sm scroll-hidden"}>
        <form className={" w-full"} onSubmit={form.handleSubmit(onSubmit)} noValidate>
          <div className="pt-5">
            <input
              {...form.register("unit")}
              placeholder="Unit name"
              className="input w-full bg-[#F8F8F8] focus:ring-0 focus:border-[#e1e1e1 ] focus:outline-none
                        text-primary text-[14px] leading-[18px] placeholder:text-[#e1e1e1 ]
                        placeholder:opacity-100
                      "
            />
            {
              errors?.unit?.message && <p className={"text-red-500 text-sm mt-1"}>{errors?.unit?.message}</p>
            }
          </div>
          <div className="pt-5">
            <input
              {...form.register("address")}
              placeholder="Address"
              className="input w-full bg-[#F8F8F8] focus:ring-0 focus:border-[#e1e1e1 ] focus:outline-none
                        text-primary text-[14px] leading-[18px] placeholder:text-[#e1e1e1 ]
                        placeholder:opacity-100
                      "
            />
            {
              errors?.address?.message && <p className={"text-red-500 text-sm mt-1"}>{errors?.address?.message}</p>
            }
          </div>

          <div className="pt-5">
            <input
              {...form.register("email")}
              placeholder="Email"
              className="input w-full bg-[#F8F8F8] focus:ring-0 focus:border-[#e1e1e1 ] focus:outline-none
                        text-primary font-[570] text-[14px] leading-[18px] placeholder:text-[#e1e1e1 ]
                        placeholder:opacity-100 placeholder:font-[570]
                      "
            />
            {
              errors?.email?.message && <p className={"text-red-500 text-sm mt-1"}>{errors?.email?.message}</p>
            }
          </div>

          <div className="flex items-center w-full gap-4 mt-20">
            <Link

              to={isSubmitting ? "#" : `/app/accounts/${params?.clientId}/select-customer`}
              className={"flex-1 w-full"}
            >
              <Button disabled={isSubmitting} type={"button"} className={"flex-1"} variant={"ghost"}>
                Cancel
              </Button>
            </Link>
            <Button loading={isSubmitting} className={"flex-1 w-full"}>Add</Button>
          </div>
        </form>
      </div>

    </div>
  );
}

export default AddBillPage;