import { apiCall } from "./helpers";

export const acceptClientInvitation = async (clientId: number) => {
	return await apiCall({
		url: `clients/acceptInvitation`,
		method: "POST",
		data: { clientId },
	});
};

export const getClientsList = async () => {
	return await apiCall({
		url: `clients/list`,
	});
};

export const getClient = async (clientId: string) => {
	return await apiCall({
		url: `clients/get?clientId=${clientId}`,
	});
};

export const addClient = async (formData: FormData) => {
	return await apiCall({
		url: `clients/create`,
		method: "POST",
		headers: { 'Content-Type': 'multipart/form-data' },
		data: formData,
	});
};

export const editClient = async (formData: FormData) => {
	return await apiCall({
		url: `clients/update`,
		method: "PATCH",
		headers: { 'Content-Type': 'multipart/form-data' },
		data: formData,
	});
};

export const rejectClientInvitation = async (clientId: number) => {
	return await apiCall({
		url: `clients/rejectInvitation`,
		method: "DELETE",
		data: { clientId },
	});
};