import { ChevronRight, Plus } from "lucide-react";
import React, { useEffect } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { AppHeader } from "../../../../components/layout/app-header";
import { useWorkspaceStore } from "../../../../store/workspace.store";
import Button from "../../../../components/ui/button";

function SelectBillPage() {

  const selectedCustomer = useWorkspaceStore((state) => state.selectedCustomer);
  const selectedContact = useWorkspaceStore((state) => state.selectedContact);
  const setSelectedBilling = useWorkspaceStore((state) => state.setSelectedBilling);

  const params = useParams()
  const navigate = useNavigate()

  const handleSelectBilling = (billing: CustomerBilling)=>{
    setSelectedBilling(billing);
    navigate(`/app/accounts/${params.clientId}/select-template`)
  }

  useEffect(() => {
    if (!selectedCustomer?.customerId) navigate(`/app/accounts/${params.clientId}/select-customer`);
    else if (!selectedContact?.contactId) navigate(`/app/accounts/${params.clientId}/select-contact`);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedContact?.contactId, selectedCustomer?.customerId]);

  if (!selectedContact?.contactId) return null;

  return (
    <div className="w-full flex flex-col p-4 justify-center  max-w-[500px] h-screen">
      <AppHeader
        show_back
        back_url={`/app/accounts/${params?.clientId}/select-contact`}
        web_url={`/app/clients/${params?.clientId}/select-billing`}
      />

      <span className="text-[12px] font-[570] text-[#717171] leading-[16px]">Step 3 of 4</span>

      <h1 className={"font-[670] leading-[40px] text-[36px] text-primary mb-4 pt-2"}>Choose a billing unit</h1>
      <input
        placeholder="Search"
        className="input mb-2 w-full border-4 border-[#F8F8F8] focus:ring-0 focus:border-[#e1e1e1 ] focus:outline-none  text-primary text-[14px] leading-[10px] placeholder:text-[#e1e1e1 ]placeholder:opacity-100"
      />
      <div className={"flex-1 overflow-y-auto scrollbar-sm scroll-hidden"}>
          {
            selectedCustomer?.billings?.length < 1 ?
              <div className={"flex items-center justify-center py-5"}>
                <p className={"text-black/60 font-bold"}>No Billing Found</p>
              </div>
              : selectedCustomer?.billings?.map((billing, idx)=>(
                <React.Fragment key={billing?.billingId}>
                  <div onClick={()=>handleSelectBilling(billing)} className="flex px-2 rounded items-center cursor-pointer hover:bg-grayish justify-between w-full max-w-[800px] gap-4 mx-auto">
                    <div className="flex items-center justify-between gap-4 my-3">
                      <div className="flex flex-col">
                        <h4 className="font-bold">{billing?.unit}</h4>
                        <h4 className="text-[#a19d9d]">{billing?.address}</h4>
                        <p className="text-[#a19d9d]">{billing?.email}</p>
                      </div>
                    </div>
                    <ChevronRight className="cursor-pointer text-[#a48080]" />
                  </div>
                  {
                    idx < selectedCustomer?.billings?.length - 1 && <hr />
                  }
                </React.Fragment>
              ))
          }
      </div>  

      <Link className={"mt-5"} to={`/app/accounts/${params?.clientId}/new-billing`}>
        <Button>
          <Plus className="" />
          <span className="font-extrabold text-white text-[18px] ml-5">Add a billing unit</span>
        </Button>
      </Link>

    </div>
  );
}

export default SelectBillPage;