import { ChevronRight, Plus } from "lucide-react";
import React, { useEffect } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";

import { AppHeader } from "../../../../components/layout/app-header";
import Button from "../../../../components/ui/button";
import Avatar from "../../../../components/ui/avatar";
import SpinnerLoader from "../../../../components/spinner-loader";
import { useCustomerStore } from "../../../../store/customers.store";
import { useWorkspaceStore } from "../../../../store/workspace.store";

function SelectCustomerPage() {

  const loadClientCustomers = useCustomerStore((state) => state.loadClientCustomers);
  const setSelectedContact = useWorkspaceStore((state) => state.setSelectedContact);
  const setSelectedCustomer = useWorkspaceStore((state) => state.setSelectedCustomer);
  const loadingCustomers = useCustomerStore((state) => state.loading);
  const customers = useCustomerStore((state) => state.customers);
  const params = useParams();
  const navigate = useNavigate()

  const handleSelectCustomer = (customer: Customer)=>{
    setSelectedCustomer(customer);
    navigate(`/app/accounts/${params.clientId}/select-contact`)
  }

  useEffect(() => {
    setSelectedContact(null);
    setSelectedCustomer(null);

    if (params.clientId) {
      loadClientCustomers(Number(params.clientId));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params.clientId]);

  return (
    <div className="w-full flex flex-col p-4 justify-center max-w-[500px] h-screen ">
      <AppHeader
        show_back
        back_url={`/app/accounts/${params?.clientId}/`}
        web_url={`/app/clients/${params?.clientId}/`}
      />

      <span className="text-[12px] font-[570] text-[#717171] leading-[16px]">Step 1 of 4</span>
      <h1 className={"font-[670] leading-[40px] text-[36px] text-primary mb-4 pt-2"}>Choose a customer</h1>
      <input
        type="text"
        placeholder="Search"
        className="input mb-2 w-full border-4 border-[#F8F8F8] focus:ring-0 focus:border-[#e1e1e1 ] focus:outline-none text-primary font-[570] text-[14px] leading-[18px] placeholder:text-[#e1e1e1]placeholder:opacity-100 placeholder:font-[570]"
      />
      <div className={"flex-1 overflow-y-auto scrollbar-sm scroll-hidden"}>
        {
          loadingCustomers ? <SpinnerLoader />:
            customers?.length < 1 ?
              <div className={"flex items-center justify-center py-5"}>
                <p className={"text-black/60 font-bold"}>No Customer Found</p>
              </div>
              : customers?.map((customer, idx)=>(
                  <React.Fragment key={customer?.customerId}>
                    <div onClick={()=>handleSelectCustomer(customer)} className="flex items-center justify-between w-full gap-4 mx-auto cursor-pointer">
                      <div className="flex items-center justify-between gap-4 my-3">
                        <div className="flex items-center justify-between gap-4 my-3">
                          <Avatar />
                          <div className="flex flex-col">
                            <h4 className="font-bold">{customer?.companyName}</h4>
                            <p>{customer?.organizationNumber}</p>
                          </div>
                        </div>
                      </div>
                      <ChevronRight className="cursor-pointer text-[#a48080]" />
                    </div>
                    {
                      idx < customers?.length - 1 && <hr />
                    }
                  </React.Fragment>
                ))
          }
      </div>
      
      <Link to={`/app/accounts/${params?.clientId}/new-customer`}>
        <Button>
          <Plus className="" />
          <span className="font-extrabold text-white text-[18px] ml-5">Add a customer</span>
        </Button>
      </Link>
    </div>
  );
}

export default SelectCustomerPage;