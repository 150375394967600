import React, { useEffect } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { z } from "zod";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import toast from "react-hot-toast";

import { AppHeader } from "../../../../components/layout/app-header";
import { addCustomer } from "../../../../services/customers.service";
import Button from "../../../../components/ui/button";

const formSchema = z.object({
  companyName: z.string().optional(),
  organizationNumber: z.string().optional(),
  address: z.string().optional(),
  email: z.string().optional(),
});
interface FormSchemaType extends z.infer<typeof formSchema> { }

function AddCustomerPage() {

  const params = useParams();
  const navigate = useNavigate()

  const form = useForm<z.infer<typeof formSchema>>({
    resolver: zodResolver(formSchema),
    defaultValues: {
    },
  });

  async function onSubmit(values: FormSchemaType) {
    try {
      const formData = new FormData();

      formData.append("companyName", values.companyName as string);
      formData.append("organizationNumber", values.organizationNumber as string);
      formData.append("address", values.address as string);
      formData.append("email", values.email as string);
      formData.append("status", "ACTIVE");

      const result = await addCustomer(params?.clientId, formData);

      if (!result.success) {
        if (typeof result.error === "object" && result.error?.length > 0) {
          for (let error of result.error) {
            form.setError(error?.path[0], {
              type: "server",
              message: error.message,
            });
          }
        }
        return;
      }

      form.reset();
      navigate(`/app/accounts/${params?.clientId}/select-customer`);
    } catch (error) {
      toast.error("Failed to add customer! Please try again later.")
      console.log("Error =>", error);
    }
  }

  const { isSubmitting, errors } = form.formState;

  useEffect(() => {
    form.setFocus("companyName")
  }, []);

  return (
    <div className="w-full flex flex-col  p-4 justify-center max-w-[500px] h-screen">

      <AppHeader
        show_back
        back_url={`/app/accounts/${params?.clientId}/select-customer`}
        web_url={`/app/clients/${params?.clientId}/customers`}
      />

      <h1 className={"font-[670] leading-[40px] text-[36px] text-primary mb-4 pt-2"}>Add a customer</h1>
      <div className={"flex-1 overflow-y-auto scrollbar-sm scroll-hidden"}>
        <form className={" w-full"} onSubmit={form.handleSubmit(onSubmit)} noValidate>
          <div className="pt-5">
            <input
              {...form.register("companyName")}
              placeholder="Company name"
              className="input w-full bg-[#F8F8F8] focus:ring-0 focus:border-[#e1e1e1 ] focus:outline-none text-primary  text-[14px] leading-[18px] placeholder:text-[#e1e1e1 ] placeholder:opacity-100 "
            />
            {
              errors?.companyName?.message && <p className={"text-red-500 text-sm mt-1"}>{ errors?.companyName?.message}</p>
            }
          </div>
          <div className="pt-5">
            <input
              {...form.register("organizationNumber")}
              placeholder="Org.no"
              className="input w-full bg-[#F8F8F8] focus:ring-0 focus:border-[#e1e1e1 ] focus:outline-none
                        text-primary  text-[14px] leading-[18px] placeholder:text-[#e1e1e1 ]
                        placeholder:opacity-100
                      "
            />
            {
              errors?.organizationNumber?.message && <p className={"text-red-500 text-sm mt-1"}>{ errors?.organizationNumber?.message}</p>
            }
          </div>
          <div className="pt-5">
            <input
              {...form.register("address")}
              placeholder="Address"
              className="input w-full bg-[#F8F8F8] focus:ring-0 focus:border-[#e1e1e1 ] focus:outline-none
                        text-primary  text-[14px] leading-[18px] placeholder:text-[#e1e1e1 ]
                        placeholder:opacity-100
                      "
            />
            {
              errors?.address?.message && <p className={"text-red-500 text-sm mt-1"}>{ errors?.address?.message}</p>
            }
          </div>
          <div className="pt-5">
            <input
              {...form.register("email")}
              placeholder="Email"
              className="input w-full bg-[#F8F8F8] focus:ring-0 focus:border-[#e1e1e1 ] focus:outline-none
                        text-primary text-[14px] leading-[18px] placeholder:text-[#e1e1e1 ]
                        placeholder:opacity-100
                      "
            />
            {
              errors?.email?.message && <p className={"text-red-500 text-sm mt-1"}>{ errors?.email?.message}</p>
            }
          </div>

          <div className="flex items-center w-full gap-4 mt-20">
            <Link

              to={isSubmitting? "#" : `/app/accounts/${params?.clientId}/select-customer`}
              className={"flex-1 w-full"}
            >
              <Button disabled={isSubmitting} type={"button"} className={"flex-1"} variant={"ghost"}>
                Cancel
              </Button>
            </Link>
            <Button loading={isSubmitting} className={'flex-1 w-full'}>Add</Button>
          </div>
        </form>
      </div>

    </div>
  );
}

export default AddCustomerPage;