import React, { useEffect, useRef, useState } from "react";
import { AppHeader } from "../../../components/layout/app-header";
import { useNavigate, useParams } from "react-router-dom";
import Button from "../../../components/ui/button";
import NewTemplateOption from "./_components/new-template-option";
import { useTemplateStore } from "../../../store/templates.store";
import TemplateLoader from "../../../components/template-loader";
import { fetchStorageFile2, saveDocumentToServer } from "../../../services/templates.service";
import TemplateError from "../../../components/template-error";
import {
  addCustomProperty,
  closeDocX, getAllCustomProperties, getEntireDocXAsBlob,
  openDocXFromBlob,
  registerTagSelectionListener,
  registerTextSelectionListener
} from "../../../../lib/document-utilities";
import { TemplateTools } from "../../../../lib/template-utilities";
import { TemplateOption, TemplateOptionType } from "../../../../lib/template-options-types";
import TemplateTagOptions from "./_components/template-tag-options";
import { EditNumbersOption } from "./_components/edit-numbers-option";
import { EditAutoReplaceOption } from "./_components/edit-auto-replace-option";
import { toast } from "react-hot-toast";

const SingleTemplatePage = () => {
  const [screenView, setScreenView] = useState<string>("home");
  const [templateError, setTemplateError] = useState<string>("");
  const [addOptionBtnEnabled, setAddOptionBtnEnabled] = useState(false);
  const [tagOptions, setTagOptions] = useState<Record<string, TemplateOption> | {}>({});
  const [savingDocument, setSavingDocument] = useState(false);
  const [selectedTagText, setSelectedTagText] = useState<string>("");
  const [loadingTemplateDoc, setLoadingTemplateDoc] = useState<boolean>(false);

  // Text Options State
  const [selectedTagOption, setSelectedTagOption] = useState<any>(null);
  const [selectedNumberOption, setSelectedNumberOption] = useState<any>(null);
  const [selectedAutoReplaceOption, setSelectedAutoReplaceOption] = useState<any>(null);

  const loadingTemplate = useTemplateStore(state => state.loading);
  const loadClientTemplate = useTemplateStore(state => state.loadClientTemplate);
  const resetLoading = useTemplateStore(state => state.resetLoading);
  const documentOpened = useTemplateStore(state => state.documentOpened);
  const template = useTemplateStore(state => state.template);
  const setSelectedTemplate = useTemplateStore(state => state.setSelectedTemplate);
  const setCheckPreviousSession = useTemplateStore(state => state.setCheckPreviousSession);
  const setDocumentOpened = useTemplateStore(state => state.setDocumentOpened);

  const registerTagSelectionListenerRef = useRef(null);
  const registerTextSelectionListenerRef = useRef(null);

  const params = useParams();
  const navigate = useNavigate();

  const resetAppState = () => {
    setTagOptions([]);
    setSelectedTagText("");
    setSelectedTagOption(null);
    setSelectedNumberOption(null);
    setSelectedAutoReplaceOption(null);
  };

  const onBackClick = () => {
    if (screenView === "tag_options_edit") {
      getAllTagsWithOptions().then(() => {
      });
      setScreenView("home");
      setSelectedTagOption(null);
    } else if (screenView === "template_new_option") {
      setScreenView("home");
      setSelectedNumberOption(null);
    } else if (screenView === "auto_replace_option_edit") {
      setScreenView("home");
      selectedAutoReplaceOption(null);
    } else if (screenView === "number_option_edit") {
      setScreenView("home");
      setSelectedNumberOption(null);
    } else {
      setCheckPreviousSession(false);
      setDocumentOpened(false);

      setTimeout(() => {
        closeDocX().then(() => {
          resetAppState();
          navigate(`/app/accounts/${params?.clientId}/templates`);
        });
      }, 200);
    }
  };

  const getAllTagsWithOptions = async () => {
    try {
      const tagsWithOptions = await TemplateTools.getAllTagsWithOptions();
      setTagOptions(tagsWithOptions);

      console.log({ tagsWithOptions });
      await TemplateTools.highlightAllTags();
    } catch (e) {
      console.log("Errror", e);
    }
  };

  const handleSaveDocumentToServer = async () => {
    try {

      setSavingDocument(true);

      const documentBlob = await getEntireDocXAsBlob();

      const properties = await getAllCustomProperties();

      console.log({ properties });

      const formData = new FormData();

      formData.append("file", documentBlob);
      formData.append("templateId", params?.templateId);

      const response = await saveDocumentToServer(params?.clientId, formData);
      setSavingDocument(false);

      if (response.success) {
        toast.success("Document saved successfully.");

        setSelectedTemplate(response?.data?.template);
        console.log(response?.data);

        let version = response?.data?.template?.version;

        console.log("VERSION", version);

        if (version) {
          await addCustomProperty("templateVersion", version);
        }
        return;
      }

      toast.error("Failed to save document. Please try again later.");

    } catch (error: any) {
      setSavingDocument(false);
      toast.error("Failed to save document. Please try again later.");
    }
  };

  useEffect(() => {
    try {
      const registerCallBacks = async () => {
        registerTagSelectionListenerRef.current = await registerTagSelectionListener((tag: string | null) => {
          console.log({ tag });
        });

        registerTextSelectionListenerRef.current = await registerTextSelectionListener((selectedText_: string | null) => {
          console.log({ selectedText_ });
          setAddOptionBtnEnabled(!!selectedText_);
          setSelectedTagText(selectedText_ ?? "");

        });
      };

      const handleLoadTemplate = async () => {
        setLoadingTemplateDoc(true);
        try {
          console.log({ documentOpened });

          if (documentOpened) {
            TemplateTools.highlightAllTags();
            registerCallBacks();
            getAllTagsWithOptions();

            resetLoading();
            setLoadingTemplateDoc(false);
            return;
          }


          const template = await loadClientTemplate(params?.clientId, params?.templateId, false);

          if (template) {
            let { fileUrl } = template;

            const fileResponse = await fetchStorageFile2(fileUrl);
            resetLoading();

            if (fileResponse.success) {
              // await closeDocX();
              console.log("BEFORE OPENING DOCX");
              const documentProperties = await openDocXFromBlob(fileResponse.data);
              console.log({ documentProperties });

              TemplateTools.highlightAllTags();

              registerCallBacks();
              getAllTagsWithOptions();
              setLoadingTemplateDoc(false);
            }
          } else {
            setLoadingTemplateDoc(false);
          }
        } catch (e) {
          resetLoading();
          setTemplateError("An error occurred! Please try again later");
        }
      };

      if (params?.templateId && params?.clientId) {
        handleLoadTemplate().then();
      }
    } catch (error: any) {
      console.log(error);
    }

    const handleVisibilityChange = (event) => {

      console.log("Visibility Changed", document.visibilityState, document.hidden);

      if (document.visibilityState === "hidden") {
        localStorage.removeItem('loadedDocumentIdentification');
      }
    };

    document.addEventListener("visibilitychange", handleVisibilityChange);

    return () => {
      try {
        if (registerTagSelectionListenerRef.current) {
          registerTagSelectionListenerRef.current?.();
        }
        if (registerTextSelectionListenerRef.current) {
          registerTextSelectionListenerRef.current?.();
        }

        closeDocX().then(() => { });
      } catch (error: any) {
        console.log(error);
      }
      document.removeEventListener("visibilitychange", handleVisibilityChange);
    };
  }, [params?.clientId, params?.templateId]);


  if (loadingTemplate || loadingTemplateDoc) return <TemplateLoader />;

  if (templateError) return <TemplateError resetError={() => setTemplateError("")} error={templateError} />;

  const formattedTags = Object.keys(tagOptions).map(key => ({ key, ...tagOptions[key] }));

  const textOptions: Record<string, any>[] = formattedTags.filter(tagOption => tagOption.type === TemplateOptionType.TEXT);
  const rangeOptions: Record<string, any>[] = formattedTags.filter(tagOption => tagOption.type === TemplateOptionType.RANGE);
  const autoReplaceOptions: Record<string, any>[] = formattedTags.filter(tagOption => tagOption.type === TemplateOptionType.AUTO_REPLACE);

  return (
    <div className="w-full flex flex-col p-4 justify-center max-w-[500px] h-screen">
      <AppHeader
        show_back
        back_url={`#`}
        onBackClick={onBackClick}
        web_url={`/app/clients/${params?.clientId}/templates`}
      />

      {
        screenView === "home" && <>
          <h1 className={"font-[670] leading-[40px] text-[36px] text-primary mb-4 pt-2"}>{template?.name}</h1>

          {
            formattedTags?.length === 0 ? <div className={"flex justify-center flex-1 items-center"}>
              <h2>No options</h2>
            </div>
              :
              <div className={"flex-1 overflow-y-auto mt-4 scrollbar-sm scroll-hidden space-y-2"}>

                {
                  textOptions?.map((option, index) => (
                    <div key={index}
                      className={"border rounded-md flex justify-between items-center p-2 hover:bg-grayish"}>
                      <div className={"flex items-center gap-2"}>
                        <span
                          className={"bg-[#F4F4F4] rounded-[5px] h-[20px] w-[20px] flex items-center justify-center"}>
                          <svg width="16" height="16" viewBox="0 0 16 16" fill="none"
                            xmlns="http://www.w3.org/2000/svg">
                            <path
                              d="M8.49967 3.16666H5.16634C4.42996 3.16666 3.83301 3.76361 3.83301 4.49999V11.5C3.83301 12.2364 4.42996 12.8333 5.16634 12.8333H10.833C11.5694 12.8333 12.1663 12.2364 12.1663 11.5V6.83332M8.49967 3.16666V5.49999C8.49967 6.23637 9.09661 6.83332 9.83301 6.83332H12.1663M8.49967 3.16666L12.1663 6.83332"
                              stroke="#666666"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            />
                            <path d="M5.83301 10.5H10.1663" stroke="#666666" strokeLinecap="round"
                              strokeLinejoin="round" />
                            <path d="M5.83301 8.5H7.49967" stroke="#666666" strokeLinecap="round"
                              strokeLinejoin="round" />
                          </svg>
                        </span>
                        <div>
                          <h3 className={"font-[570] leading-[20px]"}>{option.key}</h3>
                          <p className={"font-[380]"}>{Object.keys(option?.values)?.length} text options</p>
                        </div>

                      </div>

                      <div className={"flex items-center gap-2"}>
                        <button onClick={() => {
                          setSelectedTagOption(option);
                          setScreenView("tag_options_edit");
                        }}>
                          <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path
                              d="M1.21685 13.9729C0.501485 14.1412 -0.141231 13.4985 0.0270893 12.7832L0.585981 10.4079C0.628124 10.2288 0.719372 10.065 0.849477 9.93486L8.18026 2.60403C8.2141 2.66799 8.25793 2.72804 8.31177 2.78188L11.2801 5.75024C11.3117 5.78185 11.3455 5.81002 11.3809 5.83474L4.06515 13.1505C3.93505 13.2806 3.77124 13.3719 3.59213 13.414L1.21685 13.9729Z"
                              fill="#666666" />
                            <path
                              d="M12.414 4.80159L13.7101 3.50548C14.0966 3.11907 14.0966 2.4926 13.7101 2.1062L11.8938 0.289804C11.5074 -0.0966013 10.8809 -0.0966013 10.4945 0.289804L9.18337 1.60091C9.24734 1.63475 9.30739 1.67859 9.36124 1.73243L12.3296 4.70079C12.3612 4.7324 12.3893 4.76616 12.414 4.80159Z"
                              fill="#666666" />
                          </svg>
                        </button>
                        <button
                          onClick={(e) => {
                            console.log("EYE Clicked");
                            e.preventDefault();
                            e.stopPropagation();
                            TemplateTools.scrollToTag(option.key);
                          }}
                        >
                          <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <g clipPath="url(#clip0_816_310)">
                              <path
                                d="M15.4569 7.7975C15.435 7.74813 14.9056 6.57375 13.7287 5.39687C12.1606 3.82875 10.18 3 7.99999 3C5.81999 3 3.83937 3.82875 2.27124 5.39687C1.09437 6.57375 0.562494 7.75 0.543119 7.7975C0.51469 7.86144 0.5 7.93064 0.5 8.00062C0.5 8.0706 0.51469 8.1398 0.543119 8.20375C0.564994 8.25312 1.09437 9.42688 2.27124 10.6038C3.83937 12.1713 5.81999 13 7.99999 13C10.18 13 12.1606 12.1713 13.7287 10.6038C14.9056 9.42688 15.435 8.25312 15.4569 8.20375C15.4853 8.1398 15.5 8.0706 15.5 8.00062C15.5 7.93064 15.4853 7.86144 15.4569 7.7975ZM7.99999 10.5C7.50554 10.5 7.02219 10.3534 6.61107 10.0787C6.19995 9.80397 5.87951 9.41352 5.6903 8.95671C5.50108 8.49989 5.45157 7.99723 5.54803 7.51227C5.64449 7.02732 5.8826 6.58186 6.23223 6.23223C6.58186 5.8826 7.02732 5.6445 7.51227 5.54804C7.99722 5.45157 8.49989 5.50108 8.9567 5.6903C9.41352 5.87952 9.80396 6.19995 10.0787 6.61107C10.3534 7.0222 10.5 7.50555 10.5 8C10.5 8.66304 10.2366 9.29893 9.76776 9.76777C9.29892 10.2366 8.66304 10.5 7.99999 10.5Z"
                                fill="#666666" />
                            </g>
                            <defs>
                              <clipPath id="clip0_816_310">
                                <rect width="16" height="16" fill="white" />
                              </clipPath>
                            </defs>
                          </svg>

                        </button>
                      </div>
                    </div>

                  ))
                }

                {
                  rangeOptions?.map((option, index) => (
                    <div key={index}
                      className={"border rounded-md flex justify-between items-center p-2 hover:bg-grayish"}>
                      <div className={"flex items-center gap-2"}>
                        <span
                          className={"bg-[#F4F4F4] rounded-[5px] h-[20px] w-[20px] flex items-center justify-center"}>
                          <svg width="16" height="16" viewBox="0 0 16 16" fill="none"
                            xmlns="http://www.w3.org/2000/svg">
                            <path
                              d="M3.16699 4.16666L4.16699 3.16666V6.83332M4.16699 6.83332H3.16699M4.16699 6.83332H4.83366"
                              stroke="#666666"
                              strokeLinecap="round" strokeLinejoin="round" />
                            <path
                              d="M8.83366 9.5H7.77113C7.28399 9.5 7.01246 8.95727 7.27086 8.5716C7.30266 8.52407 7.34773 8.4874 7.39533 8.45573L8.59526 7.65733C8.64286 7.6256 8.68779 7.5888 8.72026 7.54173C9.01719 7.11187 8.71453 6.5 8.16873 6.5H7.16699"
                              stroke="#666666" strokeLinecap="round" strokeLinejoin="round" />
                            <path
                              d="M11.167 9.83334H12.1313C12.9542 9.83334 13.1003 11.0917 12.3404 11.3037C12.3036 11.314 12.265 11.3181 12.2269 11.3195L11.8337 11.3333L12.2269 11.3472C12.265 11.3485 12.3036 11.3526 12.3404 11.3629C13.1003 11.5749 12.9542 12.8333 12.1313 12.8333H11.167"
                              stroke="#666666" strokeLinecap="round" strokeLinejoin="round" />
                          </svg>
                        </span>

                        <div>
                          <h3 className={"font-[570] leading-[20px]"}>{option?.key}</h3>
                          <p className={"font-[380] text-[14px] truncate"}>Range: {" "}
                            <span className={"font-bold"}>
                              {option?.values?.range?.min} -
                              {option?.values?.range?.max}
                            </span>
                          </p>
                          <p className={"font-[380] text-[13px] truncate"}>Pre-Approved Range: {" "}
                            <span className={"font-bold"}>
                              {option?.values?.preApprovedRange?.min} -
                              {option?.values?.preApprovedRange?.max}
                            </span>
                          </p>
                        </div>

                      </div>

                      <div className={"flex items-center gap-2"}>
                        <button onClick={() => {
                          setSelectedNumberOption(option);
                          setScreenView("number_option_edit");
                        }}>
                          <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path
                              d="M1.21685 13.9729C0.501485 14.1412 -0.141231 13.4985 0.0270893 12.7832L0.585981 10.4079C0.628124 10.2288 0.719372 10.065 0.849477 9.93486L8.18026 2.60403C8.2141 2.66799 8.25793 2.72804 8.31177 2.78188L11.2801 5.75024C11.3117 5.78185 11.3455 5.81002 11.3809 5.83474L4.06515 13.1505C3.93505 13.2806 3.77124 13.3719 3.59213 13.414L1.21685 13.9729Z"
                              fill="#666666" />
                            <path
                              d="M12.414 4.80159L13.7101 3.50548C14.0966 3.11907 14.0966 2.4926 13.7101 2.1062L11.8938 0.289804C11.5074 -0.0966013 10.8809 -0.0966013 10.4945 0.289804L9.18337 1.60091C9.24734 1.63475 9.30739 1.67859 9.36124 1.73243L12.3296 4.70079C12.3612 4.7324 12.3893 4.76616 12.414 4.80159Z"
                              fill="#666666" />
                          </svg>
                        </button>
                        <button
                          onClick={(e) => {
                            console.log("EYE Clicked");
                            e.preventDefault();
                            e.stopPropagation();
                            TemplateTools.scrollToTag(option.key);
                          }}
                        >
                          <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <g clipPath="url(#clip0_816_310)">
                              <path
                                d="M15.4569 7.7975C15.435 7.74813 14.9056 6.57375 13.7287 5.39687C12.1606 3.82875 10.18 3 7.99999 3C5.81999 3 3.83937 3.82875 2.27124 5.39687C1.09437 6.57375 0.562494 7.75 0.543119 7.7975C0.51469 7.86144 0.5 7.93064 0.5 8.00062C0.5 8.0706 0.51469 8.1398 0.543119 8.20375C0.564994 8.25312 1.09437 9.42688 2.27124 10.6038C3.83937 12.1713 5.81999 13 7.99999 13C10.18 13 12.1606 12.1713 13.7287 10.6038C14.9056 9.42688 15.435 8.25312 15.4569 8.20375C15.4853 8.1398 15.5 8.0706 15.5 8.00062C15.5 7.93064 15.4853 7.86144 15.4569 7.7975ZM7.99999 10.5C7.50554 10.5 7.02219 10.3534 6.61107 10.0787C6.19995 9.80397 5.87951 9.41352 5.6903 8.95671C5.50108 8.49989 5.45157 7.99723 5.54803 7.51227C5.64449 7.02732 5.8826 6.58186 6.23223 6.23223C6.58186 5.8826 7.02732 5.6445 7.51227 5.54804C7.99722 5.45157 8.49989 5.50108 8.9567 5.6903C9.41352 5.87952 9.80396 6.19995 10.0787 6.61107C10.3534 7.0222 10.5 7.50555 10.5 8C10.5 8.66304 10.2366 9.29893 9.76776 9.76777C9.29892 10.2366 8.66304 10.5 7.99999 10.5Z"
                                fill="#666666" />
                            </g>
                            <defs>
                              <clipPath id="clip0_816_310">
                                <rect width="16" height="16" fill="white" />
                              </clipPath>
                            </defs>
                          </svg>

                        </button>
                      </div>
                    </div>
                  ))
                }
                {
                  autoReplaceOptions?.map((option, index) => (
                    <div key={index}
                      className={"border rounded-md flex justify-between items-center p-2 hover:bg-grayish"}>
                      <div className={"flex items-center gap-2"}>
                        <span
                          className={"bg-[#F4F4F4] rounded-[5px] h-[20px] w-[20px] flex items-center justify-center"}>
                          <svg width="16" height="16" viewBox="0 0 16 16" fill="none"
                            xmlns="http://www.w3.org/2000/svg">
                            <path
                              d="M6.21966 9.78019L7.99987 6.21979L9.78007 9.78019M6.90436 8.68469H9.09538M4.9488 3.06604L3.65039 4.23461L4.9488 5.40318M4.16963 4.23461H9.10359C10.8246 4.23461 12.2198 5.62977 12.2198 7.35079V7.48064M11.0594 13.4325L12.3578 12.2639L11.0594 11.0954M11.8383 12.264H6.90436C5.18333 12.264 3.78817 10.8688 3.78817 9.14779V9.01795"
                              stroke="#666666" strokeLinecap="round" strokeLinejoin="round" />
                          </svg>

                        </span>

                        <div>
                          <h3 className={"font-[570] leading-[20px]"}>{option?.value}</h3>
                          <p className={"font-[380]"}>{option?.key}</p>
                        </div>

                      </div>

                      <div className={"flex items-center gap-2"}>
                        <button
                          onClick={() => {
                            setSelectedAutoReplaceOption(option);
                            setScreenView("auto_replace_option_edit");
                          }}
                        >
                          <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path
                              d="M1.21685 13.9729C0.501485 14.1412 -0.141231 13.4985 0.0270893 12.7832L0.585981 10.4079C0.628124 10.2288 0.719372 10.065 0.849477 9.93486L8.18026 2.60403C8.2141 2.66799 8.25793 2.72804 8.31177 2.78188L11.2801 5.75024C11.3117 5.78185 11.3455 5.81002 11.3809 5.83474L4.06515 13.1505C3.93505 13.2806 3.77124 13.3719 3.59213 13.414L1.21685 13.9729Z"
                              fill="#666666" />
                            <path
                              d="M12.414 4.80159L13.7101 3.50548C14.0966 3.11907 14.0966 2.4926 13.7101 2.1062L11.8938 0.289804C11.5074 -0.0966013 10.8809 -0.0966013 10.4945 0.289804L9.18337 1.60091C9.24734 1.63475 9.30739 1.67859 9.36124 1.73243L12.3296 4.70079C12.3612 4.7324 12.3893 4.76616 12.414 4.80159Z"
                              fill="#666666" />
                          </svg>
                        </button>
                        <button
                          onClick={(e) => {
                            console.log("EYE Clicked");
                            e.preventDefault();
                            e.stopPropagation();
                            TemplateTools.scrollToTag(option.key);
                          }}
                        >
                          <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <g clipPath="url(#clip0_816_310)">
                              <path
                                d="M15.4569 7.7975C15.435 7.74813 14.9056 6.57375 13.7287 5.39687C12.1606 3.82875 10.18 3 7.99999 3C5.81999 3 3.83937 3.82875 2.27124 5.39687C1.09437 6.57375 0.562494 7.75 0.543119 7.7975C0.51469 7.86144 0.5 7.93064 0.5 8.00062C0.5 8.0706 0.51469 8.1398 0.543119 8.20375C0.564994 8.25312 1.09437 9.42688 2.27124 10.6038C3.83937 12.1713 5.81999 13 7.99999 13C10.18 13 12.1606 12.1713 13.7287 10.6038C14.9056 9.42688 15.435 8.25312 15.4569 8.20375C15.4853 8.1398 15.5 8.0706 15.5 8.00062C15.5 7.93064 15.4853 7.86144 15.4569 7.7975ZM7.99999 10.5C7.50554 10.5 7.02219 10.3534 6.61107 10.0787C6.19995 9.80397 5.87951 9.41352 5.6903 8.95671C5.50108 8.49989 5.45157 7.99723 5.54803 7.51227C5.64449 7.02732 5.8826 6.58186 6.23223 6.23223C6.58186 5.8826 7.02732 5.6445 7.51227 5.54804C7.99722 5.45157 8.49989 5.50108 8.9567 5.6903C9.41352 5.87952 9.80396 6.19995 10.0787 6.61107C10.3534 7.0222 10.5 7.50555 10.5 8C10.5 8.66304 10.2366 9.29893 9.76776 9.76777C9.29892 10.2366 8.66304 10.5 7.99999 10.5Z"
                                fill="#666666" />
                            </g>
                            <defs>
                              <clipPath id="clip0_816_310">
                                <rect width="16" height="16" fill="white" />
                              </clipPath>
                            </defs>
                          </svg>

                        </button>
                      </div>
                    </div>
                  ))
                }

              </div>
          }
          <div className="flex w-full gap-2">
            <Button disabled={!addOptionBtnEnabled} onClick={() => setScreenView("template_new_option")} variant="ghost"
              className="flex-1">Add an option</Button>
            <Button onClick={handleSaveDocumentToServer} loading={savingDocument} className="flex-1">Save</Button>
          </div>
        </>
      }

      {
        screenView === "tag_options_edit" &&
        <TemplateTagOptions
          selectedTagOption={selectedTagOption}
          setScreenView={setScreenView}
          setSelectedTagOption={setSelectedTagOption}
          reloadTags={() => {
            getAllTagsWithOptions();
          }}
        />
      }

      {
        screenView === "number_option_edit" &&
        <EditNumbersOption
          selectedNumberOption={selectedNumberOption}
          setScreenView={setScreenView}
          setSelectedNumberOption={setSelectedNumberOption}
          reloadTags={() => {
            getAllTagsWithOptions();
          }}
        />
      }

      {
        screenView === "auto_replace_option_edit" &&
        <EditAutoReplaceOption
          selectedAutoReplaceOption={selectedAutoReplaceOption}
          setScreenView={setScreenView}
          setSelectedAutoReplaceOption={setSelectedAutoReplaceOption}
          reloadTags={() => {
            getAllTagsWithOptions();
          }}
        />
      }

      {
        screenView === "template_new_option" &&
        <NewTemplateOption
          selectedText={selectedTagText}
          setScreenView={setScreenView}
          onComplete={() => {
            getAllTagsWithOptions();
          }}
        />
      }

    </div>
  );
};

export default SingleTemplatePage;