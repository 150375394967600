import React from "react";
import { AppLogo } from "./AppLogo";
import { Link } from "react-router-dom";
import AppHeaderMenu from "./app-header-menu";
import Button from "../ui/button";

interface AppHeaderProps {
  web_url: string;
  show_back?: boolean;
  back_url?: string;
  back_text?: string;
  onBackClick?: () => void;
}

export const AppHeader = ({ web_url, back_text, show_back = false, back_url = "#", onBackClick }: AppHeaderProps) => {
  return (
    <div className="flex items-center justify-between w-full py-6 mx-auto">
      <div className="flex items-center gap-2">
        {
          show_back &&
          <Button className="w-max" variant="ghost">
            <Link
              to={back_url}
              className="flex items-center gap-2"
              onClick={(event) => {
                if (onBackClick) {
                  event.preventDefault();
                  onBackClick();
                }
              }}
            >
              <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M18.5625 11L3.4375 11" stroke="black" strokeWidth="2" strokeLinecap="round"
                  strokeLinejoin="round" />
                <path d="M9.625 17.1875L3.4375 11L9.625 4.8125" stroke="black" strokeWidth="2" strokeLinecap="round"
                  strokeLinejoin="round" />
              </svg>

              {back_text && <span className="text-primary text-[14px] leading-[18px] font-[670]">{back_text}</span>}
            </Link>
          </Button>
        }
        <AppLogo />
      </div>
      <div className="flex items-center justify-between gap-4">
        <Link to={web_url} target={"_blank"} className={"hidden"} rel={"noreferrer"}>
          <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clipPath="url(#clip0_516_5203)">
              <path
                d="M14.25 8.25H17.25V11.25"
                stroke="#A4A4A4"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M9.75 15.75H6.75V12.75"
                stroke="#A4A4A4"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M20.25 4.5H3.75C3.33579 4.5 3 4.83579 3 5.25V18.75C3 19.1642 3.33579 19.5 3.75 19.5H20.25C20.6642 19.5 21 19.1642 21 18.75V5.25C21 4.83579 20.6642 4.5 20.25 4.5Z"
                stroke="#A4A4A4"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </g>
            <defs>
              <clipPath id="clip0_516_5203">
                <rect width="24" height="24" fill="white" />
              </clipPath>
            </defs>
          </svg>
        </Link>

        <AppHeaderMenu />

      </div>
    </div>
  );
};