import React, { useEffect } from "react";
import { useCustomerStore } from "../../../../store/customers.store";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import SpinnerLoader from "../../../../components/spinner-loader";
import { cn } from "../../../../utils";
import { ChevronRight } from "lucide-react";
import Button from "../../../../components/ui/button";
import { updateContract } from "../../../../services/workspace.service";
import toast from "react-hot-toast";
import { useWorkspaceStore } from "../../../../store/workspace.store";

interface Props {
  onSuccess: () => void;
  contract: Workspace;
}

const ContractChangeContact = ({ onSuccess, contract }: Props) => {

  const loadClientCustomers = useCustomerStore((state) => state.loadClientCustomers);
  const loadingCustomers = useCustomerStore((state) => state.loading);
  const customers = useCustomerStore((state) => state.customers);
  const loadWorkspace = useWorkspaceStore(state => state.loadWorkspace);

  const [selectedContact, setSelectedContact] = React.useState<CustomerContact | null>(contract.contact);
  const [updating, setUpdating] = React.useState(false);

  const params = useParams();
  const [searchParams] = useSearchParams();

  const contractVersion = searchParams.get("version");

  const handleSaveSubmit = async () => {
    setUpdating(true);

    const response = await updateContract(Number(params?.clientId), {
      "contractId": contract.contractId,
      "contractVersion": contract.version,
      "contactId": selectedContact.contactId,
    })

    if(response.success){
      toast.success("Contact update successfully.");
    }else{
      toast.error("Failed to update contact! Please try again later")
    }
    await loadWorkspace(Number(params.clientId), Number(params.contractId), Number(contractVersion));
    setUpdating(false);

    onSuccess();
  };

  useEffect(() => {
    if (params.clientId) {
      loadClientCustomers(Number(params.clientId)).then();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params.clientId]);

  const currentCustomer = customers.find(c => c.customerId === contract.customerId);

  return (
    <div className={"h-full flex-1 flex flex-col "}>
      <h1 className={"font-[670] leading-[40px] text-[36px] text-primary mb-4 pt-2"}>Choose a contact</h1>
      <div className={"flex-1 overflow-y-auto scrollbar-sm scroll-hidden"}>
        {
          loadingCustomers ? <SpinnerLoader /> :
            <>
              {
                currentCustomer?.billings?.length < 1 ?
                  <div className={"flex items-center justify-center py-5"}>
                    <p className={"text-black/60 font-bold"}>No Contact Found</p>
                  </div>
                  :
                  currentCustomer?.contacts?.map((contact, idx) => (
                    <React.Fragment key={contact?.contactId}>
                      <div onClick={() => setSelectedContact(contact)}
                           className={cn("flex items-center px-2 rounded-lg justify-between w-full gap-4 cursor-pointer", {
                             "bg-gray-100 my-1.5": selectedContact.contactId === contact?.contactId
                           })}
                      >
                        <div className="flex items-center justify-between gap-4 my-3">
                          <div className="flex flex-col">
                            <h4 className="font-bold">{contact?.name}</h4>
                            <h4 className="font-normal">{contact?.title}</h4>
                            <p className="font-normal text-[#b3a8a8]">{contact?.phoneNumber}</p>
                            <p className="text-[#b3a8a8] font-normal">{contact?.email}</p>
                          </div>
                        </div>
                      </div>
                      {
                        idx < currentCustomer?.billings?.length - 1 && <hr />
                      }
                    </React.Fragment>
                  ))
              }

            </>
        }
      </div>

      <Button disabled={selectedContact.contactId === contract.contact?.contactId} loading={updating} onClick={handleSaveSubmit}>
        <span className="font-extrabold text-white text-[18px] ml-5">Save</span>
      </Button>

    </div>
  );
};

export default ContractChangeContact