import React, { Dispatch, SetStateAction, useEffect, useState } from "react";
import { TemplateTools } from "../../../../../lib/template-utilities";
import { toast } from "react-hot-toast";
import { ConfirmModal } from "../../../../components/confirm-modal";
import Button from "../../../../components/ui/button";
import AutoReplaceOption from "./new-template-option/_components/auto-replace-option";

interface EditAutoReplaceOptionProps {
  selectedAutoReplaceOption: any;
  setScreenView: Dispatch<SetStateAction<string>>;
  setSelectedAutoReplaceOption: Dispatch<SetStateAction<any|null>>;
  reloadTags?: ()=>void;
}

export function EditAutoReplaceOption({setScreenView, selectedAutoReplaceOption, setSelectedAutoReplaceOption, reloadTags=()=>{}}:EditAutoReplaceOptionProps){
  const [saving, setSaving] = useState(false);

  const [errors, setErrors] = useState<Record<string, string>>({});

  const [autoReplaceValues, setAutoReplaceValues] = useState<Record<string, string>>({
    orgNo: ""
  })

  const handleAutoReplaceInputChange = (key: string, value: string) => {
    setAutoReplaceValues(prev=>({ ...prev, [key]: value }))
    setErrors(prev=>({ ...prev, [key]: "" }))
  }

  const validateErrors = ()=>{
    let isValid = true;

    if(!autoReplaceValues.orgNo) {
      setErrors(prev=>({...prev, orgNo: "Field is required"}))
      isValid = false;
    }
    else {
      setErrors(prev=>({...prev, orgNo: ""}))
    }

    return isValid;
  }

  const handleSave = async ()=>{
    try {

      if(validateErrors()){
        setSaving(true);

        await TemplateTools.upsertOption(
          selectedAutoReplaceOption.key,
          selectedAutoReplaceOption.type,
          autoReplaceValues.orgNo
        )

        toast.success("Option updated successfully.");
        setSaving(false);
        reloadTags()
        setScreenView("home");
        setSelectedAutoReplaceOption(null)
        await TemplateTools.updateContentFromDefaultValue(selectedAutoReplaceOption.key)
        return;
      }

    } catch(error: any){
      setSaving(false);
      toast.error("Failed to update option. Please try again later.")
    }
  }

  const handleDeleteTagOption = async ()=>{
    try {
      await TemplateTools.removeTag(selectedAutoReplaceOption?.key);
      setSelectedAutoReplaceOption(null);
      reloadTags();
      setScreenView("home")
      toast.success("Tag option removed successfully!");
    } catch(error: any){
      toast.error("Failed to delete option. Please try again later.")
    }
  }

  useEffect(() => {
    if(selectedAutoReplaceOption?.key){
      //values?.preApprovedRange?.preApprovedMin
      setAutoReplaceValues({
        orgNo: selectedAutoReplaceOption?.value,
      })
    }
  }, [selectedAutoReplaceOption]);

  if(!selectedAutoReplaceOption?.key) return null;

  return (
    <>
      <div className={"flex justify-between items-center"}>
        <h1 className={"font-[670] leading-[40px] text-[36px] text-primary mb-4 pt-2"}>{selectedAutoReplaceOption?.key}</h1>

        <div>
          <ConfirmModal message="Please confirm you want to delete the option value"
                        onConfirm={handleDeleteTagOption}>
            <Button
              className={"bg-[#ED4B4B0F] w-max hover:bg-[#ED4B4B0F] text-[#ED4B4B] "}
              variant={"ghost"}
              loaderClasses={"text-[#ED4B4B]"}
            >
              <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path fillRule="evenodd" clipRule="evenodd"
                      d="M5.04148 3.04603H1.75C1.33579 3.04603 1 3.388 1 3.80984C1 4.23167 1.33579 4.57364 1.75 4.57364H2.29744L3.03566 11.9045C3.07233 12.2688 3.10416 12.585 3.15066 12.8451C3.19999 13.121 3.27483 13.3923 3.42741 13.6499C3.65663 14.0369 3.99309 14.3462 4.39449 14.5389C4.66168 14.6672 4.93432 14.7154 5.20888 14.7372C5.46769 14.7578 5.77973 14.7578 6.13935 14.7578H9.8607C10.2203 14.7578 10.5323 14.7578 10.7911 14.7372C11.0657 14.7154 11.3383 14.6672 11.6055 14.5389C12.0069 14.3462 12.3434 14.0369 12.5726 13.6499C12.7252 13.3923 12.8 13.121 12.8494 12.8451C12.8959 12.585 12.9277 12.2689 12.9644 11.9046L13.7027 4.57364H14.25C14.6642 4.57364 15 4.23167 15 3.80984C15 3.388 14.6642 3.04603 14.25 3.04603H10.9585C10.7205 1.60105 9.4865 0.5 8 0.5C6.5135 0.5 5.27952 1.60105 5.04148 3.04603ZM6.58535 3.04603H9.41465C9.20873 2.4527 8.65311 2.02762 8 2.02762C7.34689 2.02762 6.79127 2.4527 6.58535 3.04603ZM12.1949 4.57364H3.8053L4.52507 11.7212C4.56531 12.1208 4.59164 12.377 4.6264 12.5714C4.65949 12.7565 4.69091 12.8255 4.71183 12.8608C4.78823 12.9898 4.90039 13.0929 5.03419 13.1572C5.07081 13.1748 5.14146 13.1996 5.32562 13.2142C5.51905 13.2296 5.77198 13.2301 6.16646 13.2301H9.83358C10.2281 13.2301 10.481 13.2296 10.6744 13.2142C10.8586 13.1996 10.9292 13.1748 10.9658 13.1572C11.0996 13.093 11.2118 12.9898 11.2882 12.8609C11.3091 12.8255 11.3405 12.7565 11.3736 12.5714C11.4084 12.377 11.4347 12.1209 11.475 11.7212L12.1949 4.57364ZM6.21255 5.59301C6.62624 5.57194 6.97838 5.89641 6.99906 6.31772L7.24906 11.4098C7.26975 11.8311 6.95115 12.1897 6.53745 12.2108C6.12376 12.2318 5.77162 11.9074 5.75094 11.4861L5.50094 6.394C5.48025 5.97269 5.79885 5.61407 6.21255 5.59301ZM9.78745 5.59301C10.2011 5.61407 10.5197 5.97269 10.4991 6.394L10.2491 11.4861C10.2284 11.9074 9.87624 12.2318 9.46255 12.2108C9.04885 12.1897 8.73025 11.8311 8.75094 11.4098L9.00094 6.31772C9.02162 5.89641 9.37376 5.57194 9.78745 5.59301Z"
                      fill="#ED4B4B" />
              </svg>

              <span>Delete</span>
            </Button>
          </ConfirmModal>
        </div>
      </div>

      <div className={"flex-1 overflow-y-auto scrollbar-sm scroll-hidden space-y-8 mt-6"}>
        <AutoReplaceOption values={autoReplaceValues} errors={errors} handleAutoReplaceInputChange={handleAutoReplaceInputChange} />
      </div>

      <hr className={"my-3"} />

      <div className="flex w-full gap-2">
        <Button onClick={handleSave} loading={saving} className="flex-1">Done</Button>
      </div>
    </>
  )
}