import { Loader } from "lucide-react";
import React from 'react'

const SpinnerLoader = () => {
  return (
    <div className="min-h-[250px] flex justify-center items-center">
      <Loader size={40} className="animate-spin opacity-75" />
    </div>
  )
}

export default SpinnerLoader