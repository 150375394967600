import React, { ElementRef, useRef } from "react";
import Button from "./ui/button";

interface ConfirmModalProps {
  children: React.ReactNode;
  onConfirm: () => void;
  title?: string;
  message?: string;
  disabled?: boolean;
}

export const ConfirmModal = ({ children, disabled, onConfirm, title = "Are you sure?", message ="This action cannot be undone." }: ConfirmModalProps)=>{

  const modalInputRef = useRef<ElementRef<"input">>()

  return (
    <div className={"w-full flex-1"}>
      <div className={"w-full"} onClick={disabled? ()=>{} :()=>{modalInputRef?.current?.click()}} >{children}</div>

      <input ref={modalInputRef} type="checkbox" id="confirm_modal_a" className="modal-toggle" />
      <div className="modal" role="dialog">
        <div className="modal-box">
          <h3 className="text-lg font-bold">{title}</h3>
          <p className="py-4">{message}</p>
          <div className="flex justify-between items-center">
            <Button type={"button"} variant={"ghost"} onClick={()=>{modalInputRef?.current?.click()}} className={"w-max"}>Cancel</Button>
            <Button type={"button"} className={"w-max"} onClick={()=>{
              onConfirm();
              modalInputRef?.current?.click()
            }}>Confirm</Button>
          </div>
        </div>
        <label className="modal-backdrop" htmlFor="confirm_modal_a">Close</label>
      </div>
    </div>
  )
}
