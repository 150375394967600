// TODO XXX - Same as backend/src/lib/template-options-types.ts

export enum TemplateOptionType {
    TEXT = 'TEXT',
    RANGE = 'RANGE',
    AUTO_REPLACE = 'AUTO_REPLACE',
}

export interface TextTemplateValue {
    text: string;
    needsApproval: boolean;
}

export interface RangeTemplateValue {
    range: {
        min: number;
        max: number;
    };
    preApprovedRange: {
        min: number;
        max: number;
    };
}

export type AutoReplaceTemplateValue = string;

export type TextTemplateValues = Record<string, TextTemplateValue>;

export interface TemplateOptionBase {
    type: TemplateOptionType;
}

export interface TextTemplateOption extends TemplateOptionBase {
    type: TemplateOptionType.TEXT;
    value: string;
    values: TextTemplateValues;
}

export interface RangeTemplateOption extends TemplateOptionBase {
    type: TemplateOptionType.RANGE;
    value: number;
    values: RangeTemplateValue;
}

export interface AutoReplaceTemplateOption extends TemplateOptionBase {
    type: TemplateOptionType.AUTO_REPLACE;
    value: string;
}

// Union the specific type interfaces for the full definition of possible options
export type TemplateOption = TextTemplateOption | RangeTemplateOption | AutoReplaceTemplateOption;
export type TemplateOptions = Record<string, TemplateOption | null>;

/*
{
  "PRIORITY": {
    "type": "TEXT",
    "value": "DEFAULT",
    "values": {
      "NAME OF BETTER": {
        "text": "better",
        "needsApproval": false
      }
    },
  },

  "LENGTH": {
    "type": "RANGE",
    "values": {
      "range": {
        "min": 1,
        "max": 10
      },
      "preApprovedRange": {
        "min": 2,
        "max": 8
      }
    },
    value: 5,
  },

  "COMPANY_NAME": {
    "type": "AUTO_REPLACE",
    value: "companyName"
  },

  "NOTHING": null
}
*/