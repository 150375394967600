import { create } from 'zustand';

type AuthStore = {
	authUser: User | null;
	loginEmail: string | null;
	loading: boolean;

	setLoginEmail: (email: string|null) => void;
	setAuthUser: (authUser: User | null) => void;
};

export const useAuthStore = create<AuthStore>((set) => ({
	authUser: null,
	loginEmail: null,
	loading: true,

	setLoginEmail: (email: string|null) => set({ loginEmail: email }),
	setAuthUser: (authUser: User | null) => set({ authUser, loginEmail: null }),

}));