import React from "react";
import { useAuthStore } from "../../store/auth.store";
import { closeDocX } from "../../../lib/document-utilities";
import { useNavigate } from "react-router-dom";

const AppHeaderMenu = () => {

  const authUser = useAuthStore(state => state.authUser);
  const setAuthUser = useAuthStore(state => state.setAuthUser);

  const navigate = useNavigate();

  const initials = authUser?authUser?.firstName?.charAt(0) + authUser?.lastName?.charAt(0): 'U';

  const handleLogout = async () => {
    closeDocX().then(() => { 
      localStorage.clear();
      setAuthUser(null);
      navigate("/auth/login");
    });
  }
  
  return (
    <>
      <div className="dropdown dropdown-end">
        <div tabIndex={0} role="button" className="m-0">
          <div className="h-12 w-12 rounded-full bg-[#f3df30] hover:bg-[#f3df30]/80 btn flex items-center justify-center ">{initials}</div>
        </div>
        <ul tabIndex={0} className="dropdown-content menu bg-base-100 rounded-md z-[1] w-[130px] p-2 shadow">
          <li>
            <div className="min-h-0 py-2.5 h-max btn" onClick={handleLogout}>Log out</div>
          </li>
        </ul>
      </div>

    </>
  );
};

export default AppHeaderMenu;