import { cn } from "../../../../utils";
import React, { Dispatch, ElementRef, SetStateAction, useRef } from "react";
import { Edit } from "lucide-react";
import Button from "../../../../components/ui/button";
import toast from "react-hot-toast";
import { useWorkspaceStore } from "../../../../store/workspace.store";
import { updateContract } from "../../../../services/workspace.service";

interface ContractHeaderProps {
  workspace: Workspace;
  currentTab: number;
  setCurrentTab: Dispatch<SetStateAction<number>>;
}

const statuses = [
  "SENT", "RECEIVED", "SIGNED", "ABONDONED", "DISCONTINUED", "EXPIRED", "ARCHIVED"
];

const ContractHeader = ({ workspace, currentTab, setCurrentTab }: ContractHeaderProps) => {

  let status = workspace.status.toLowerCase();

  const [selectedStatus, setSelectedStatus] = React.useState<string>( workspace.status ?? "");
  const [updating, setUpdating] = React.useState(false);

  const modalInputRef = useRef<ElementRef<"input">>();

  const loadWorkspace = useWorkspaceStore(state=>state.loadWorkspace)

  const closeModal = () => {
    modalInputRef?.current?.click();
  };

  const handleUpdate = async ()=>{
    if(selectedStatus === "") return;

    setUpdating(true)

    const response = await updateContract(workspace.clientId, {
      contractId: workspace.contractId,
      contractVersion: workspace.version,
      status: selectedStatus,
    })

    setUpdating(false);

    if(!response.success){
      toast.error("Failed to update contact status! Please try again later")
      return;
    }

    toast.success("Status updated successfully.");
    await loadWorkspace(workspace.clientId, workspace.contractId, workspace.version, true)
    closeModal()
  }

  return (
    <>
      <h2 className={"text-[28px] leading-[32px] text-black font-[670]"}>
        {workspace.name}
      </h2>

      <div className={"flex items-center gap-2 mt-2"}>
        <p className={cn("flex items-center gap-1.5", {
          "text-orange-400": ["preparing", "received"].includes(status),
          "text-red-400": ["escalated", "abandoned", "discontinued", "expired"].includes(status),
          "text-green-400": ["ready", "sent", "signed"].includes(status),
          "text-grey-400": ["archived"].includes(status)
        })}>
          <svg width="8" height="8" viewBox="0 0 8 8" fill="none" xmlns="http://www.w3.org/2000/svg">
            <circle cx="4" cy="4" r="4" fill="currentColor" />
          </svg>
          <span className={cn("text-current font-[380] text-[12px] leading-[16px]")}>
              {workspace?.status}
            </span>
        </p>

        <label htmlFor="change_contract_status_modal" className="">
          <Edit className={"text-black/70 cursor-pointer"} size={16} />
        </label>

        <input type="checkbox" ref={modalInputRef} id="change_contract_status_modal" className="modal-toggle" />
        <div className="modal" role="dialog">
          <div className="max-w-sm modal-box">
            <h3 className="text-lg font-bold">Select Status</h3>

            <select
              value={selectedStatus}
              onChange={e => setSelectedStatus(e.target.value)}
              className="w-full mt-5 select select-bordered"
            >
              <option value={""} disabled>Select Status</option>
              {
                statuses.map((item) => (
                  <option key={item}>{item}</option>
                ))
              }
            </select>
            <div className="flex items-center gap-4 mt-3">
              <Button className={"flex-1"} variant={"ghost"} onClick={closeModal}>Close</Button>
              <Button loading={updating} onClick={handleUpdate} className={"flex-1"}>Update</Button>
            </div>
          </div>
        </div>
      </div>

      <div className={"grid grid-cols-2 mt-4"}>
        <div className={"w-full"}>
          <p className={"font-[380] text-[12px] leading-[16px] text-[#717171] "}>Created by</p>
          <p
            className={"font-[380] text-[14px] leading-[20px] text-[#000000] "}>{workspace.user?.firstName} {workspace.user?.lastName}</p>
        </div>
        <div className={"w-full"}>
          <p className={"font-[380] text-[12px] leading-[16px] text-[#717171] "}>For</p>
          <p
            className={"font-[380] text-[14px] leading-[20px] text-[#000000] "}>{workspace?.customer?.companyName}</p>
        </div>
      </div>

      <div className={"mt-8 bg-[#EDEDED] p-[3px] w-full rounded-[32px] flex gap-[4px] h-[42px]"}>
        <button
          className={cn("flex-1 cursor-pointer w-full rounded-full text-sm flex items-center h-full justify-center", {
            "bg-white": currentTab === 0
          })}
          onClick={() => setCurrentTab(0)}
        >
          Amendments
        </button>
        <button
          className={cn("flex-1 cursor-pointer w-full rounded-full text-sm flex items-center h-full justify-center", {
            "bg-white": currentTab === 1
          })}
          onClick={() => setCurrentTab(1)}
        >
          Options
        </button>
        <button
          className={cn("flex-1 cursor-pointer w-full rounded-full text-sm flex items-center h-full justify-center", {
            "bg-white": currentTab === 2
          })}
          onClick={() => setCurrentTab(2)}
        >
          Info
        </button>

      </div>
    </>
  );
};

export default ContractHeader;