import { apiCall } from "./helpers";

export const addContract = async (clientId: number, details: any) => {
	return await apiCall({
		url: `contracts/create?clientId=${clientId}`,
		method: "POST",
		data: details,
	});
};

export const saveContractOptionChanges = async (clientId: number, details: any) => {
	return await apiCall({
		url: `contracts/amendment?clientId=${clientId}`,
		method: "POST",
		data: details,
	});
};

export const updateContract = async (clientId: number, details: any) => {
	return await apiCall({
		url: `contracts/update?clientId=${clientId}`,
		method: "PATCH",
		data: details,
	});
};


export const fetchContracts = async (clientId: number, status: string) => {
	return await apiCall({
		url: `contracts/list?clientId=${clientId}&status=${status}`
	});
};

export const fetchContract = async (clientId: number, contractId: number, contractVersion: number) => {
	return await apiCall({
		url: `contracts/get?clientId=${clientId}&contractId=${contractId}&${contractVersion && `contractVersion=${contractVersion}`}`
	});
};

export const downloadContract = async (clientId: number, contractId: number, contractVersion: number) => {
	return await apiCall({
		url: `contracts/download?clientId=${clientId}&contractId=${contractId}&${contractVersion && `contractVersion=${contractVersion}`}`,
		responseType: 'blob'
	});
};

export const fetchContractAutoCompleteKeys = async (clientId: number) => { 
	return await apiCall({
		url: `contracts/autocompleteKeys?clientId=${clientId}`
	});
};