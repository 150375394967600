// @ts-nocheck

import React, { useEffect, useState } from "react";
import Button from "../../../../components/ui/button";
import { TemplateOptionType } from "../../../../../lib/template-options-types";
import { cn } from "../../../../utils";
import toast from "react-hot-toast";
import { saveContractOptionChanges } from "../../../../services/workspace.service";
import { useParams } from "react-router-dom";
import { useWorkspaceStore } from "../../../../store/workspace.store";
import {
  Handle,
  Segment,
  Tick,
  TickLabel,
  Track
} from "../../../templates/template/_components/new-template-option/_components/range-option";
import { Ranger, useRanger } from "@tanstack/react-ranger";
import ThreeHandleSlider from "../../../../components/ui/ThreeHandleSlider";

interface ContractOptionEditProps {
  option: Record<string, any>;
  updateCurrentView: (view: string) => void;
  contract: Workspace;
  onSuccess: (data: any) => void;
}

const ContractOptionEdit = ({ option, updateCurrentView, contract, onSuccess }: ContractOptionEditProps) => {

  const updateWorkspaceAmendment = useWorkspaceStore(state => state.updateWorkspaceAmendment);
  const [saving, setSaving] = React.useState(false);
  const [selectedOptionValue, setSelectedOptionValue] = React.useState<Record<string, any>>({});

  let values = option.type === TemplateOptionType.TEXT ?
    Object.keys(option?.values)?.map(key => ({ key, ...option?.values[key] }))
    : [];

  const [rangeOptionValues, setRangeOptionsValues] = useState<Record<string, number>>({
    minValue: 0,
    maxValue: 10000,
    defaultValue: 4000,
    preApprovedMin: 3000,
    preApprovedMax: 9000
  });

  const rangerRef = React.useRef<HTMLDivElement>(null);
  const params = useParams();

  const handleSaveSubmit = async () => {
    try {
      if (option?.type === TemplateOptionType.TEXT && !selectedOptionValue?.key) return;

      setSaving(true);

      let details: Record<string, any> = {
        "contractId": contract.contractId,
        "contractVersion": contract.version,
        "source": "CLIENT",
        optionId: option.optionId
      };


      if (option?.type === TemplateOptionType.TEXT) {
        details = {
          ...details,
          "valueId": selectedOptionValue.key,
          "value": selectedOptionValue.text,
        };
      }

      if (option?.type === TemplateOptionType.RANGE) {
        details = {
          ...details,
          "valueId": "",
          "value": rangeOptionValues.defaultValue + "",
        };
      }

      if (!details.value) {
        setSaving(false);
        return;
      }

      let response = await saveContractOptionChanges(Number(params?.clientId), details);

      setSaving(false);

      if (response.success) {
        toast.success("Saved successfully.");
        onSuccess();
      } else {
        toast.error("Failed to save! Please try again later");
      }
    } catch (error) {
      toast.error("Failed to save! Please try again later");
    }
  };


  const rangerInstance = useRanger<HTMLDivElement>({
    getRangerElement: () => rangerRef.current,
    values: [rangeOptionValues.preApprovedMin, rangeOptionValues.defaultValue, rangeOptionValues.preApprovedMax],
    min: rangeOptionValues.minValue,
    max: rangeOptionValues.maxValue,
    stepSize: 1,
    onChange: (instance: Ranger<HTMLDivElement>) => {
      setRangeOptionsValues(prev => ({ ...prev, defaultValue: instance.sortedValues[1] }));
    }
  });

  const handles = rangerInstance.handles() ?? [];

  useEffect(() => {
    if (option?.type === TemplateOptionType.RANGE) {
      const selectedAmendMent = contract?.amendments?.find(amendment => amendment.optionId === option.optionId);
      setRangeOptionsValues({
        minValue: option?.values?.range?.min,
        maxValue: option?.values?.range?.max,
        defaultValue: Number(selectedAmendMent?.value ?? option?.value),
        preApprovedMin: option?.values?.preApprovedRange?.min,
        preApprovedMax: option?.values?.preApprovedRange?.max
      });
    }
  }, [option?.type]);
  
  return (
    <div className={"flex-1 w-full flex flex-col"}>
      <div className={"flex-1 w-full h-full overflow-y-auto"}>
        <h1 className={"font-[670] text-[28px] leading-[32px]"}>{option?.key} </h1>

        {
          option?.type === TemplateOptionType.TEXT && (
            <div className={"py-4 mt-6"}>

              <div className={"border border-[#00000014] rounded-[8px] p-[16px]"}>
                <p className={"flex items-center gap-1 font-[570] text-[12px] leading-[16px] text-[#ED4B4B] "}>
                  <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <g clipPath="url(#clip0_816_1581)">
                      <path fillRule="evenodd" clipRule="evenodd"
                        d="M6 12C9.31371 12 12 9.31371 12 6C12 2.68629 9.31371 0 6 0C2.68629 0 0 2.68629 0 6C0 9.31371 2.68629 12 6 12ZM6 1.99999C6.41421 1.99999 6.75 2.33578 6.75 2.74999V6.3C6.75 6.71421 6.41421 7.05 6 7.05C5.58579 7.05 5.25 6.71421 5.25 6.3V2.74999C5.25 2.33578 5.58579 1.99999 6 1.99999ZM6.90156 8.99998C6.90156 9.49703 6.49862 9.89997 6.00156 9.89997C5.50451 9.89997 5.10156 9.49703 5.10156 8.99998C5.10156 8.50292 5.50451 8.09998 6.00156 8.09998C6.49862 8.09998 6.90156 8.50292 6.90156 8.99998Z"
                        fill="#ED4B4B" />
                    </g>
                    <defs>
                      <clipPath id="clip0_816_1581">
                        <rect width="12" height="12" fill="white" />
                      </clipPath>
                    </defs>
                  </svg>
                  <span>Requires approval</span>
                </p>


                <h2 className={"font-[570] text-[20px] my-3 leading-[24px]"}>Custom amendment</h2>

                <p className={"font-[380] text-[16px] leading-[24px] text-[#121212] "}>
                  There are many variations of passages of Lorem Ipsum available, but the majority have suffered
                  alteration in some form, by injected humour,
                  or randomised words which don't look even slightly believable.
                </p>
              </div>

              <h2 className={"font-[570] text-[18px] mt-6 leading-[24px]"}>Options</h2>

              <div className={"mt-4"}>

                {
                  values?.map((value, index) => (
                    <div
                      key={index}
                      onClick={() => setSelectedOptionValue(value)}
                      className={cn("border cursor-pointer border-[#00000014] rounded-[8px] p-[16px]", {
                        "border-[#0F4CEAA3] border-[4px]": selectedOptionValue?.key === value?.key
                      })}
                    >
                      {
                        value?.needsApproval &&
                        <p className={"flex items-center gap-1 font-[570] text-[12px] leading-[16px] text-[#ED4B4B] "}>
                          <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <g clipPath="url(#clip0_816_1581)">
                              <path fillRule="evenodd" clipRule="evenodd"
                                d="M6 12C9.31371 12 12 9.31371 12 6C12 2.68629 9.31371 0 6 0C2.68629 0 0 2.68629 0 6C0 9.31371 2.68629 12 6 12ZM6 1.99999C6.41421 1.99999 6.75 2.33578 6.75 2.74999V6.3C6.75 6.71421 6.41421 7.05 6 7.05C5.58579 7.05 5.25 6.71421 5.25 6.3V2.74999C5.25 2.33578 5.58579 1.99999 6 1.99999ZM6.90156 8.99998C6.90156 9.49703 6.49862 9.89997 6.00156 9.89997C5.50451 9.89997 5.10156 9.49703 5.10156 8.99998C5.10156 8.50292 5.50451 8.09998 6.00156 8.09998C6.49862 8.09998 6.90156 8.50292 6.90156 8.99998Z"
                                fill="#ED4B4B" />
                            </g>
                            <defs>
                              <clipPath id="clip0_816_1581">
                                <rect width="12" height="12" fill="white" />
                              </clipPath>
                            </defs>
                          </svg>
                          <span>Requires approval</span>
                        </p>
                      }

                      <h2 className={"font-[570] text-[20px] my-3 leading-[24px]"}>{value?.key}</h2>

                      <p className={"font-[380] text-[16px] leading-[24px] text-[#121212] "}>
                        {value?.text}
                      </p>
                    </div>
                  ))
                }

              </div>

            </div>
          )
        }

        {
          option?.type === TemplateOptionType.RANGE && (
            <div className={"py-4 mt-6 px-4"}>
              <div className={"my-4 bg-[#F8F8F8] p-4 rounded-[8px]"}>
                <p className={"font-[380] text-[10px] text-[#717171]"}>
                  Value
                </p>
                <input
                  type="text"
                  className="focus:border-none focus:outline-none focus:ring-0 font-[570] w-full text-[20px] placeholder:font-[380] placeholder:text-[16px] bg-transparent"
                  value={rangeOptionValues.defaultValue}
                  onChange={e => {
                    try {
                      setRangeOptionsValues(prev => ({ ...prev, defaultValue: Number(e.target.value) }));
                    } catch (error) { }
                  }}
                />
              </div>

              <div className={"track"}>
                <ThreeHandleSlider
                  disableRange
                  values={{
                    minValue: rangeOptionValues?.preApprovedMin,
                    maxValue: rangeOptionValues?.preApprovedMax,
                    defaultValue: rangeOptionValues.defaultValue,
                    fromValue: rangeOptionValues?.minValue,
                    toValue: rangeOptionValues?.maxValue
                  }}
                  onChange={(values) => {
                    setRangeOptionsValues(prev => ({ ...prev, defaultValue: values.defaultValue }));
                  }}
                />
              </div>
            </div>
          )
        }

      </div>

      <div className={"w-full flex justify-between gap-3"}>
        <Button variant={"ghost"} onClick={() => updateCurrentView("home")} className={"flex-1"}>Cancel</Button>
        <Button onClick={handleSaveSubmit} loading={saving} className={"flex-1"}>Save</Button>
      </div>
    </div>
  );
};

export default ContractOptionEdit;