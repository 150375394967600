"use client";

import * as React from "react";
import { format } from "date-fns";
import { Calendar as CalendarIcon, X } from "lucide-react";

import { cn } from "../../utils";
import { Calendar } from "./calendar";
import {
	Popover,
	PopoverContent,
	PopoverTrigger,
} from "../ui/popover";

interface DatePickerProps {
	value: string | number | Date;
	onValueChange: (date: string | number | Date) => void;
	label: string;
}

export function DatePicker({ label, value, onValueChange }: DatePickerProps) {
	const [isOpen, setIsOpen] = React.useState(false);

	return (
		<Popover open={isOpen} onOpenChange={setIsOpen}>
			<PopoverTrigger asChild>
				<button
					onClick={() => setIsOpen(!isOpen)}
					className={cn(
						"w-full justify-start text-left font-normal bg-transparent !shadow-none !border-2 hover:bg-transparent ",
						"border-[#F8F8F8] text-[14px] leading-[18px] text-[#e1e1e1 ] opacity-100",
						"flex items-center p-3 w-full rounded-[12px]",
					)}
				>
					<CalendarIcon className="w-4 h-4 mr-2" />
					{value ? format(value, "PPP") : <span>{label}</span>}

					{
						value &&
						<button
							type="button"
							className="ml-auto"
							onClick={(e) => {
								e.stopPropagation();
								onValueChange("");
							}}
						>
							<X className="w-4 h-4" />
						</button>
					}
				</button>
			</PopoverTrigger>
			<PopoverContent className="w-auto p-0">
				<Calendar
					mode="single"
					captionLayout="dropdown-buttons"
					selected={value ? new Date(value) : undefined}
					onSelect={date => {
						onValueChange(date.toISOString());
						setIsOpen(false);
					}}
					fromDate={new Date(1990, 1, 1)}
					toDate={new Date(2100, 1, 1)}
					initialFocus
				/>
			</PopoverContent>
		</Popover>
	);
}
