import React from "react";
import { AppHeader } from "./layout/app-header";

const TemplateLoader = ({ message = "Loading template..." }: { message?: string; }) => {
  return (
    <div className="w-full p-4 max-w-[500px] flex flex-col h-screen">
      <AppHeader web_url={"/app/"} />
      <div className='flex items-center justify-center flex-1'>
        <div className="flex flex-col items-center">
          <svg className={"animate-spin"} width="129" height="128" viewBox="0 0 129 128" fill="none"
            xmlns="http://www.w3.org/2000/svg">
            <circle cx="64.5" cy="64" r="62.5" stroke="#EEEEEE" strokeWidth="3" />
            <path
              d="M117.897 31.5449C123.568 40.8673 126.7 51.5121 126.981 62.4206C127.262 73.329 124.682 84.121 119.498 93.723C114.314 103.325 106.706 111.402 97.4316 117.152C88.1569 122.901 77.5386 126.122 66.6328 126.494"
              stroke="#121212" strokeWidth="3" />
          </svg>

          <span className="mt-4 text-gray-700">{message}</span>
        </div>
      </div>
    </div>
  );
};

export default TemplateLoader;