import React, { useEffect, useState } from "react";
import { Loader } from "lucide-react";
import { Navigate, Route, Routes } from "react-router-dom";
import {
  AccountsPage,
  AddBillPage,
  AddContactPage,
  AddContractInformationPage,
  AddContractPage,
  AddCustomerPage, ContractPage,
  ContractParameters,
  CreateContractPage,
  CustomAmendmentsPage,
  EditContractInformationPage,
  LoginPage,
  NotFound,
  SelectBillPage,
  SelectContactPage,
  SelectCustomerPage,
  SelectTemplatePage,
  SingleTemplatePage,
  TemplatesPage,
  VerifyPage,
  WorkspacePage
} from "./pages";

import { getLoggedInUser } from "./services/auth.service";
import { useAuthStore } from "./store/auth.store";
import { AppWrapper } from "./components/app-wrapper";
import RoleGuard from "./components/auth/role-guard";
import { SessionChecker } from "./components/session-checker";

const App = () => {

  const [loading, setLoading] = useState(true)
  const setAuthUser = useAuthStore(state=>state.setAuthUser)

  useEffect(() => {
    let token = localStorage.getItem("token");
    const fetchUser = async () => {
      const result = await getLoggedInUser();

      if (result.success && result?.data?.user) {
        setAuthUser(result?.data?.user);
      } else{
        localStorage.clear()
      }

      setLoading(false);
    };

    if (token) fetchUser().then()
    else setLoading(false)

  }, []);

  if (loading) {
    return (
      <div className="flex items-center justify-center min-h-screen">
        <Loader className='w-8 h-8 animate-spin' />
      </div>
    );
  }

  return (
    <div className={"flex justify-center"}>

        <Routes>
          <Route index element={<LoginPage />} />
          <Route path={"/auth/login"} element={<LoginPage />} />
          <Route path={"/auth/verify"} element={<VerifyPage />} />
          <Route path={"/app"} element={<AppWrapper />}>
            <Route index element={<SessionChecker><AccountsPage /></SessionChecker>} />
            <Route path={"accounts"} element={<SessionChecker><AccountsPage /></SessionChecker>} />
            <Route path={"workspaces"} element={<Navigate to={"/app/accounts"} />} />
            <Route path={"accounts/:clientId"} element={<SessionChecker><WorkspacePage /></SessionChecker>} />
            <Route path={"accounts/:clientId/workspaces"} element={<SessionChecker><WorkspacePage /></SessionChecker>} />
            <Route path={"accounts/:clientId/templates"} element={<SessionChecker><TemplatesPage /></SessionChecker>} />
            <Route
              path={"accounts/:clientId/templates/:templateId"}
              element={
                <RoleGuard showFallback roles={["admin"]}>
                  <SingleTemplatePage />
                </RoleGuard>
              }
            />
            <Route path={"accounts/:clientId/workspaces/:contractId"} element={<ContractPage />} />

            <Route path={"accounts/:clientId/select-customer"} element={<SelectCustomerPage />} />
            <Route path={"accounts/:clientId/new-customer"} element={<AddCustomerPage />} />
            <Route path={"accounts/:clientId/select-contact"} element={<SelectContactPage />} />
            <Route path={"accounts/:clientId/new-contact"} element={<AddContactPage />} />
            <Route path={"accounts/:clientId/select-billing"} element={<SelectBillPage />} />
            <Route path={"accounts/:clientId/new-billing"} element={<AddBillPage />} />
            <Route path={"accounts/:clientId/select-template"} element={<SelectTemplatePage />} />
            <Route path={"accounts/:clientId/contract-parameters"} element={<ContractParameters />} />
            <Route path={"accounts/:clientId/create-contract"} element={<CreateContractPage />} />
          </Route>

          <Route path={"/contract"} element={<AddContractPage />} />
          <Route path={"/info"} element={<AddContractInformationPage />} />
          <Route path={"/edit"} element={<EditContractInformationPage />} />
          <Route path={"/custom"} element={<CustomAmendmentsPage />} />
          <Route path={"*"} element={<NotFound />} />
        </Routes>
    
    </div>
  );
};

export default App;
