import { create } from 'zustand';
import { fetchClientCustomers } from "../services/customers.service";

type CustomerStore = {
	customers: Customer[];
	customer: Customer | null;
	loading: boolean;

	loadClientCustomers: (clientId: number) => Promise<void>;
};

export const useCustomerStore = create<CustomerStore>((set) => ({
	customers: [],
	customer: null,
	loading: true,

	loadClientCustomers: async (clientId: number) => {
		set({ loading: true, customers: [] });
		const response = await fetchClientCustomers(clientId);
		if (response.success) set({ customers: response?.data?.customers ?? [] });
		set({ loading: false });
	},
}));