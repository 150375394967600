
import React, { useEffect, useState } from "react";
import { AUTO_REPLACE_OPTIONS } from "../../../../../../constants";
import { fetchContractAutoCompleteKeys } from "../../../../../../services/workspace.service";
import { useParams } from "react-router-dom";

interface Props {
	values: Record<string, string>,
	errors: Record<string, string>,
	handleAutoReplaceInputChange: (key: string, value: string) => void;
}
const AutoReplaceOption = ({ values, errors, handleAutoReplaceInputChange }: Props) => {

	const [keys, setKeys] = useState<Record<string, string>[]>([]);

	const params = useParams();

	useEffect(() => {
		const fetchKeys = async () => {
			try {
				let response = await fetchContractAutoCompleteKeys(Number(params.clientId));
				if (response.success) {
					let keys = Object.keys(response.data?.keys)?.map(key => ({ value: key, label: response.data?.keys[key] }));
					setKeys(keys);
				}
			} catch (error) {
				console.log("Error fetching keys", error);
			}
		};

		if (params.clientId) {
			fetchKeys();
		}
	}, []);

	return (
		<>
			<div className={"flex-1 overflow-y-auto scrollbar-sm scroll-hidden space-y-8 mt-6"}>
				<div className={"gap-4"}>
					<div className="w-full">
						<select
							className="input w-full border-2 border-[#F8F8F8] focus:ring-0 focus:border-[#e1e1e1] focus:outline-none-primary  text-[14px] font-bold leading-[18px] placeholder:text-[#e1e1e1] placeholder:opacity-100 "
							value={values.orgNo}
							onChange={e => handleAutoReplaceInputChange("orgNo", e.target.value)}
						>
							<option value="">Select</option>
							{
								keys.map(opt => (
									<option key={opt.label} value={opt.value}>{opt.label}</option>
								))
							}
						</select>
						{errors.orgNo && <p className={"text-red-400 mt-1.5 text-sm"}>{errors.orgNo}</p>}
					</div>
				</div>
			</div>
		</>
	);
};

export default AutoReplaceOption;