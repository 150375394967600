import { AutoReplaceTemplateOption, RangeTemplateOption, RangeTemplateValue, TemplateOption, TemplateOptionType, TextTemplateOption, TextTemplateValues } from "./template-options-types";

export abstract class TemplateTools {
    /**
     * Retrieves the settings for a tag from the document settings.
     * 
     * @param tagName {string} - The name of the tag to retrieve settings for.
     * @returns {Promise<object | null>} - A promise that resolves with the settings object for the tag or null.
     */
    static async getOptionForTag(tagName: string): Promise<TemplateOption | null> {
        return await Word.run(async (context) => {
            // Retrieve and load the tag's data from the document settings.
            let tagSettings = context.document.settings.getItemOrNullObject(tagName);
            context.load(tagSettings, 'value');
            await context.sync();

            if (tagSettings.isNullObject) {
                console.error(`Tag '${tagName}' not found.`);
                return null;
            }

            // Parse the tag data and check if the option exists.
            if (tagSettings.value) {
                return JSON.parse(tagSettings.value) || null;
            }

            return null;
        });
    }

    /**
     * Saves the option for a tag in the document settings.
     * 
     * @param tagName {string} - The name of the tag to update settings for.
     * @param templateOption {TemplateOption} - The new settings object for the tag.
     */
    static async saveOptionForTag(tagName: string, templateOption: TemplateOption) {
        await Word.run(async (context) => {
            context.document.settings.add(tagName, JSON.stringify(templateOption));
            await context.sync();
        });
    }

    /**
     * Retrieves all tags and their options from the document settings.
     * 
     * @returns {Promise<Record<string, TemplateOption>>} - A promise that resolves with an object containing all tags and their settings.
     */
    static async getAllTagsWithOptions(): Promise<Record<string, TemplateOption>> {
        const tags = await Word.run(async (context) => {
            const settings = context.document.settings;
            context.load(settings);
            await context.sync();
            return settings.items;
        });

        return tags.reduce((acc, tag) => {
            acc[tag.key] = JSON.parse(tag.value);
            return acc;
        }, {});
    }

    /** 
     * Creates or updates a tag with the specified option settings
     * 
     * @param {string} tagName - A unique identifier for the option (e.g., 'DEFAULT', 'PRICING', 'COMPANY_NAME')
     * @param {TemplateOptionType} type - The type of the option (e.g., 'TEXT', 'RANGE', 'AUTO_REPLACE')
     * @param {string | number} value - A default value
     * @param {TemplateOptionValue} valueSettings - A configuration object for the value
     *
     * Usage examples:
     *   upsertOption(‘Perks’, 'TEXT', defaultValue, {'valueName': { text: 'value 1', needsApproval: false }});
     *   upsertOption('Numbers', 'RANGE', 120, { range: { min: 100, max: 200 }, preApprovedRange: { min: 100, max: 180 } });
     *   upsertOption(‘Org nr’, 'AUTO_REPLACE', 'company.name');
     */
    /*
        {
            "PRIORITY": {
                "type": "TEXT",
                "value": "DEFAULT",
                "values": {
                    "DEFAULT": {
                        "text": "better",
                        "needsApproval": false
                    }
                },
            },

            "LENGTH": {
                "type": "RANGE",
                "values": {
                    "range": {
                        "min": 1,
                        "max": 10
                    },
                    "preApprovedRange": {
                        "min": 2,
                        "max": 8
                    }
                },
                value: 5,
            },

            "COMPANY_NAME": {
                "type": "AUTO_REPLACE",
                value: "companyName"
            },

            "NOTHING": null
        }
    */
    static async upsertOption(optionId: string, type: TemplateOptionType, value: string | number, valueSettings?: RangeTemplateValue | TextTemplateValues) {
        let tagSettings = await this.getOptionForTag(optionId);
        if (!tagSettings) {
            switch (type) {
                case TemplateOptionType.TEXT:
                    await this.saveOptionForTag(optionId, { type: TemplateOptionType.TEXT, value: value as string, values: valueSettings as TextTemplateValues });
                    await this.createTagFromSelection(optionId);
                return;
                case TemplateOptionType.RANGE:
                    await this.saveOptionForTag(optionId, { type: TemplateOptionType.RANGE, value: value as number, values: valueSettings as RangeTemplateValue });
                    await this.createTagFromSelection(optionId);
                return;
                case TemplateOptionType.AUTO_REPLACE:
                    await this.saveOptionForTag(optionId, { type: TemplateOptionType.AUTO_REPLACE, value: value as string });
                    await this.createTagFromSelection(optionId);
                return;
            }
        }

        switch (tagSettings.type) {
            case TemplateOptionType.TEXT:
                let textSettings = tagSettings as TextTemplateOption;
                if (value !== null) {
                    textSettings.value = value as string;
                }
                for (const key in valueSettings) {
                    textSettings.values[key] = valueSettings[key];
                }

                await this.saveOptionForTag(optionId, textSettings);
            return;
            case TemplateOptionType.RANGE:
                let rangeSettings = tagSettings as RangeTemplateOption;
                if (value !== null) {
                    rangeSettings.value = value as number;
                }
                rangeSettings.values = valueSettings as RangeTemplateValue;
                await this.saveOptionForTag(optionId, rangeSettings);
            return;
            case TemplateOptionType.AUTO_REPLACE:
                let autoReplaceSettings = tagSettings as AutoReplaceTemplateOption;
                if (value !== null) {
                    autoReplaceSettings.value = value as string;
                }
                await this.saveOptionForTag(optionId, autoReplaceSettings);
            return;
        }
    }

    /**
     * Removes a text value for a tag.
     * 
     * @param {string} optionId - The name of the tag to remove the option from.
     * @param {string} valueId - The unique identifier of the option to remove.
     */
    static async removeTextValueForOption(optionId: string, valueId: string) {
        let tagSettings = await this.getOptionForTag(optionId);
        if (!tagSettings || tagSettings.type !== TemplateOptionType.TEXT) {
            return;
        }

        let textSettings = tagSettings as TextTemplateOption;
        if (valueId in textSettings.values) {
            delete textSettings.values[valueId];
        }

        if (textSettings.value === valueId) {
            textSettings.value = null;
        }
        
        await this.saveOptionForTag(optionId, textSettings);
    }

    /**
     * Removes a tag from the document and deletes all associated content controls.
     * 
     * @param tagName {string} - The name of the tag to remove.
     */
    static async removeTag(tagName: string) {
        await this.removeHighlightColorForTag(tagName);

        await Word.run(async (context) => {
            context.document.settings.add(tagName, null);
            await context.sync();

            // Find and remove the content control(s) associated with the tag.
            const controlsWithTag = context.document.contentControls.getByTag(tagName);
            context.load(controlsWithTag, "items");
            await context.sync();

            // Delete all content controls with the given tag.
            controlsWithTag.items.forEach((control) => control.delete(true));
            await context.sync();
        }).catch(console.error);
    }

    /**
     * Creates a new tag or updates an existing one based on the current selection.
     *
     * @param {string} tagName - The name of the tag to create or update.
     * 
     */
    static async createTagFromSelection(tagName: string) {
        const text = await Word.run(async (context) => {
            // Get the current selection.
            const selection = context.document.getSelection();
            if (!selection) {
                return;
            }

            // Find an existing content control by tag or create a new one.
            let controlsWithTag = context.document.contentControls.getByTag(tagName);
            context.load(controlsWithTag, "items");
            await context.sync();

            // If no content control with the specified tag exists, create a new one.
            if (controlsWithTag.items.length == 0) {
                let contentControl = selection.insertContentControl();
                contentControl.tag = tagName;
                await context.sync();
            }

            // Highlight the tag
            await this.highlightTag(tagName);
        });
    }

    /**
     * Retrieve the content of a tag.
     * 
     * @param tagName - The name of the tag to retrieve the content for.
     * @returns {Promise<string>} - A promise that resolves with the content of the tag as string
     */
    static async getContentForTag(tagName: string) : Promise<string> {
        return await Word.run(async (context) => {
            let controlsWithTag = context.document.contentControls.getByTag(tagName);
            context.load(controlsWithTag, "items");
            await context.sync();

            if (controlsWithTag.items.length > 0) {
                const contentControl = controlsWithTag.items[0];
                contentControl.load('text');
                await context.sync();
                return contentControl.text;
            }

            return '';
        });
    }

    /**
     * Changes the value for a tag to the specified option.
     * 
     * @param tagName {string} - The name of the tag to switch the option for.
     * @param value {string | number} - Either the valueId of values in Text or number of Range or value of AutoReplace
     */
    static async changeOptionValueForTag(tagName: string, value: string | number) {
        let tagSettings = await this.getOptionForTag(tagName);
        if (!tagSettings) {
            return;
        }

        let content = null;

        switch (tagSettings.type) {
            case TemplateOptionType.TEXT:
                let textSettings = tagSettings as TextTemplateOption;
                textSettings.value = value as string;
                content = textSettings.values[value];
                break;
            case TemplateOptionType.RANGE:
                let rangeSettings = tagSettings as RangeTemplateOption;
                rangeSettings.value = value as number;
                content = rangeSettings.value.toString();
                break;
            case TemplateOptionType.AUTO_REPLACE:
                let autoReplaceSettings = tagSettings as AutoReplaceTemplateOption;
                autoReplaceSettings.value = value as string;
                break;
        }

        // Update the tag settings
        await this.saveOptionForTag(tagName, tagSettings);

        // Update the content of tag
        if (content !== null)
            await this.updateContentForTag(tagName, content);
    }

    static async updateContentForTag(tagName: string, content: string) {
        // Update the content of the tag
        await Word.run(async (context) => {
            // Find the content control by tag and update its text with the selected option's text.
            let controlsWithTag = context.document.contentControls.getByTag(tagName);
            context.load(controlsWithTag, 'items');
            await context.sync();

            if (controlsWithTag.items.length > 0) {
                const control = controlsWithTag.items[0]; // Assuming the first one is the one we want.
                control.insertText(content, 'Replace');
                await context.sync();
                await this.highlightTag(tagName);
            } else {
                console.error(`No content control found with tag '${tagName}'.`);
            }
        }).catch(console.error);
    }

    /**
     * Removes the highlight color from all content controls with the specified tag.
     * 
     * @param tagName {string} - The name of the tag to remove the highlight color from.
     */
    static async removeHighlightColorForTag(tagName: string) {
        await Word.run(async (context) => {
            // Get all content controls with the specified tag.
            const controlsWithTag = context.document.contentControls.getByTag(tagName);
            context.load(controlsWithTag, "items");
            await context.sync();

            // Iterate over each content control and remove the highlight color.
            controlsWithTag.items.forEach((control) => {
                control.font.highlightColor = null;
                // TODO XXX control.color investigate for colors instead of font.highlightColor?
            });

            await context.sync();
        }).catch(console.error);
    }

    /**
     * Removes all highlight colors from the document.
     */
    static async removeAllHighlightColors() {
        await Word.run(async (context) => {
            // Get all content controls in the document.
            const contentControls = context.document.contentControls;
            context.load(contentControls, "items");
            await context.sync();

            // Iterate over each content control.
            contentControls.items.forEach((control) => {
                // Remove the highlight color.
                control.font.highlightColor = null;
                // TODO XXX control.color investigate for colors instead of font.highlightColor?
            });

            await context.sync();
        }).catch(console.error);
    }

    /**
     * Highlights all tags based on their settings.
     */
    static async highlightAllTags() {
        // Get all tags settings
        const tagsSettings = await this.getAllTagsWithOptions();

        await Word.run(async (context) => {
            // Get all content controls in the document.
            const contentControls = context.document.contentControls;
            context.load(contentControls, "items");
            await context.sync();

            // Iterate over each content control.
            contentControls.items.forEach((control) => {
                if (control.tag in tagsSettings) {
                    // TODO XXX control.color investigate for colors instead of font.highlightColor?
                    const settingsForTag = tagsSettings[control.tag];
                    switch (settingsForTag.type) {
                        case TemplateOptionType.TEXT:
                            control.font.highlightColor = settingsForTag.values[settingsForTag.value].needsApproval ? "red" : "yellow";
                            break;
                        case TemplateOptionType.RANGE:
                            const rangeOption = settingsForTag.values as RangeTemplateValue;
                            if (settingsForTag.value < rangeOption.preApprovedRange.min || settingsForTag.value > rangeOption.preApprovedRange.max) {
                                control.font.highlightColor = "red";
                            } else {
                                control.font.highlightColor = "yellow";
                            }
                            break;
                    }
                }
            });

            await context.sync();
        }).catch(console.error);
    }

    /**
     * Highlights a tag based on its settings.
     */
    static async highlightTag(tagName: string) {
        const tagSettings = await this.getOptionForTag(tagName);
        if (!tagSettings) {
            console.error(`Tag '${tagName}' not found.`);
            return;
        }

        await Word.run(async (context) => {
            // Get all content controls with the specified tag.
            const controlsWithTag = context.document.contentControls.getByTag(tagName);
            context.load(controlsWithTag, "items");
            await context.sync();

            // Iterate over each content control and set the highlight color to yellow.
            controlsWithTag.items.forEach((control) => {
                // TODO XXX control.color investigate for colors instead of font.highlightColor?
                const settingsForTag = tagSettings;
                switch (settingsForTag.type) {
                    case TemplateOptionType.TEXT:
                        control.font.highlightColor = settingsForTag.values[settingsForTag.value].needsApproval ? "red" : "yellow";
                        break;
                    case TemplateOptionType.RANGE:
                        const rangeOption = settingsForTag.values as RangeTemplateValue;
                        if (settingsForTag.value < rangeOption.preApprovedRange.min || settingsForTag.value > rangeOption.preApprovedRange.max) {
                            control.font.highlightColor = "red";
                        } else {
                            control.font.highlightColor = "yellow";
                        }
                        break;
                }
            });

            await context.sync();
        }).catch(console.error);
    }

    /**
     * Scrolls to the first content control with the specified tag.
     * 
     * @param tagName {string} - The name of the tag to scroll to.
     */
    static async scrollToTag(tagName: string) {
        await Word.run(async (context) => {
            // Attempt to find content controls with the specified tag.
            const controlsWithTag = context.document.contentControls.getByTag(tagName);
            context.load(controlsWithTag, "items");
            await context.sync();

            // If there are any content controls with the specified tag, select the first one.
            if (controlsWithTag.items.length > 0) {
                const controlToSelect = controlsWithTag.items[0];
                controlToSelect.select();
                await context.sync();
            } else {
                console.log(`No content controls found with tag: ${tagName}`);
            }
        }).catch(console.error);
    }

    /**
     * Updates the content of a tag based on its default value stored in the document settings.
     * 
     * @param {string} tagName - The name of the tag to update the content for.
     */
    static async updateContentFromDefaultValue(tagName: string) {
        // Retrieve the settings for the tag
        const tagSettings = await this.getOptionForTag(tagName);
        if (!tagSettings) {
            console.error(`Tag '${tagName}' not found.`);
            return;
        }

        let defaultValue = null;

        // Determine the default value based on the type of the tag
        switch (tagSettings.type) {
            case TemplateOptionType.TEXT:
                const textSettings = tagSettings as TextTemplateOption;
                if (textSettings.values && textSettings.value && textSettings.values[textSettings.value]) {
                    defaultValue = textSettings.values[textSettings.value].text;
                }
                break;
            case TemplateOptionType.RANGE:
                const rangeSettings = tagSettings as RangeTemplateOption;
                if (rangeSettings.value !== undefined && rangeSettings.value !== null) {
                    defaultValue = rangeSettings.value.toString();
                }
                break;
        }

        // Update the content of the tag
        if (defaultValue !== null)
            await this.updateContentForTag(tagName, defaultValue);
    }

    /**
     * Updates the content of multiple tags based on the values provided.
     * @param values 
     */
    static async updateContentOfTags(values: Record<string, string>) {
        for (const tagId in values) {
            await this.updateContentForTag(tagId, values[tagId]);
        }
    }
}