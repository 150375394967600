import { ArrowLeft } from "lucide-react";
import React, { ElementRef, useEffect, useRef } from "react";
import { Link } from "react-router-dom";

function AddContractInformationPage() {
  const emailRef = useRef<ElementRef<"input">>();

  useEffect(() => {
    emailRef.current?.focus();
  }, []);

  return (
    <div className="w-full flex flex-col my-12 px-12  agency-shadow rounded-xl  max-w-[500px] ">
      <div className="flex items-center justify-between w-full mx-auto my-4">
        <ArrowLeft className="cursor-pointer" />
        <div className="flex items-center justify-between gap-4">
          <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clipPath="url(#clip0_516_5203)">
              <path
                d="M14.25 8.25H17.25V11.25"
                stroke="#A4A4A4"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M9.75 15.75H6.75V12.75"
                stroke="#A4A4A4"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M20.25 4.5H3.75C3.33579 4.5 3 4.83579 3 5.25V18.75C3 19.1642 3.33579 19.5 3.75 19.5H20.25C20.6642 19.5 21 19.1642 21 18.75V5.25C21 4.83579 20.6642 4.5 20.25 4.5Z"
                stroke="#A4A4A4"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </g>
            <defs>
              <clipPath id="clip0_516_5203">
                <rect width="24" height="24" fill="white" />
              </clipPath>
            </defs>
          </svg>
          <p className="h-12 w-12 rounded-full bg-[#f3df30] flex items-center justify-center ">A1</p>
        </div>
      </div>

      <h1 className={"font-[700] leading-[20px] text-[36px] text-primary mb-3 pt-2"}>Contract Info</h1>

      <div className="flex items-center flex-col justify-between w-full max-w-[800px] px-4  mx-auto  ">
        <div className="flex items-center justify-between w-full max-w-[800px] gap-4 px-2 mx-auto ">
          <div className="flex items-center justify-between gap-4 my-3">
            <div className="flex flex-col">
              <h4 className="font-bold text-[22px] py-1">Customer</h4>
              <h4 className="font-bold">Hitta.se</h4>
              <p className="font-normal ">
                Fyrspannasvagen 100, <br />
                Malmo 111111
              </p>
              <p className="font-normal ">email@email.com</p>
            </div>
          </div>
          <p className="font-bold underline rounded-full cursor-pointer">Change</p>
        </div>

        <hr className="text-[#b3a8a8] h-1 w-full" />

        <div className="flex items-center justify-between w-full max-w-[800px] gap-4 px-2 mx-auto ">
          <div className="flex items-center justify-between gap-4 my-3">
            <div className="flex flex-col">
              <h4 className="font-bold text-[22px] py-1">Billing Unit</h4>
              <h4 className="font-bold">Unit 1</h4>
              <p className="font-normal ">
                Fyrspannasvagen 100, <br />
                Malmo 111111
              </p>
              <p className="font-normal ">email@email.com</p>
            </div>
          </div>
          <p className="font-bold underline rounded-full cursor-pointer">Change</p>
        </div>

        <hr className="text-[#b3a8a8] h-1 w-full" />

        <div className="flex items-center justify-between w-full max-w-[800px] gap-4 px-2 mx-auto ">
          <div className="flex items-center justify-between gap-4 my-3">
            <div className="flex flex-col">
              <h4 className="font-bold text-[22px] py-1">Customer contact</h4>
              <h4 className="font-bold text-[18px]">Name</h4>
              <h4 className="font-normal">CEO</h4>
              <p className="font-normal ">+46791002525</p>
              <p className="font-normal ">zakay@danial.se</p>
            </div>
          </div>
          <p className="font-bold underline rounded-full cursor-pointer">Change</p>
        </div>
      </div>

      <div className="flex items-center w-full gap-4 my-10">
        <Link
          to="/info/edit"
          type={"button"}
          className={"btn text-black bg-[#F8F8F8] mt-3 flex items-center  rounded-[12px] flex-1  "}
        >
          Edit
        </Link>

        <button
          type={"button"}
          className={"btn text-white  bg-black mt-3 flex items-center  rounded-[12px] h-[44px] flex-1 "}
        >
          Done
        </button>
      </div>
    </div>
  );
}

export default AddContractInformationPage;