import { request } from "../utils/axios-utils";

export const requestLogin = async (email: string) => {
	try {
		const response = await request({
			url: `auth/request?email=${email}`
		});
		if (response.data.status === false) {
			return { success: false, error: response.data.errors };
		}
		return { success: true, data: response.data };
	} catch (error) {
		return { success: false, error };
	}
};

export const verifyLogin = async (email: string, code: string) => {
	try {
		const response = await request({
			url: `auth/login?email=${email}&code=${code}`
		});

		if (response.data.status === false) {
			return { success: false, error: response.data.errors };
		}

		return { success: true, data: response.data };
	} catch (error) {
		return { success: false, error };
	}
};

export const loginWithHash = async (hash: string) => {
	try {
		const response = await request({
			url: `auth/login/${hash}`
		});

		if (response.data.status === false) {
			return { success: false, error: response.data.errors };
		}

		return { success: true, data: response.data };
	} catch (error) {
		return { success: false, error };
	}
};

export const getLoggedInUser = async () => {
	try {
		const response = await request({
			url: `auth/user`
		});

		if (response.data.status === false) {
			return { success: false, error: response.data.errors };
		}

		return { success: true, data: response.data };
	} catch (error) {
		return { success: false, error };
	}
 };