import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { z } from "zod";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";

import { AppLogo } from "../../../components/layout/AppLogo";
import { requestLogin } from "../../../services/auth.service";
import { useAuthStore } from "../../../store/auth.store";
import Button from "../../../components/ui/button";

const formSchema = z.object({
  email: z.string(),
});
interface FormSchemaType extends z.infer<typeof formSchema> { }

function LoginPage(){

  const setLoginEmail = useAuthStore(state=>state.setLoginEmail)
  const authUser = useAuthStore(state=>state.authUser)

  const form = useForm<z.infer<typeof formSchema>>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      email: "",
    },
  });

  const navigate = useNavigate()

  async function onSubmit(values: FormSchemaType) {
    try {

      const result = await requestLogin(values.email);
      if (!result.success) {
        // show error
        if (typeof result.error === "object" && result.error?.length > 0) {
          let error = result.error[0];
          form.setError(error?.path[0], {
            type: "server",
            message: error.message,
          });
        }
        return;
      }

      setLoginEmail(values.email);
      localStorage.setItem("login_email", values.email)
      navigate("/auth/verify");
    } catch (error) {
      console.log("Error =>", error)
    }
  }

  const { isSubmitting, errors } = form.formState;

  useEffect(() => {
    localStorage.removeItem("login_email")
    form.setFocus("email");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(()=>{
    if(authUser?.userId){
      navigate("/app")
    }
    console.log(authUser);
  },[authUser?.userId])

  return (
    <div className={"w-full flex flex-col min-h-screen justify-center items-center max-w-[400px] mx-auto p-5"}>
      <AppLogo />
      <h1 className={"font-[670] leading-[40px] text-[36px] text-primary mt-12 "}>Login</h1>

      <form className={"mt-8 w-full"} onSubmit={form.handleSubmit(onSubmit)} noValidate >
        <input
          type="email"
          {...form.register("email")}
          placeholder="email@email.exmple"
          className="input w-full bg-[#F8F8F8] focus:ring-0 focus:border-none focus:outline-none
                      text-primary font-[570] text-[14px] leading-[18px] placeholder:text-primary
                      placeholder:opacity-100 placeholder:font-[570]
                    "
        />
        {
          errors?.email?.message && <p className={"text-red-500 text-sm mt-1"}>{ errors?.email?.message}</p>
        }

        <Button loading={isSubmitting} className={"w-full mt-5"}>Log In</Button>

        <p className={"w-full text-center text-[12px] mt-4 px-2"}>
          by clicking on this button you accepting our <a className={"underline"} href={"/terms-of-service"} rel={"noreferrer"}>Terms of Service</a>
        </p>

      </form>
    </div>
  )
}

export default LoginPage