import React from "react"
import Button from "../../components/ui/button";
import { AppLogo } from "../../components/layout/AppLogo";
import { Link } from "react-router-dom";

const NotFound = ()=>{
  return (
    <div className={"w-full flex flex-col min-h-screen justify-center items-center max-w-[400px] mx-auto p-5"}>
      <AppLogo />
      <h1 className={"font-[670] leading-[40px] text-[36px] text-primary mt-6"}>404</h1>

      <p className="text-gray-600 text-center text-md my-4">Oops! The page you are looking for could not be found.</p>
      <Link to={"/app"}>
        <Button className={"w-max"}>Go back to Home</Button>
      </Link>
    </div>
  )
}

export default NotFound