import React, { ReactNode, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import { useAuthStore } from "../../store/auth.store";
import { Loader } from "lucide-react";

interface AuthGuardProps{
  children: ReactNode
}
export const AuthGuard = ({children}: AuthGuardProps)=>{
  const [loading, setLoading] = useState(true)

  const authUser = useAuthStore(state=>state.authUser)
  const navigate = useNavigate()

  const next = window?.location?.pathname+window.location.search
  useEffect(() => {
    if(!authUser?.userId){
        navigate(`/auth/login?redirect_to=${decodeURIComponent(next)}`);
        return;
    }

    setLoading(false)
  }, []);

  if (loading) {
    return (
      <div className="min-h-screen flex items-center justify-center">
        <Loader className='h-8 w-8 animate-spin' />
      </div>
    );
  }

  return <>{children}</>;
}