import { apiCall } from "./helpers";

export const addCustomer = async (clientId: number | string, formData: FormData) => {
	return await apiCall({
		url: `customers/create?clientId=${clientId}`,
		method: "POST",
		headers: { 'Content-Type': 'multipart/form-data' },
		data: formData,
	});
};

export const editCustomer = async (clientId: number | string, formData: FormData) => {
	return await apiCall({
		url: `customers/update?clientId=${clientId}`,
		method: "PATCH",
		headers: { 'Content-Type': 'multipart/form-data' },
		data: formData,
	});
};

export const addCustomerBillingInfo = async (clientId: number | string, formData: any) => {
	return await apiCall({
		url: `customers/billings/add?clientId=${clientId}`,
		method: "POST",
		data: formData,
	});
};

export const editCustomerBillingInfo = async (clientId: number | string, formData: any) => {
	return await apiCall({
		url: `customers/billings/update?clientId=${clientId}`,
		method: "PATCH",
		data: formData,
	});
};

export const addCustomerContact = async (clientId: number | string, formData: any) => {
	return await apiCall({
		url: `customers/contacts/add?clientId=${clientId}`,
		method: "POST",
		data: formData,
	});
};

export const editCustomerContact = async (clientId: number | string, formData: any) => {
	return await apiCall({
		url: `customers/contacts/update?clientId=${clientId}`,
		method: "PATCH",
		data: formData,
	});
};

export const deleteCustomer = async (customerId: number | string) => {
	return await apiCall({
		url: `customers/delete?customerId=${customerId}`,
		method: "DELETE",
	});
};

export const removeCustomerBilling = async (clientId: number | string, billingId: number | string) => {
	return await apiCall({
		url: `customers/billings/remove?clientId=${clientId}`,
		method: "DELETE",
		data: {
			billingId
		}
	});
};

export const removeCustomerContact = async (clientId: number | string, contactId: number | string) => {
	return await apiCall({
		url: `customers/contacts/remove?clientId=${clientId}`,
		method: "DELETE",
		data: {
			contactId
		}
	});
};


export const fetchClientCustomers = async (clientId: number) => {
	return await apiCall({
		url: `customers/list?clientId=${clientId}`
	});
};

export const fetchClientCustomer = async (clientId: string, customerId: string) => {
	return await apiCall({
		url: `customers/get?clientId=${clientId}&customerId=${customerId}`
	});
};