import { apiCall } from "./helpers";
import { BASE_API_URL } from "../constants";
import { loadJwtToken } from "../utils/local-storage";

export const fetchTemplateDocuments = async (clientId: number) => {
	return await apiCall({
		url: `templates/list?clientId=${clientId}`
	});
};

export const fetchTemplateDocument = async (clientId: string, templateId: string) => {
	return await apiCall({
		url: `templates/get?clientId=${clientId}&templateId=${templateId}`
	});
};

export const fetchStorageFile = async (filePath: string) => {
	return await apiCall({
		url: `storage/file?filePath=${filePath}`
	});
};

export const fetchStorageFile2 = async (filePath: string) => {
	try {
		let token = await loadJwtToken();
		const response = await fetch(`${BASE_API_URL}storage/file?filePath=${filePath}`, {
			headers: {
				"Authorization": `Bearer ${token}`
			}
		})

		return {success: true, data: await response.blob()}
	} catch (error) {
		return {success: false, data: null}
	}
}

export const saveDocumentToServer = async (clientId: number | string, formData: FormData) => {
	return await apiCall({
		url: `templates/update?clientId=${clientId}`,
		method: "PATCH",
		headers: { 'Content-Type': 'multipart/form-data' },
		data: formData,
	});
};