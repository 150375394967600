import axios from "axios"
import { BASE_API_URL } from '../constants';
import { loadJwtToken } from "./local-storage";

const client = axios.create({
  baseURL: BASE_API_URL,
  headers: { 'Content-Type': 'application/json' },
});

export const request = async ({ ...options }) => {
  if (typeof window !== 'undefined') {
    let token = await loadJwtToken();
    if (token) client.defaults.headers.common.Authorization = `Bearer ${token}`;
  }

  const onSuccess = (response: any) => response;
  const onError = (error: any) => {
    throw error;
  };

  return client({ ...options })
    .then(onSuccess)
    .catch(onError);
};


export const requestFetch = async ({url, headers={}, body={} }) => {
  return fetch(`${BASE_API_URL}${url}`, {
    method: 'GET',
    headers: headers,
    body: JSON.stringify(body)
  })
}