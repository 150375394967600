import React, { ReactNode } from 'react';
import Button from '../ui/button';
import { useNavigate } from "react-router-dom";
import { useAuthStore } from "../../store/auth.store";
import { ConfirmModal } from "../confirm-modal";
import { AppHeader } from "../layout/app-header";

type GuardProps = {
  children: ReactNode;
  roles?: string[];
  showFallback?: boolean;
}
const RoleGuard = ({ children, roles = [], showFallback = false }:GuardProps) => {

  const authUser = useAuthStore(state=>state.authUser);
  const setAuthUser = useAuthStore(state=>state.setAuthUser);
  const navigate = useNavigate();

  if(!authUser?.userId) return <div />;

  const onlyAdmin = roles?.map(role => role?.toLowerCase()).includes('admin');

  if (roles?.length === 0 || (onlyAdmin && authUser?.admin))
    return <>{children}</>;

  if (!showFallback) return <div />;

  const logOut = () => {
    setAuthUser(null);
    localStorage.clear();
    navigate("/auth/login");
  };

  return (
    <div className="w-full flex flex-col p-4 justify-center max-w-[500px] h-screen">
      <AppHeader
        show_back={false}
        back_url={`#`}
        web_url={`#`}
      />

      <div className={"flex-1 flex items-center justify-center flex-col"}>
        <h1 className='text-center text-xl text-gray-500'>
          You are not authorized to access this page.
        </h1>

        <div className='mt-10 flex w-full max-w-[320px] items-center gap-x-3'>
          <ConfirmModal message="" onConfirm={logOut}>
            <Button variant={"ghost"} >Log Out</Button>
          </ConfirmModal>
          <Button className={"flex-1"}>Go to Dashboard </Button>
        </div>
      </div>

    </div>
  );
};

export default RoleGuard;
