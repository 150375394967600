import React, { useEffect } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { ChevronRight, Plus } from "lucide-react";

import { AppHeader } from "../../../../components/layout/app-header";
import { useWorkspaceStore } from "../../../../store/workspace.store";
import Button from "../../../../components/ui/button";

function SelectContactPage() {

  const selectedCustomer = useWorkspaceStore((state) => state.selectedCustomer);
  const setSelectedContact = useWorkspaceStore((state) => state.setSelectedContact);

  const params = useParams()
  const navigate = useNavigate()

  const handleSelectContact = (contact: CustomerContact) =>{
    setSelectedContact(contact);
    navigate(`/app/accounts/${params.clientId}/select-billing`)
  }

  useEffect(() => {
    if (!selectedCustomer?.customerId)
      navigate(`/app/accounts/${params.clientId}/select-customer`);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedCustomer?.customerId]);

  if (!selectedCustomer?.customerId) return null;

  return (
    <div className="w-full flex flex-col p-4 justify-center max-w-[500px] h-screen">
      <AppHeader
        show_back
        back_url={`/app/accounts/${params?.clientId}/select-customer`}
        web_url={`/app/clients/${params?.clientId}/select-customer`}
      />

      <span className="text-[12px] font-[570] text-[#717171] leading-[16px]">Step 2 of 4</span>
      <h1 className={"font-[670] leading-[40px] text-[36px] text-primary mb-6 pt-2"}>Pick a contact person</h1>
      <input
        placeholder="Search"
        className="input mb-2 w-full border-4 border-[#F8F8F8] focus:ring-0 focus:border-[#e1e1e1 ] focus:outline-none text-primary text-[14px] leading-[10px] placeholder:text-[#e1e1e1 ]placeholder:opacity-100"
      />
      <div className={"flex-1 overflow-y-auto scrollbar-sm scroll-hidden"}>
        {
          selectedCustomer?.contacts?.length < 1 ?
            <div className={"flex items-center justify-center py-5"}>
              <p className={"text-black/60 font-bold"}>No Contact Found</p>
            </div>
            : selectedCustomer?.contacts?.map((contact, idx)=>(
              <React.Fragment key={contact?.contactId}>
                <div onClick={()=> handleSelectContact(contact)} className="flex items-center justify-between w-full gap-4 cursor-pointer">
                  <div className="flex items-center justify-between gap-4 my-3">
                    <div className="flex flex-col">
                      <h4 className="font-bold">{contact?.name}</h4>
                      <h4 className="font-normal">{contact?.title}</h4>
                      <p className="font-normal text-[#b3a8a8]">{contact?.phoneNumber}</p>
                      <p className="text-[#b3a8a8] font-normal">{contact?.email}</p>
                    </div>
                  </div>
                  <ChevronRight className="cursor-pointer text-[#a48080]" />
                </div>
                {
                  idx < selectedCustomer?.contacts?.length - 1 && <hr />
                }
              </React.Fragment>
            ))
        }
      </div>
      <Link className={"mt-5"} to={`/app/accounts/${params?.clientId}/new-contact`}>
        <Button>
          <Plus className="" />
          <span className="font-extrabold text-white text-[18px] ml-5">Add a contact person</span>
        </Button>
      </Link>
    </div>
  );
}

export default SelectContactPage;