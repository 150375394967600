import React, { useEffect, useRef, useState } from "react";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";

import { AppHeader } from "../../../components/layout/app-header";
import { useWorkspaceStore } from "../../../store/workspace.store";
import SpinnerLoader from "../../../components/spinner-loader";
import ContractAmendments from "./_components/contract-amendments";
import ContractInfo from "./_components/contract-info";
import ContractOptions from "./_components/contract-options";
import ContractHeader from "./_components/contract-header";
import ContractOptionEdit from "./_components/contract-option-edit";
import ContractChangeBilling from "./_components/contract-change-billing";
import ContractChangeContact from "./_components/contract-change-contact";
import toast from "react-hot-toast";
import { useTemplateStore } from "../../../store/templates.store";
import { closeDocX, openDocXFromBlob, registerTagSelectionListener, registerTextSelectionListener } from "../../../../lib/document-utilities";
import { TemplateTools } from "../../../../lib/template-utilities";
import { Loader } from "lucide-react";
import { downloadContract } from "../../../services/workspace.service";
import TemplateError from "../../../components/template-error";

const ContractPage = () => {
  const loadingWorkspace = useWorkspaceStore(state => state.loading);
  const workspace = useWorkspaceStore(state => state.workspace);
  const loadWorkspace = useWorkspaceStore(state => state.loadWorkspace);
  const documentOpened = useTemplateStore(state => state.documentOpened);
  const resetLoading = useTemplateStore(state => state.resetLoading);

  const [currentTab, setCurrentTab] = useState(0);
  const [currentPage, setCurrentPage] = useState("home");
  const [selectedOption, setSelectedOption] = useState<any>({});
  const [templateError, setTemplateError] = useState<string>("");
  const [loadingContractDocument, setLoadingContractDocument] = useState<boolean>(false);

  const params = useParams();
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const registerTagSelectionListenerRef = useRef(null);
  const registerTextSelectionListenerRef = useRef(null);

  const contractVersion = searchParams.get("version");

  const updateCurrentView = (view: string) => {
    setCurrentPage(view);
    if (view === "home") {
      setSelectedOption({});
      loadWorkspace(Number(params.clientId), Number(params.contractId), Number(contractVersion), true);
    }
  };
  const updateSelectedOption = (option: any) => setSelectedOption(option);

  useEffect(() => {

    try {

    } catch (error) {

    }
    const registerCallBacks = async () => {
      registerTagSelectionListenerRef.current = await registerTagSelectionListener((tag: string | null) => {
        console.log({ tag });
      });

      registerTextSelectionListenerRef.current = await registerTextSelectionListener((selectedText_: string | null) => {
        console.log({ selectedText_ });
      });
    };

    const handleLoadWorkspace = async () => {
      try {

        if (documentOpened) {
          registerCallBacks();

          TemplateTools.highlightAllTags();

          resetLoading();
          setLoadingContractDocument(false);
          return;
        }

        setLoadingContractDocument(true);
        const contract = await loadWorkspace(Number(params.clientId), Number(params.contractId), Number(contractVersion));

        if (!contract) {
          toast.error("Contract not found");
          navigate(`/app/accounts/${params.clientId}/workspaces`);
          return;
        }

        const fileResponse = await downloadContract(Number(params.clientId), Number(params.contractId), Number(contractVersion));
        resetLoading();

        if (fileResponse.success) {
          await openDocXFromBlob(fileResponse.data);
          TemplateTools.highlightAllTags();

          TemplateTools.updateContentOfTags(contract.autocomplete);
          registerCallBacks();

          setLoadingContractDocument(false);
        } else {
          setTemplateError("An error occurred! Please try again later");
        }

      } catch (error) {
        console.log(error);
      }

    };

    if (params.clientId && params.contractId) {
      if (!contractVersion) {
        navigate(`/app/accounts/${params.clientId}/workspaces`);
        return;
      }

      handleLoadWorkspace();
    }

    return () => {
      try {
        if (registerTagSelectionListenerRef.current) {
          registerTagSelectionListenerRef.current?.();
        }
        if (registerTextSelectionListenerRef.current) {
          registerTextSelectionListenerRef.current?.();
        }

        closeDocX().then(() => {
        });
      } catch (error: any) {
        console.log(error);
      }
    };

  }, [params.clientId, params.contractId, contractVersion]);

  const onBackClick = () => {
    if (currentPage === "option-edit") {
      setCurrentPage("home");
      return;
    }

    if (["change-billing", "change-contact"].includes(currentPage)) {
      setCurrentPage("home");
      return;
    }

    navigate(`/app/accounts/${params.clientId}/workspaces`);
  };

  if (loadingContractDocument) {
    return (
      <div className="flex items-center justify-center min-h-screen nnnnn">
        <Loader className='w-8 h-8 animate-spin' />
      </div>
    );
  }

  if (templateError) return <TemplateError isContract resetError={() => setTemplateError("")} error={templateError} />;

  return (
    <div className="w-full p-4 max-w-[500px] flex flex-col h-screen">
      <AppHeader
        web_url={"/app/"}
        show_back={true}
        onBackClick={onBackClick}
      // back_url={`/app/accounts/${params.clientId}/workspaces`}
      />

      <div className={"flex-1 flex flex-col mt-2 scrollbar-sm scroll-hidden"}>

        {
          loadingWorkspace ? <SpinnerLoader /> :
            !workspace?.contactId ?
              <>
                <div className={"w-full flex-1 h-full flex justify-center items-center"}>
                  <p>Contract not found</p>
                </div>
              </> :
              <>
                {
                  currentPage === "home" &&
                  <>
                    <ContractHeader
                      workspace={workspace}
                      currentTab={currentTab}
                      setCurrentTab={setCurrentTab}
                    />

                    {
                      currentTab === 0 &&
                      <ContractAmendments
                        updateSelectedOption={updateSelectedOption}
                        workspace={workspace}
                        updateCurrentView={updateCurrentView}
                      />
                    }
                    {
                      currentTab === 1 &&
                      <ContractOptions
                        workspace={workspace}
                        updateCurrentView={updateCurrentView}
                        updateSelectedOption={updateSelectedOption}
                      />
                    }
                    {
                      currentTab === 2 && <ContractInfo workspace={workspace} updateCurrentView={updateCurrentView} />
                    }
                  </>
                }

                {
                  currentPage === "option-edit" && <ContractOptionEdit
                    option={selectedOption}
                    updateCurrentView={updateCurrentView}
                    contract={workspace}
                    onSuccess={() => {
                      setCurrentPage("home");
                      setCurrentTab(0);
                      loadWorkspace(Number(params.clientId), Number(params.contractId), Number(contractVersion), true);
                      setSelectedOption({});
                    }}
                  />
                }

                {
                  currentPage === "change-billing" &&
                  <ContractChangeBilling
                    contract={workspace}
                    onSuccess={() => {
                      setCurrentPage("home");
                      setCurrentTab(2);
                    }}
                  />
                }
                {
                  currentPage === "change-contact" &&
                  <ContractChangeContact
                    contract={workspace}
                    onSuccess={() => {
                      setCurrentPage("home");
                      setCurrentTab(2);
                    }}
                  />
                }
              </>
        }
      </div>
    </div>
  );
};

export default ContractPage;