import React from "react";
import { AppHeader } from "./layout/app-header";
import Button from "./ui/button";
import { useNavigate, useParams } from "react-router-dom";
import { closeDocX } from "../../lib/document-utilities";

interface Props {
  error?: string;
  resetError?: () => void;
  isContract?: boolean;
}
const TemplateError = ({ error, isContract = false, resetError = () => { } }: Props) => {
  const navigate = useNavigate();
  const params = useParams();

  return (
    <div className="w-full p-4 max-w-[500px] flex flex-col h-screen">
      <AppHeader web_url={`/app/accounts/${params?.clientId}/${isContract ? "workspaces" : "templates"}`} />
      <div className='flex items-center justify-center flex-1'>
        <div className="flex flex-col items-center text-danger">
          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5"
            stroke="currentColor" className="w-[120px] h-[120px] opacity-80">
            <path strokeLinecap="round" strokeLinejoin="round"
              d="M12 9v3.75m-9.303 3.376c-.866 1.5.217 3.374 1.948 3.374h14.71c1.73 0 2.813-1.874 1.948-3.374L13.949 3.378c-.866-1.5-3.032-1.5-3.898 0L2.697 16.126ZM12 15.75h.007v.008H12v-.008Z" />
          </svg>


          <span className="mt-4 text-danger">{error}</span>

          <div className={"mt-5 w-[200px]"}>
            <Button onClick={() => {
              closeDocX().then(() => {
                resetError();
                navigate(`/app/accounts/${params?.clientId}/templates`);
              });
            }} className="px-6 mt-4">Close document</Button>


          </div>
        </div>
      </div>
    </div>
  );
};

export default TemplateError;