import { create } from 'zustand';
import { fetchTemplateDocument, fetchTemplateDocuments } from "../services/templates.service";

type TemplateStore = {
	templates: Template[];
	template: Template | null;
	loading: boolean;
	documentOpened: boolean;
	checkPreviousSession: boolean;

	loadClientTemplates: (clientId: number) => Promise<void>;
	loadClientTemplate: (clientId: string, templateId: string, resetLoading?: boolean) => Promise<Template | null>;
	silentReLoadClientTemplate: (clientId: string, templateId: string) => Promise<void>;

	setSelectedTemplate: (template: Template) => void;
	setDocumentOpened: (open: boolean) => void;
	setCheckPreviousSession: (status: boolean) => void;
	resetLoading: () => void;
};

export const useTemplateStore = create<TemplateStore>((set) => ({
	templates: [],
	template: null,
	loading: false,
	documentOpened: false,
	checkPreviousSession: true,

	loadClientTemplates: async (clientId: number) => {
		set({ loading: true, templates: [] });
		const response = await fetchTemplateDocuments(clientId);
		if (response.success) set({ templates: response?.data?.templates ?? [] });
		set({ loading: false });
	},

	loadClientTemplate: async (clientId: string, templateId: string, resetLoading=true) => {
		set({ loading: true, template: null });
		const response = await fetchTemplateDocument(clientId, templateId);
		if (response.success) set({ template: response?.data?.template ?? null });

		if(resetLoading) set({ loading: false });

		return response?.data?.template ?? null;
	},

	silentReLoadClientTemplate: async (clientId: string, templateId: string) => {
		const response = await fetchTemplateDocument(clientId, templateId);
		if (response.success) set({ template: response?.data?.template ?? null });
	},

	setSelectedTemplate: (template: Template) => {
		set({ template });
	},
	setDocumentOpened: (open: boolean) => {
		set({ documentOpened: open });
	},
	setCheckPreviousSession: (status: boolean) => {
		set({ checkPreviousSession: status });
	},
	resetLoading: () => set({ loading: false })
}));