import { type ClassValue, clsx } from "clsx"
import { twMerge } from "tailwind-merge"

export function cn(...inputs: ClassValue[]) {
  return twMerge(clsx(inputs))
}
// await function
export function sleep(ms: number) {
  return new Promise(resolve => setTimeout(resolve, ms))
}

export function stringToBlob(data: string, type: string): Blob {
    return new Blob([data], { type: type });
}

export function getNameInitials(name: string) {
  return name.split(" ").map((n) => n[0]).join("");
}