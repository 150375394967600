import { create } from 'zustand';
import { getClientsList } from "../services/clients.service";

type ClientStore = {
	clients: Client[];
	client: Client | null;
	loading: boolean;

	loadClients: () => Promise<void>;
	setClients: (clientRoles: ClientUserRole[]) => void;
};

export const useClientStore = create<ClientStore>((set) => ({
	clients: [],
	client: null,
	loading: true,

	loadClients: async () => {
		set({ loading: true, clients: [] });
		const response = await getClientsList();

		if (response.success) set({ clients: response?.data?.clients ?? [] });
		set({ loading: false });
	},

	setClients: (clientRoles: ClientUserRole[]) =>{
		const clients = clientRoles.map(role=>role.client);
		set({ clients, loading: false });
	}
}));