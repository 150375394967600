import React from "react";
import { cn } from "../../utils";

export interface AvatarProps extends React.HTMLAttributes<HTMLDivElement>{
  avatar?: string;
  name?: string;
}

const Avatar = ({ avatar="", name="", className, ...props }: AvatarProps)=>{
  return (
    <div  className={cn("h-12 w-12 rounded-full flex items-center justify-center bg-[#a48080]", className)} {...props}>
      {
        avatar ? <img src={avatar} className={"w-full h-full object-cover"} alt={name} />
          : <p className=" ">{name}</p>
      }
    </div>
  )
}

export default Avatar