import * as React from "react"
import { cva, type VariantProps } from "class-variance-authority"
import { Loader } from 'lucide-react';
import { cn } from "../../utils";

export const buttonVariants = cva(
  "btn h-[44px] w-full font-bold rounded-[12px] disabled:pointer-events-none border-none hover:border-none ",
  {
    variants: {
      variant: {
        default: "btn-primary text-white disabled:bg-primary/80 hover:bg-primary/90",
        outline: "",
        ghost : "bg-[#F8F8F8] hover:bg-[#F8F8F9] disabled:bg-[#F8F8F9] hover:border-none "
      },
    },
    defaultVariants: {
      variant: "default",
    },
  }
)

export interface ButtonProps
  extends React.ButtonHTMLAttributes<HTMLButtonElement>,
    VariantProps<typeof buttonVariants> {
  loading?: boolean;
  loaderClasses?: string;
}
const Button = React.forwardRef<HTMLButtonElement, ButtonProps>(
  ({
     className, loaderClasses, variant, loading = false,
     children, disabled, ...props }, ref
  )=>{
  const Comp = "button"

  return (
    <Comp
      className={cn(buttonVariants({ variant, className }))}
      ref={ref}
      disabled={disabled || loading}
      {...props}
    >
      {loading ? <Loader className={cn("h-5 w-5 text-white animate-spin", loaderClasses)} /> : children}
    </Comp>
  )
});

export default Button;