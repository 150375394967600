import React, { ButtonHTMLAttributes, Dispatch, SetStateAction, useState } from "react";
import Button from "../../../../../components/ui/button";
import { cn } from "../../../../../utils";
import TextOptions from "./_components/text-option";
import RangeOptions from "./_components/range-option";
import AutoReplaceOption from "./_components/auto-replace-option";
import { toast } from "react-hot-toast";
import { TemplateTools } from "../../../../../../lib/template-utilities";
import { TemplateOptionType } from "../../../../../../lib/template-options-types";

interface Props {
	setScreenView: Dispatch<SetStateAction<string>>;
	onComplete: ()=>void;
	selectedText?: string;
}

const NewTemplateOption = ({ setScreenView, onComplete, selectedText }: Props) => {
	const [selectedOption, setSelectedOption] = useState<TemplateOptionType>(TemplateOptionType.TEXT);
	const [saving, setSaving] = useState(false);

	const [errors, setErrors] = useState<Record<string, string>>({});

	// For Inputs
	const [values, setValues] = useState<Record<string, boolean|string|number>>({
		name: "",
		valueName: "",
		valueText: selectedText || "",
		needsApproval: false
	})

	const [rangeOptionValues, setRangeOptionsValues] = useState<Record<string, number>>({
		minValue: 0,
		maxValue: 10000,
		defaultValue: 4000,
		preApprovedMin: 100,
		preApprovedMax: 9000,
	});

	const [autoReplaceValues, setAutoReplaceValues] = useState<Record<string, string>>({
		orgNo: ""
	})

	const handleInputChange = (key: string, value: string|number|boolean) => {
		setValues(prev=>({ ...prev, [key]: value }))
		setErrors(prev=>({ ...prev, [key]: "" }))
	}

	const handleRangeInputChange = (key: string, value: number) => {
		setRangeOptionsValues(prev=>({ ...prev, [key]: value }))
		setErrors(prev=>({ ...prev, [key]: "" }))
	}

	const handleAutoReplaceInputChange = (key: string, value: string) => {
		setAutoReplaceValues(prev=>({ ...prev, [key]: value }))
		setErrors(prev=>({ ...prev, [key]: "" }))
	}

	const handleResetOnSwitch = (value: TemplateOptionType, all=false)=>{

		if(value===TemplateOptionType.TEXT || all){
			setValues(prev=>({
				...prev,
				valueName: "",
				valueText: "",
				needsApproval: false
			}));
		}

		if(value===TemplateOptionType.RANGE || all){
			setRangeOptionsValues({
				minValue: 0,
				maxValue: 10000,
				defaultValue: 4000,
				preApprovedMin: 100,
				preApprovedMax: 9000,
			});
		}

		if(value===TemplateOptionType.AUTO_REPLACE || all){
			setAutoReplaceValues({
				orgNo: ""
			});
		}
	}

	const validateErrors = ()=>{
		let isValid = true;

		if(!values.name) {
			setErrors(prev=>({...prev, name: "Name is required"}))
			isValid = false;
		} else {
			setErrors(prev=>({...prev, name: ""}))
		}

		if(selectedOption === TemplateOptionType.TEXT){
			if(!values.valueName) {
				setErrors(prev=>({...prev, valueName: "Field is required"}))
				isValid = false;
			}
			else {
				setErrors(prev=>({...prev, valueName: ""}))
			}

			if(!values.valueText) {
				setErrors(prev=>({...prev, valueText: "Field is required"}))
				isValid = false;
			}
			else {
				setErrors(prev=>({...prev, valueText: ""}))
			}
		}

		if(selectedOption === TemplateOptionType.RANGE){
			if(!rangeOptionValues.maxValue) {
				setErrors(prev=>({...prev, maxValue: "Field is required"}))
				isValid = false;
			}
			else {
				setErrors(prev=>({...prev, maxValue: ""}))
			}

			if(!rangeOptionValues.defaultValue) {
				setErrors(prev=>({...prev, defaultValue: "Field is required"}))
				isValid = false;
			}
			else {
				setErrors(prev=>({...prev, defaultValue: ""}))
			}

			if(!rangeOptionValues.preApprovedMin) {
				setErrors(prev=>({...prev, preApprovedMin: "Field is required"}))
				isValid = false;
			}
			else {
				setErrors(prev=>({...prev, preApprovedMin: ""}))
			}

			if(!rangeOptionValues.preApprovedMax) {
				setErrors(prev=>({...prev, preApprovedMax: "Field is required"}))
				isValid = false;
			}
			else {
				setErrors(prev=>({...prev, preApprovedMax: ""}))
			}
		}

		if(selectedOption === TemplateOptionType.AUTO_REPLACE){
			if(!autoReplaceValues.orgNo) {
				setErrors(prev=>({...prev, orgNo: "Field is required"}))
				isValid = false;
			}
			else {
				setErrors(prev=>({...prev, orgNo: ""}))
			}
		}

		return isValid;
	}

	const handleSave = async ()=>{
		try {

			if(validateErrors()){
				setSaving(true);

				if(selectedOption === TemplateOptionType.TEXT){
					await TemplateTools.upsertOption(
						values.name as string,
						TemplateOptionType.TEXT  as TemplateOptionType,
						values.valueName as string,
						{
							[values.valueName as string]:
								{
									text: values.valueText as string,
									needsApproval: values.needsApproval as boolean
								}
					})

					handleResetOnSwitch(TemplateOptionType.TEXT)
				}

				if (selectedOption === TemplateOptionType.RANGE){
					await TemplateTools.upsertOption(
						values.name as string,
						TemplateOptionType.RANGE  as TemplateOptionType,
						rangeOptionValues.defaultValue,
						{
							range: {min: rangeOptionValues.minValue, max: rangeOptionValues.maxValue},
							preApprovedRange: {min: rangeOptionValues.preApprovedMin, max: rangeOptionValues.preApprovedMax},
					})

					handleResetOnSwitch(TemplateOptionType.RANGE)
				}

				if (selectedOption === TemplateOptionType.AUTO_REPLACE){
					await TemplateTools.upsertOption(
						values.name as string,
						TemplateOptionType.AUTO_REPLACE  as TemplateOptionType,
						autoReplaceValues.orgNo
					)

					handleResetOnSwitch(TemplateOptionType.RANGE)
				}

				toast.success("Template option saved successfully.");
				setSaving(false);
				onComplete()
				setScreenView("home")
				TemplateTools.updateContentFromDefaultValue(values.name as string)
				return;
			}

		} catch(error: any){
			setSaving(false);
			toast.error("Failed to save option. Please try again later.")
		}
	}

	return (
		<>
			<div className={"flex justify-between items-center"}>
				<h1 className={"font-[670] leading-[40px] text-[36px] text-primary mb-4 pt-2"}>New option</h1>
			</div>

			<div className={"flex-1 overflow-y-auto scrollbar-sm scroll-hidden space-y-8"}>
				<div>
					<input
						placeholder="Name"
						className="input w-full bg-[#F8F8F8] focus:ring-0 focus:border-[#e1e1e1 ] focus:outline-none
													text-primary text-[14px] leading-[18px] placeholder:text-[#e1e1e1 ]
													placeholder:opacity-100
												"
						value={values?.name as string}
						onChange={e => handleInputChange("name", e.target.value)}
					/>
					{errors.name && <p className={"text-red-400 mt-1.5 text-sm"}>{errors.name}</p>}
				</div>

				<div className="flex items-center w-full gap-4 ">
					<TabButton
						isActive={selectedOption === TemplateOptionType.TEXT}
						onClick={() => {
							setSelectedOption(TemplateOptionType.TEXT);
							handleResetOnSwitch(TemplateOptionType.TEXT);
						}}
					>
						<svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
							<path
								d="M13.083 4.75H8.08301C6.97844 4.75 6.08301 5.64543 6.08301 6.75V17.25C6.08301 18.3546 6.97844 19.25 8.08301 19.25H16.583C17.6876 19.25 18.583 18.3546 18.583 17.25V10.25M13.083 4.75V8.25C13.083 9.35457 13.9784 10.25 15.083 10.25H18.583M13.083 4.75L18.583 10.25"
								stroke="black" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
							<path d="M9.08301 15.75H15.583" stroke="black" strokeWidth="1.5" strokeLinecap="round"
										strokeLinejoin="round" />
							<path d="M9.08301 12.75H11.583" stroke="black" strokeWidth="1.5" strokeLinecap="round"
										strokeLinejoin="round" />
						</svg>
						<span>Text</span>
					</TabButton>
					<TabButton
						isActive={selectedOption === TemplateOptionType.RANGE}
						onClick={() => {
							setSelectedOption(TemplateOptionType.RANGE);
							handleResetOnSwitch(TemplateOptionType.RANGE);
						}}
					>
						<svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
							<path d="M5.25 6.25L6.75 4.75V10.25M6.75 10.25H5.25M6.75 10.25H7.75" stroke="black" strokeWidth="1.5"
										strokeLinecap="round" strokeLinejoin="round" />
							<path
								d="M13.75 14.25H12.1562C11.4255 14.25 11.0182 13.4359 11.4058 12.8574C11.4535 12.7861 11.5211 12.7311 11.5925 12.6836L13.3924 11.486C13.4638 11.4384 13.5312 11.3832 13.5799 11.3126C14.0253 10.6678 13.5713 9.75 12.7526 9.75H11.25"
								stroke="black" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
							<path
								d="M17.25 14.75H18.6964C19.9308 14.75 20.1499 16.6376 19.0101 16.9556C18.9549 16.971 18.897 16.9772 18.8398 16.9792L18.25 17L18.8398 17.0208C18.897 17.0228 18.9549 17.0289 19.0101 17.0444C20.1499 17.3624 19.9308 19.25 18.6964 19.25H17.25"
								stroke="black" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
						</svg>
						<span>Range</span>
					</TabButton>
					<TabButton
						isActive={selectedOption === TemplateOptionType.AUTO_REPLACE}
						onClick={() => {
							setSelectedOption(TemplateOptionType.AUTO_REPLACE);
							handleResetOnSwitch(TemplateOptionType.AUTO_REPLACE);
						}}
					>
						<svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
							<path
								d="M9.49551 14.6704L12.1658 9.32975L14.8361 14.6704M10.5226 13.0271H13.8091M7.58922 4.59912L5.6416 6.35198L7.58922 8.10483M6.42046 6.35198H13.8214C16.4029 6.35198 18.4957 8.44472 18.4957 11.0263V11.221M16.7551 20.1488L18.7027 18.396L16.7551 16.6431M17.9235 18.396H10.5226C7.94102 18.396 5.84827 16.3033 5.84827 13.7218V13.527"
								stroke="black" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
						</svg>
						<p className="flex flex-col items-start">
							<span>Auto</span>
							<span>Replace </span>
						</p>
					</TabButton>
				</div>
				<div>
					{
						selectedOption === TemplateOptionType.TEXT &&
						<TextOptions values={values} errors={errors} handleInputChange={handleInputChange} />
					}
					{
						selectedOption === TemplateOptionType.RANGE &&
						<RangeOptions values={rangeOptionValues} errors={errors} handleRangeInputChange={handleRangeInputChange} />
					}
					{
						selectedOption === TemplateOptionType.AUTO_REPLACE &&
						<AutoReplaceOption values={autoReplaceValues} errors={errors} handleAutoReplaceInputChange={handleAutoReplaceInputChange} />
					}
				</div>
			</div>

			<hr className={"my-3"} />

			<div className="flex w-full gap-2">
				<Button onClick={() => setScreenView("home")} variant="ghost" className="flex-1">
					Cancel
				</Button>
				<Button loading={saving} onClick={handleSave} className="flex-1">Save</Button>
			</div>
		</>
	);
};


interface TabButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
	isActive: boolean;
}

const TabButton = ({ isActive, children, ...props }: TabButtonProps) => {
	return (
		<button
			className={
				cn("flex items-center justify-center font-[670] gap-2 border border-[#00000014] rounded-lg flex-1 h-[40px] text-[12px] leading-[12px]",
					{
						"border-4 border-black": isActive
					}
				)}
			{...props}
		>
			{children}
		</button>
	);
};

export default NewTemplateOption;