import React from 'react';
import * as Slider from '@radix-ui/react-slider';

type Values = {
  minValue?: number,
  maxValue?: number,
  defaultValue?: number,
  fromValue?: number,
  toValue?: number;
};
interface Props {
  values: Values,
  disableRange?: boolean;
  onChange: (values: Values) => void;
}

const ThreeHandleSlider = ({ values, disableRange = false, onChange }: Props) => {
  const handleRangeChange = (newRange) => {
    onChange({ ...values, minValue: newRange[0], maxValue: newRange[1] });
  };

  const handleDefaultChange = (newDefault) => {
    onChange({ ...values, defaultValue: newDefault[0] });
  };

  return (
    <div className="w-full max-w-sm py-4 space-y-8">
      <div className="relative pt-6">
        <Slider.Root
          className="relative flex items-center w-full select-none touch-none"
          value={[(values.minValue ?? 10), (values.maxValue ?? 90)]}
          onValueChange={handleRangeChange}
          min={values?.fromValue ?? 0}
          max={values?.toValue ?? 100}
          step={1}
          disabled={disableRange}
          minStepsBetweenThumbs={1}
        >
          <Slider.Track className="relative w-full h-2 bg-red-600 rounded-full grow">
            <Slider.Range className="absolute h-full bg-white rounded-full" />
          </Slider.Track>
          <SliderThumb
            value={values.minValue ?? 10}
            label={"Min"}
            className="top-[-30px]"
          />
          <SliderThumb
            value={values.maxValue ?? 90}
            label={"Max"}
            className="top-[-30px]"
          />
        </Slider.Root>

        {/* Default Value Slider */}
        <Slider.Root
          className="absolute left-0 w-full top-2"
          value={[values.defaultValue]}
          onValueChange={handleDefaultChange}
          min={values?.fromValue ?? 0}
          max={values?.toValue ?? 100}
          step={1}
        >
          <Slider.Track className="w-full h-2 bg-transparent">
            <Slider.Range className="h-full bg-transparent" />
          </Slider.Track>

          <SliderThumb value={values.defaultValue} label={"Default"} className="!fill-green-500 relative" />
        </Slider.Root>
      </div>
    </div>
  );
};


const SliderThumb = ({ value, label, ...rest }) => {
  return (
    <Slider.Thumb className="focus:!ring-0 focus:!ring-offset-0 cursor-pointer focus:!border-none focus:!outline-none !p-0 !rounded-full" asChild >
      <div className="relative">
        <span className="absolute top-[-25px]">{value}</span>
        <svg width="34" height="28" viewBox="0 0 34 28" fill="white" {...rest} xmlns="http://www.w3.org/2000/svg">
          <g filter="url(#filter0_d_816_595)">
            <rect x="4" y="2" width="25.8207" height="20" rx="10" fill="white"></rect>
            <rect x="4.25" y="2.25" width="25.3207" height="19.5" rx="9.75" stroke="#E9E9E9" strokeWidth="0.5">
            </rect></g><rect x="14.7588" y="9" width="1.07586" height="6" rx="0.537931" fill="#C9C9C9"></rect><rect x="17.9863" y="9" width="1.07586" height="6" rx="0.537931" fill="#C9C9C9"></rect>
          <defs>
            <filter id="filter0_d_816_595" x="0" y="0" width="33.8203" height="28" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
              <feFlood floodOpacity="0" result="BackgroundImageFix"></feFlood>
              <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"></feColorMatrix>
              <feOffset dy="2"></feOffset>
              <feGaussianBlur stdDeviation="2"></feGaussianBlur>
              <feComposite in2="hardAlpha" operator="out"></feComposite>
              <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.16 0"></feColorMatrix>
              <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_816_595"></feBlend>
              <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_816_595" result="shape"></feBlend>
            </filter>
          </defs>
        </svg>
        <span className="absolute text-sm bottom-[-20px]">{label}</span>
      </div>

    </Slider.Thumb>
  );
};

export default ThreeHandleSlider;