import React, { useRef, useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { OTPInput, SlotProps  } from 'input-otp'
import { z } from "zod";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import toast from "react-hot-toast";

import { AppLogo } from "../../../components/layout/AppLogo";
import { cn } from "../../../utils";
import { useAuthStore } from "../../../store/auth.store";
import { requestLogin, verifyLogin } from "../../../services/auth.service";
import Button from "../../../components/ui/button";
import { saveJwtToken } from "../../../utils/local-storage";

const formSchema = z.object({
  code: z.string(),
});
interface FormSchemaType extends z.infer<typeof formSchema> { }

function VerifyPage(){
  const [timeLeft, setTimeLeft] = useState(90);
  const [requestingCode, setRequestingCode] = useState(false);

  const loginEmail = useAuthStore(state=>state.loginEmail);
  const setLoginEmail = useAuthStore(state=>state.setLoginEmail);
  const setAuthUser = useAuthStore(state=>state.setAuthUser);

  const inputRef = useRef(null)
  const navigate = useNavigate()

  const form = useForm<FormSchemaType>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      code: "",
    },
  });

  async function onSubmit(values: FormSchemaType) {
    try {

      const result = await verifyLogin(loginEmail, values.code);

      if (!result.success) {
        if (typeof result.error === "object" && result.error?.length > 0) {
          let error = result.error[0];
          form.setError(error?.path[0], {
            type: "server",
            message: error.message,
          });
        }
        return;
      }

      if (result?.data?.user) {
        saveJwtToken(result?.data?.token ?? "");
        setAuthUser(result?.data?.user);
        navigate("/app");
      }
    } catch (error) {
      console.log("Error =>", error);
    }
  }

  async function resendLoginCode() {
    setRequestingCode(true);
    form.reset();
    form.clearErrors();
    
    const result = await requestLogin(loginEmail);
    setRequestingCode(false);
    if (!result.success) {
      toast.error("Failed to send code");
      return;
    }

    setTimeLeft(90);
    toast.success("Code sent successfully");
    inputRef.current?.focus();
  }

  const { isSubmitting, errors } = form.formState;

  useEffect(() => {
    const localLoginEmail = localStorage.getItem("login_email")

    if (!loginEmail) {
      if(localLoginEmail && localLoginEmail?.includes("@")){
        setLoginEmail(localLoginEmail)
        inputRef.current?.focus();
      }else{
        // navigate("/auth/login");
      }
    } else {
      inputRef.current?.focus();
    }

    return ()=> localStorage.removeItem("login_email")
  }, [loginEmail]);

  useEffect(() => {
    let timer = setInterval(() => {
      setTimeLeft(timeLeft - 1);
    }, 1000);

    if (timeLeft === 0) {
      clearInterval(timer);
    }
    return () => clearInterval(timer);
  }, [timeLeft]);

  const minutes = Math.floor(timeLeft / 60);
  const seconds = timeLeft % 60;

  return (
    <div className={"w-full flex flex-col min-h-screen justify-center items-center max-w-[360px] mx-auto p-5"}>
      <AppLogo />
      <h1 className={"font-[670] leading-[40px] text-[36px] text-primary mt-12 "}>Login</h1>

      <p className="mt-5 text-center">
        Check <span className="font-bold">{loginEmail} </span>for code
      </p>

      <form className={"mt-8 w-full"} onSubmit={form.handleSubmit(onSubmit)} noValidate>

        <OTPInput
          ref={inputRef}
          value={form.watch("code")}
          onChange={(value: string) => {
            form.setValue("code", value);
            form.clearErrors()
          }}
          onComplete={() => form.handleSubmit(onSubmit)()}
      
          maxLength={6}
          containerClassName="group flex items-center has-[:disabled]:opacity-30 gap-2"
          render={({ slots }) => (
            <div className="flex w-full gap-2">
              {slots.map((slot, idx) => (
                <Slot key={idx} {...slot} />
              ))}
            </div>
          )}
        />

        {
          errors?.code?.message && <p className={"text-red-500 text-sm mt-1"}>{errors?.code?.message}</p>
        }

        <div className="flex justify-center gap-2 mt-5">
          <button disabled={isSubmitting || timeLeft !== 0} className="flex items-center justify-center gap-2" type="button" onClick={resendLoginCode}>
            <svg className={cn(requestingCode && "animate-spin")} width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
              <g clipPath="url(#clip0_682_89)">
                <path d="M14.375 8.125H18.125V4.375" stroke="#8C8C8C" strokeWidth="1.5" strokeLinecap="round"
                      strokeLinejoin="round" />
                <path
                  d="M14.7188 14.9998C13.736 15.9272 12.5018 16.5444 11.1702 16.7743C9.83865 17.0042 8.46887 16.8366 7.23202 16.2925C5.99517 15.7483 4.94609 14.8518 4.21585 13.7148C3.48562 12.5779 3.10662 11.251 3.12621 9.89986C3.1458 8.54875 3.5631 7.23336 4.32599 6.11806C5.08887 5.00276 6.1635 4.137 7.41561 3.62896C8.66771 3.12091 10.0418 2.9931 11.3661 3.26151C12.6904 3.52991 13.9063 4.18263 14.8618 5.13809L18.1251 8.12481"
                  stroke="#8C8C8C" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
              </g>
              <defs>
                <clipPath id="clip0_682_89">
                  <rect width="20" height="20" fill="white" />
                </clipPath>
              </defs>
            </svg>

            <p>
              Resend code {timeLeft > 0 &&
              <span> in {minutes < 10 ? `0${minutes}` : minutes}:{seconds < 10 ? `0${seconds}` : seconds}</span>}
            </p>
          </button>

        </div>

        <div className={"mt-12 flex justify-center "}>
          <Link className={"font-bold text-[14px]"} to={"/auth/login"}>Wrong email?</Link>
        </div>

        <Button loading={isSubmitting} disabled={requestingCode} className={"mt-5 w-full "}>Verify</Button>

        <p className={"w-full text-center text-[12px] mt-4 px-2"}>
          by clicking on this button you accepting our <a className={"underline"} href={"/"} target={"_blank"}
                                                          rel={"noreferrer"}>Terms of Service</a>
        </p>

      </form>
    </div>
  )
}

function Slot(props: SlotProps) {
  return (
    <div
      className={cn(
        "relative w-full h-[44px] text-[2rem] text-primary",
        "flex items-center justify-center",
        "transition-all duration-300",
        "bg-[#F8F8F8] rounded-md",
        { "border-2 border-primary": props.isActive }
      )}
    >
      {props.char !== null && <div>{props.char}</div>}
    </div>
  )
}

export default VerifyPage;