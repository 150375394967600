export async function saveJwtToken(jwtToken: string): Promise<void> {
    localStorage.setItem("token", jwtToken);

	if (!Office.context) return;
	
  try {
      Office.context?.roamingSettings?.set("token", jwtToken);
      await new Promise((resolve, reject) => {
          Office.context?.roamingSettings?.saveAsync((result) => {
              if (result.status === Office.AsyncResultStatus.Succeeded) {
                  resolve(result.value);
              } else {
                  reject(result.error);
              }
          });
      });
  } catch (error) {
      console.error("Failed to save JWT token in Office roaming settings:", error?.message);
  }
}

export async function loadJwtToken(): Promise<string | null> {
	const jwtToken = localStorage.getItem("token");
	
	if (!Office.context) return jwtToken;

  try {
      await new Promise<string | null>((resolve, reject) => {
        const result = Office.context?.roamingSettings?.get("token")

        if (result?.status === Office.AsyncResultStatus.Succeeded) {
          resolve(result?.value);
        } else {
          reject(result?.error??"Failed");
        }
      });

      return jwtToken;
  } catch (error) {
      console.error("Failed to load JWT token from Office roaming settings:", error?.message);
      return jwtToken;
  }
}