
import React, { Dispatch, SetStateAction, useState } from "react";
import Button from "../../../../components/ui/button";
import TextOptions from "./new-template-option/_components/text-option";
import { TemplateOptionType } from "../../../../../lib/template-options-types";
import { TemplateTools } from "../../../../../lib/template-utilities";
import { toast } from "react-hot-toast";

interface Props{
  tagOption: any;
  onCancel: () => void;
  onComplete: (values: any) => void;
}

const TemplateTagOptionNewValue = ({tagOption, onCancel, onComplete}: Props)=>{

  // For Inputs
  const [values, setValues] = useState<Record<string, boolean|string|number>>({
    valueName: "",
    valueText: "",
    needsApproval: false
  })
  const [errors, setErrors] = useState<Record<string, string>>({});
  const [saving, setSaving] = useState(false);

  const handleInputChange = (key: string, value: string|number|boolean) => {
    setValues(prev=>({ ...prev, [key]: value }))
    setErrors(prev=>({ ...prev, [key]: "" }))
  }

  const validateErrors = ()=>{
    let isValid = true;

    if(tagOption?.type === TemplateOptionType.TEXT){
      if(values.valueName) {
        setErrors(prev=>({...prev, valueName: ""}))
      }
      else {
        setErrors(prev=>({...prev, valueName: "Field is required"}))
        isValid = false;
      }

      if(!values.valueText) {
        setErrors(prev=>({...prev, valueText: "Field is required"}))
        isValid = false;
      }
      else {
        setErrors(prev=>({...prev, valueText: ""}))
      }
    }
    return isValid;
  }

  const handleSave = async ()=>{
    try {

      if(validateErrors()){
        setSaving(true);

        let obj: any = {};

        if(tagOption.type === TemplateOptionType.TEXT){
          obj=values;

          await TemplateTools.upsertOption(
            tagOption.key,
            tagOption.type,
            tagOption.value,
            {
              ...(tagOption?.values??{}),
              [values.valueName as string]:
                {
                  text: values.valueText as string,
                  needsApproval: values.needsApproval as boolean
                }
            })
        }

        toast.success("Option value saved successfully.");
        setSaving(false);
        onComplete(obj)
        return;
      }

    } catch(error: any){
      setSaving(false);
      toast.error("Failed to save option value. Please try again later.")
    }
  }


  return (
    <>
      <div className={"flex justify-between items-center"}>
        <h1 className={"font-[670] leading-[40px] text-[36px] text-primary mb-4 pt-2"}>New Value</h1>
      </div>

      <TextOptions values={values} errors={errors} handleInputChange={handleInputChange} />
      <hr className={"my-3"} />

      <div className="flex w-full gap-2">
        <Button onClick={onCancel} variant="ghost" className="flex-1">
          Cancel
        </Button>
        <Button onClick={handleSave} loading={saving} className="flex-1">Save</Button>
      </div>
    </>
  )
}

export default TemplateTagOptionNewValue