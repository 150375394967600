import { ChevronRight } from "lucide-react";
import React, { useEffect } from "react";
import { AppHeader } from "../../../../components/layout/app-header";
import { useNavigate, useParams } from "react-router-dom";
import { useWorkspaceStore } from "../../../../store/workspace.store";
import { useTemplateStore } from "../../../../store/templates.store";
import SpinnerLoader from "../../../../components/spinner-loader";

function SelectTemplatePage() {

  const selectedCustomer = useWorkspaceStore((state) => state.selectedCustomer);
  const selectedContact = useWorkspaceStore((state) => state.selectedContact);
  const loadClientTemplates = useTemplateStore((state) => state.loadClientTemplates);
  const loadingTemplates = useTemplateStore((state) => state.loading);
  const templates = useTemplateStore((state) => state.templates);
  const setSelectedTemplate = useWorkspaceStore((state) => state.setSelectedTemplate);

  const params = useParams();
  const navigate = useNavigate();

  const handleSelectTemplate = (template: Template) =>{
    setSelectedTemplate(template);
    navigate(`/app/accounts/${params.clientId}/contract-parameters`);
  }

  useEffect(() => {
    if (!selectedCustomer?.customerId) navigate(`/app/accounts/${params.clientId}/select-customer`);
    else if (!selectedContact?.contactId) navigate(`/app/accounts/${params.clientId}/select-contact`);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedContact?.contactId, selectedCustomer?.customerId]);
  useEffect(() => {
    if (params?.clientId) loadClientTemplates(Number(params?.clientId));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params?.clientId]);

  if (!selectedContact?.contactId) return null;

  return (
    <div className="w-full flex flex-col p-4 justify-center  max-w-[500px]">
      <AppHeader
        show_back
        back_url={`/app/accounts/${params?.clientId}/select-billing`}
        web_url={`/app/clients/${params?.clientId}/select-template`}
      />

      <span className="text-[12px] font-[570] text-[#717171] leading-[16px]">Step 4  of 4</span>
      <h1 className={"font-[670] leading-[40px] text-[36px] text-primary mb-5 pt-2"}>Choose a template</h1>
      <input
        placeholder="Search"
        className="input mb-2 w-full bg-[#F8F8F8] focus:ring-0 focus:border-[#e1e1e1 ] focus:outline-none
                      text-primary text-[14px] leading-[10px] placeholder:text-[#e1e1e1 ]
                      placeholder:opacity-100
                    "
      />

      {
        loadingTemplates ? <SpinnerLoader />:
          templates?.length < 1 ?
            <div className={"flex items-center justify-center py-5"}>
              <p className={"text-black/60 font-bold"}>No Template Found</p>
            </div>
            : templates?.map((template, idx)=>(
              <React.Fragment key={template?.templateId}>
                <div onClick={()=>handleSelectTemplate(template)} className="flex px-2 rounded items-center cursor-pointer py-4 hover:bg-grayish justify-between w-full max-w-[800px] gap-4 mx-auto">
                  <div className="flex items-center justify-between gap-4">
                    <div className="flex items-center justify-between gap-4">
                        <span>
                          <svg width="30" height="30" viewBox="0 0 18 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path
                              d="M17.0306 5.71938L11.7806 0.469375C11.7109 0.399749 11.6282 0.344539 11.5371 0.306898C11.4461 0.269257 11.3485 0.249923 11.25 0.25H2.25C1.85218 0.25 1.47064 0.408035 1.18934 0.68934C0.908035 0.970645 0.75 1.35218 0.75 1.75V18.25C0.75 18.6478 0.908035 19.0294 1.18934 19.3107C1.47064 19.592 1.85218 19.75 2.25 19.75H15.75C16.1478 19.75 16.5294 19.592 16.8107 19.3107C17.092 19.0294 17.25 18.6478 17.25 18.25V6.25C17.2501 6.15148 17.2307 6.05391 17.1931 5.96286C17.1555 5.87182 17.1003 5.78908 17.0306 5.71938ZM11.25 6.25V2.125L15.375 6.25H11.25Z"
                              fill="black"
                            />
                          </svg>
                        </span>
                        <div className="flex flex-col">
                          <h4 className="font-bold capitalize">{template?.name}</h4>
                        </div>
                    </div>
                  </div>
                  <ChevronRight className="cursor-pointer text-[#a48080]" />
                </div>
                {
                  idx < templates?.length - 1 && <hr />
                }
              </React.Fragment>
            ))
      }
    </div>
  );
}

export default SelectTemplatePage;