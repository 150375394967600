import JSZip from "jszip";
import { parseStringPromise } from "xml2js";

////////////////////////////////////////////////////////
/////////////////// DOCX FUNCTIONS /////////////////////
////////////////////////////////////////////////////////

function arrayBufferToBase64(buffer: ArrayBuffer): string {
    let binary = '';
    const bytes = new Uint8Array(buffer);
    const len = bytes.length;

    for (let i = 0; i < len; i++) {
        binary += String.fromCharCode(bytes[i]);
    }

    return btoa(binary);
}

// Blob can be returned from response.blob() from a fetch
export function openDocXFromBlob(blob: Blob, openInNewWindow: boolean = false): Promise<{ [key: string]: any } | false> {
    return new Promise((resolve, reject) => {
        const reader = new FileReader();

        reader.onloadend = async function () {
            try {
                const arrayBuffer = reader.result as ArrayBuffer;
                const base64String = arrayBufferToBase64(arrayBuffer);

                if (openInNewWindow) {
                    await Word.run(async (context) => {
                        // Create a new document from the base64 string and open it in a new window
                        const myNewDoc = context.application.createDocument(base64String);
                        context.load(myNewDoc);
                        await context.sync();

                        // Close the current document without saving
                        context.document.close(Word.CloseBehavior.skipSave);

                        // Open the new document
                        myNewDoc.open();
                        await context.sync();
                    });
                } else {
                    // Extract properties and settings from the blob before inserting it into the document
                    const properties = await extractCustomPropertiesFromBlob(blob);
                    const settings = await extractSettingsFromBlob(blob);

                    await Word.run(async (context) => {
                        // Replace the content of the current document with the content from the base64 string
                        context.document.body.insertFileFromBase64(base64String, "Replace");
                        await context.sync();

                        // Optionally, set the document title or any other properties to ensure Word recognizes it as updated
                        context.document.properties.title = "Updated Document";
                        await context.sync();

                        // Set the document properties
                        for (const [key, value] of Object.entries(properties)) {
                            await addCustomProperty(key, value);
                        }

                        // Set the document settings
                        for (const [key, value] of Object.entries(settings)) {
                            context.document.settings.add(key, JSON.stringify(value));
                        }

                        await context.sync();

                        // Get all custom properties
                        const updatedProperties = await getAllCustomProperties();

                        // Store the identification of the loaded document in localStorage
                        localStorage.setItem('loadedDocumentIdentification', JSON.stringify(updatedProperties));

                        // Resolve the promise with the properties
                        resolve(updatedProperties);
                    });
                }
            } catch (error) {
                console.error("Error processing document:", error);
                resolve(false); // Document was not fully loaded or an error occurred
            }
        };

        reader.onerror = function (error) {
            console.error("Error reading .docx file:", error);
            reject(error); // An error occurred while reading the file
        };

        // Read the Blob as an ArrayBuffer
        reader.readAsArrayBuffer(blob);
    });
}

async function extractCustomPropertiesFromBlob(blob: Blob): Promise<{ [key: string]: any }> {
    const zip = await JSZip.loadAsync(blob);
    const customPropsPath = 'docProps/custom.xml';
    const customPropsXml = await zip.file(customPropsPath)?.async('text');

    if (!customPropsXml) {
        return {};
    }

    const result = await parseStringPromise(customPropsXml);
    const properties: { [key: string]: any } = {};

    if (result && result.Properties && result.Properties.property) {
        result.Properties.property.forEach((prop: any) => {
            const name = prop.$.name;
            let value = prop['vt:lpwstr']?.[0] || prop['vt:i4']?.[0];

            // Handle cases where value is an object with _ property
            if (value && typeof value === 'object' && '_' in value) {
                value = value._;
            }

            if (name && value !== undefined) {
                properties[name] = isNaN(value) ? value : parseInt(value, 10);
            }
        });
    }

    return properties;
}

async function extractSettingsFromBlob(blob: Blob): Promise<{ [key: string]: any }> {
    const zip = await JSZip.loadAsync(blob);

    const addInId = "8a976b83-7fcb-4977-9474-79568877e629"; // Your extension ID
    const webextensionsFolder = 'word/webextensions/';
    const files = zip.folder(webextensionsFolder);

    if (!files) {
        console.log('No webextensions folder found');
        return {};
    }

    let xmlData: string | null = null;

    // Find the XML file containing our extension's UUID
    for (const fileName of Object.keys(zip.files)) {
        let filePath: string;
        if (fileName.startsWith(webextensionsFolder)) {
            filePath = fileName;
        } else {
            filePath = `${fileName}`;
        }

        if (fileName.endsWith('.xml')) {
            const fileData = await zip.file(filePath)?.async('text');
            if (fileData && fileData.trim().length > 0) {
                const settings = await parseStringPromise(fileData);

                // Ensure the we:webextension element exists
                if (settings['we:webextension']) {
                    const webExtension = settings['we:webextension'];

                    // Check if we:reference exists within we:webextension
                    if (webExtension['we:reference']) {
                        const references = webExtension['we:reference'];

                        // Iterate through references to find the matching ID
                        for (const reference of references) {
                            const referenceId = reference['$']['id'];
                            if (referenceId && referenceId.toLowerCase() === addInId.toLowerCase()) {
                                xmlData = fileData;
                                break;
                            }
                        }
                    }
                }
            }
        }

        if (xmlData) {
            break; // Exit the loop as soon as we find the relevant XML data
        }
    }

    // If no relevant XML file is found, return an empty object
    if (!xmlData) {
        console.log('No relevant XML file found containing the extension ID');
        return {};
    }

    // Parse the XML data and extract properties
    const finalSettings = await parseStringPromise(xmlData);
    const result: Record<string, any> = {};

    if (finalSettings['we:webextension'] && finalSettings['we:webextension']['we:properties']) {
        const properties = finalSettings['we:webextension']['we:properties'][0]['we:property'];

        for (const property of properties) {
            const name = property['$']['name'];
            // TODO XXX - Why do we need to double parse it?, the templates created seems to double encode, lets fix this
            const value = JSON.parse(JSON.parse(property['$']['value']));
            result[name] = value;
        }
    }

    return result;
}

export async function closeDocX(): Promise<void> {
    return Word.run(async (context) => {
        // Clear the document content
        context.document.body.clear();

        // Load custom properties
        context.document.properties.customProperties.load();
        // Load document settings
        context.document.settings.load();

        await context.sync();

        // Delete custom properties
        context.document.properties.customProperties.items.forEach(property => property.delete());
        // Delete document settings
        context.document.settings.items.forEach(setting => setting.delete());

        await context.sync();

        // Clear the loadedDocumentIdentification from local storage
        localStorage.removeItem('loadedDocumentIdentification');
    }).catch(console.error);
}

export function getEntireDocXAsBlob(): Promise<Blob> {
    return new Promise((resolve, reject) => {
        Office.context.document.getFileAsync(Office.FileType.Compressed, { sliceSize: 65536 /*64 KB*/ }, (result) => {
            if (result.status === Office.AsyncResultStatus.Failed) {
                reject(result.error.message);
            } else {
                const file = result.value;
                const slices = file.sliceCount;
                let fetchedSlices = 0;
                const docdataSlices: number[][] = [];

                const getSlice = (sliceIndex: number) => {
                    file.getSliceAsync(sliceIndex, (sliceResult) => {
                        if (sliceResult.status === Office.AsyncResultStatus.Failed) {
                            reject(sliceResult.error.message);
                        } else {
                            docdataSlices[sliceIndex] = sliceResult.value.data;

                            if (++fetchedSlices === slices) {
                                file.closeAsync();
                                const docdata = new Uint8Array(docdataSlices.flat());
                                const blob = new Blob([docdata], { type: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document' });
                                resolve(blob);
                            } else {
                                getSlice(sliceIndex + 1);
                            }
                        }
                    });
                };

                getSlice(0);
            }
        });
    });
}

async function getDocumentContent(): Promise<string> {
    return Word.run(async context => {
        const body = context.document.body;
        body.load('text');

        await context.sync();

        return body.text;
    });
}

export enum DocumentState {
    BLANK = 'DOCUMENT_BLANK',
    LOADED = 'DOCUMENT_LOADED',
    DIRTY = 'DOCUMENT_DIRTY',
    VERIFY = 'DOCUMENT_VERIFY'
}

// TODO XXX Is the document is empty - no content? Of copurse a new template could be empty with properties
export async function checkDocumentState(): Promise<string> {
    const allProperties = await getAllCustomProperties();
    const documentContent = await getDocumentContent();

    if (Object.keys(allProperties).length === 0) {
        if (documentContent.trim() === '') {
            return DocumentState.BLANK;
        } else {
            return DocumentState.DIRTY;
        }
    } else {
        const loadedDocumentIdentification = JSON.parse(localStorage.getItem('loadedDocumentIdentification') || 'null');

        if (loadedDocumentIdentification === null) {
            return DocumentState.VERIFY;
        } else {
            const isSameDocument = Object.entries(loadedDocumentIdentification).every(([key, value]) => {
                return allProperties[key] === value;
            });

            if (isSameDocument) {
                return DocumentState.LOADED;
            } else {
                return DocumentState.DIRTY;
            }
        }
    }
}

export async function setDocumentLoaded(properties: { [key: string]: any }) {
    localStorage.setItem('loadedDocumentIdentification', JSON.stringify(properties));
}

////////////////////////////////////////////////////////
///////////////// CUSTOM PROPERTY CODE /////////////////
////////////////////////////////////////////////////////

export enum DocumentType {
    TEMPLATE = 'Template',
    CONTRACT = 'Contract'
}

export async function getLoadedDocumentType(): Promise<DocumentType | null> {
    const properties = await getAllCustomProperties();

    return getDocumentType(properties);
}

export function getDocumentType(properties: { [key: string]: any }): DocumentType | null {
    if (properties.contractId) {
        return DocumentType.CONTRACT;
    } else if (properties.templateId) {
        return DocumentType.TEMPLATE;
    }

    return null;
}

export async function getAllCustomProperties(): Promise<{ [key: string]: any }> {
    const propertyKeys = ['templateId', 'templateVersion', 'contractId', 'contractVersion'];
    return await getCustomProperties(propertyKeys);
};

async function getCustomProperties(propertyKeys: string[]): Promise<{ [key: string]: any }> {
    try {
        // This will hold the results.
        const properties: { [key: string]: any } = {};

        // Execute a Word.run for the asynchronous operations within Word.
        await Word.run(async (context) => {
            // Iterate over each key provided in the array.            
            for (const key of propertyKeys) {
                // Attempt to get the custom property by its key.
                const customProperty = context.document.properties.customProperties.getItemOrNullObject(key);                

                // Load the key and value of the custom property.
                // Note: We're not using a wildcard load here to avoid the previous issue.
                context.load(customProperty, 'key, value');

                // Synchronize the state by executing queued commands, 
                // ensuring the property is loaded.
                await context.sync();

                // Check if the custom property is not null and choose it to the results.
                if (customProperty && customProperty.key) {
                    properties[key] = customProperty.value;
                }
            }
        });

        return properties;
    } catch (error) {
        console.error('Error loading specific custom properties:', error);
        return {};
    }
}

export async function addCustomProperty(key: string, value: any): Promise<void> {
    await Word.run(async (context) => {
        const properties = context.document.properties.customProperties;

        // Check if the property already exists
        const existingProperty = properties.getItemOrNullObject(key);
        await context.sync();

        if (existingProperty.isNullObject) {
            // If the property does not exist, add it
            properties.add(key, value);
        } else {
            // If the property exists, update its value
            existingProperty.value = value;
        }

        await context.sync();
    });
}

async function getCustomPropertyByKey(key: string): Promise<any> {
    return await Word.run(async (context) => {
        const properties = context.document.properties.customProperties;
        const property = properties.getItemOrNullObject(key);
        context.load(property, "key, value");
        await context.sync();

        return property.key ? property.value : null;
    });
}

async function removeCustomProperty(key: string): Promise<void> {
    await Word.run(async (context) => {
        const properties = context.document.properties.customProperties;
        const property = properties.getItemOrNullObject(key);
        context.load(property, "key");
        await context.sync();

        if (property.key) {
            property.delete();
            await context.sync();
        }
    });
}

////////////////////////////////////////////////////////
//////////////////////// EVENTS ////////////////////////
////////////////////////////////////////////////////////

async function isTagSelected(callback: (tag: string | null) => void) {
    await Word.run(async (context) => {
        // Get the current selection.
        const selection = context.document.getSelection();
        // Load the content control properties for the selection.
        selection.contentControls.load('items/tag');
        await context.sync();

        // Check if the selection is within content controls.
        const contentControls = selection.contentControls.items;
        if (contentControls.length > 0) {
            // Assuming we care about the first content control in the selection.
            const tag = contentControls[0].tag;
            callback(tag);
        } else {
            callback(null);
        }
    }).catch(console.error);
}

async function isTextSelected(callback: (selectedRange: string|null) => void) {
    await Word.run(async (context) => {
        // Get the current selection.
        const selection = context.document.getSelection();
        // Load the text property for the selection.
        selection.load('text');
        await context.sync();

        // Check if the selection has text.
        if (selection.text) {
            callback(selection.text);
        } else {
            callback(null);
        }
    }).catch(console.error);
}

export async function registerTagSelectionListener(callback: (tag: string | null) => void) {
    let selectionChangedHandler = () => isTagSelected(callback);
    await Office.context.document.addHandlerAsync(Office.EventType.DocumentSelectionChanged, selectionChangedHandler, (result) => {
        if (result.status === Office.AsyncResultStatus.Failed) {
            console.error(result.error.message);
        }
    });

    const removeTagSelectionListener = async () => {
        await Office.context.document.removeHandlerAsync(Office.EventType.DocumentSelectionChanged, { handler: selectionChangedHandler }, (result) => {
            if (result.status === Office.AsyncResultStatus.Failed) {
                console.error(result.error.message);
            }
        });
    };

    return removeTagSelectionListener;
}

export async function registerTextSelectionListener(callback: (selectedRange: string|null) => void) {
    let selectionChangedHandler = () => isTextSelected(callback);
    await Office.context.document.addHandlerAsync(Office.EventType.DocumentSelectionChanged, selectionChangedHandler, (result) => {
        if (result.status === Office.AsyncResultStatus.Failed) {
            console.error(result.error.message);
        }
    });

    const removeTextSelectionListener = async () => {
        await Office.context.document.removeHandlerAsync(Office.EventType.DocumentSelectionChanged, { handler: selectionChangedHandler }, (result) => {
            if (result.status === Office.AsyncResultStatus.Failed) {
                console.error(result.error.message);
            }
        });
    };

    return removeTextSelectionListener;
}